import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

import {ReactComponent as GrafanaIcon} from "../../../../assets/icons/services/grafana.svg";
import {ReactComponent as JiraIcon} from "../../../../assets/icons/services/jira.svg";
import {ReactComponent as GitLabIcon} from "../../../../assets/icons/services/gitlab.svg";
import {ReactComponent as TestLabIcon} from "../../../../assets/icons/services/testlab.svg";
import {ReactComponent as HuntLabIcon} from "../../../../assets/icons/services/huntlab.svg";
import {iconProps} from "./styles/styles";
import {ServiceListItem} from "../../../../types/MenuListItems.types";

export const servicesItems:ServiceListItem[] = [
    {
        name: "Test Lab",
        icon: <SvgIcon component={TestLabIcon} {...iconProps} />,
        href: "https://testlab-new.loylabs.ru",
    },
    {
        name: "Hunt Lab",
        icon: <SvgIcon component={HuntLabIcon} {...iconProps} />,
        href: "https://huntlab-test.loylabs.ru",
    },
    {
        name: "Grafana",
        icon: <SvgIcon component={GrafanaIcon} {...iconProps} />,
        href: "http://90.156.206.55:3000",
    },
    {
        name: "Jira",
        icon: <SvgIcon component={JiraIcon} {...iconProps} />,
        href: "https://loylabs-jira.cp12.ru",
    },
    {
        name: "GitLab",
        icon: <SvgIcon component={GitLabIcon} {...iconProps} />,
        href: "https://code.dev.loylabs.ru",
    },
];