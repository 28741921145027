import {styled} from "@mui/material/styles";
import {Typography} from "@mui/material";

interface IColorTypographyProps {
    color?: string
}

export const styleBadgeWrapper = {
    display: 'flex',
    justifyContent: 'center',
    gap: '8px',
    flexWrap: 'wrap',
    alignItems: 'center',
}

export const styleActiveBadge = {
    '& .MuiBadge-badge': {
        backgroundColor: '#37BD5D',
        color: '#ffffff',
        margin: '0',
        padding: '0',
    },
}

export const styleIcon = {
    width: '70%',
    height: '70%',
}

export const styleBoxCardWrapper = {
    borderRadius: '12px',
    backgroundColor: 'rgba(185, 215, 255, 0.08)',
    p: 3,
    display: 'flex',
    flexDirection: 'column',
}

export const styleBox = {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    alignItems: 'center',
    justifyContent: 'center',
}

export const ColorTypography = styled(Typography)<IColorTypographyProps>(({ color }) => ({
    display: 'flex',
    alignItems: 'center',
    color: color,
}))

export const styleCardHeader = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingInline: '0px',
}

export const styleBoxHeader = {
    display: 'flex',
    gap: '16px',
    justifyContent: 'flex-start',
}

export const buttonStyle = {
    borderRadius: "60px",
    backgroundColor: "rgba(24, 132, 255, 1)",
    p: "4px 16px",
    height: "40px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    whiteSpace: "nowrap",
    minWidth: "auto",
    color: 'rgba(230, 237, 245, 1)',
    "&:hover": {
        backgroundColor: "rgba(26, 117, 222, 1)",
    },
}