import React, { FC } from "react";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import {Tooltip, useTheme} from "@mui/material";

import {MenuListItem, MenuListItems } from "../../../types/MenuListItems.types";
import {truncate} from "../../../operation/truncate";

interface MenuListProps {
  testId?: string;
  items: MenuListItems;
  forRemainingMenu?: boolean;
  onClick?: (id: string) => void;
}

export const MenuList: FC<MenuListProps> = ({
  testId,
  items,
  forRemainingMenu,
  onClick,
}) => {
  const theme = useTheme();

  const location = useLocation().pathname;
  const isActive = (path: string) => {
    return path === location;
  };

  const iconStyle = {
    minWidth: "auto",
    marginRight: "20px",
  };

  const itemStyle = {
    borderRadius: "48px",
    marginBottom: "4px",
    backgroundColor: forRemainingMenu
      ? theme.palette.background.paper
      : undefined,
  };

  const handleClick = (id?: string) => {
    if (onClick && id) {
      onClick(id);
    }
  };

  const renderListItemText = (item: MenuListItem) => {
    const text = truncate(item.name, 20);
    if (item.name.length > 20) {
      return (
          <Tooltip title={item.name} placement="top" arrow>
            <ListItemText
                secondary={isActive(item.href) ? false : text}
                secondaryTypographyProps={{
                  variant: "button",
                }}
                primary={isActive(item.href) ? text : false}
                primaryTypographyProps={{
                  variant: "button",
                }}
            />
          </Tooltip>
      );
    } else {
      return (
          <ListItemText
              secondary={isActive(item.href) ? false : text}
              secondaryTypographyProps={{
                variant: "button",
              }}
              primary={isActive(item.href) ? text : false}
              primaryTypographyProps={{
                variant: "button",
              }}
          />
      );
    }
  };

  const listProps = {
    'data-testid': testId,
  };

  return (
      <List {...listProps}>
        {items.map((item, index) => (
            <ListItemButton
                style={itemStyle}
                to={item.href}
                key={index}
                selected={isActive(item.href)}
                component={NavLink}
                onClick={() => {
                  handleClick(item.id);
                }}
            >
              <ListItemIcon sx={iconStyle}>{item.icon}</ListItemIcon>
              {renderListItemText(item)}
            </ListItemButton>
        ))}
      </List>
  );
};

export default MenuList;
