import React, {FC, useState} from 'react';
import DialogWrapper from '../../../common/Modals/DialogWrapper/DialogWrapper';
import {useAppDispatch} from '../../../../hooks/useAppDispatch';
import {useAppSelector} from '../../../../hooks/useAppSelector';
import {toggleModal} from '../../../../reducer/bonusesModalSlice';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputFilled from '../../../common/Inputs/InputFilled/InputFilled';
import {LoadProgress} from '../../../common/LoadProgress/LoadProgress';
import {ConfirmationContent} from './ConfirmationContent/ConfirmationContent';
import {ApplicationButtons, ButtonItem} from './ApplicationButtons/ApplicationButtons';
import {useApplicationData} from './helpers/useApplicationData';


export const ApplicationModal: FC = () => {
    const dispatch = useAppDispatch();
    const {isOpenView, modalType} = useAppSelector((state) => state.bonusesModal);
    const {activity, gifts, isFetchingActivity, isFetchingGifts, isShowButton, message} = useApplicationData();

    const [typeButton, setTypeButton] = useState<'approve' | 'cancel' | 'clearing' | ''>('');

    const handleClose = () => {
        dispatch(toggleModal({type: null, isOpenView: false, id: undefined}));
        setTypeButton('');
    };

    const handleClick = (type: 'approve' | 'cancel' | 'clearing') => {
        setTypeButton(type);
    };

    const buttons: ButtonItem[] = [
        {text: 'Подтвердить', type: 'approve'},
        {text: 'Отклонить', type: 'cancel'},
        {text: 'На уточнение', type: 'clearing'},
    ];

    const getHeader = (): React.ReactNode => {
        if (isFetchingActivity || isFetchingGifts) return "";
        if (modalType === 'managementGifts' && gifts) return gifts.gift.title;
        if (modalType === 'managementActivity' && activity) return activity.activity.title;
        return ""
    }

    return (
        <DialogWrapper
            openModal={isOpenView && (modalType === 'managementActivity' || modalType === 'managementGifts')}
            handleModal={handleClose}
            width="532px"
            contentDividers
            header={getHeader()}
        >
            {isFetchingActivity || isFetchingGifts ? (
                <LoadProgress/>
            ) : typeButton ? (
                <ConfirmationContent typeButton={typeButton} close={() => setTypeButton('')} action={modalType ?? ''}/>
            ) : (
                <Box display="flex" alignItems="center">
                    <Box flex="70%">
                        <Typography>Введите ваше сообщение</Typography>
                        <InputFilled
                            maxRows={7}
                            minRows={7}
                            margin="8px 0"
                            multiline
                            value={message}
                            handleValue={() => {
                            }}
                            disabled
                        />
                    </Box>
                    <ApplicationButtons buttons={buttons} onClick={handleClick} isVisible={isShowButton}/>
                </Box>
            )}
        </DialogWrapper>
    );
};
