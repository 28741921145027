import React, {ReactNode} from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuList from "../../../common/MenuList/MenuList";
import {iconStyle, servicesStyle} from "./styles/styles";
import {MenuListItem, ServiceListItem} from "../../../../types/MenuListItems.types";


interface IServicesListProps {
    items: ServiceListItem[] | MenuListItem[]
    children: ReactNode
}

const ServicesList = ({items, children}: IServicesListProps) => {
    const slots = React.Children.toArray(children);
    const icon = slots.find(child => React.isValidElement(child) && child.type === ServicesList.Icon);
    const text = slots.find(child => React.isValidElement(child) && child.type === ServicesList.Text);

    return (
        <Accordion sx={servicesStyle}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon color="secondary"/>}
                aria-controls="services-menu-content"
                id="services-menu-header"
            >
                <ListItemIcon sx={iconStyle}>
                    {icon}
                </ListItemIcon>
                <ListItemText
                    secondary={text}
                    secondaryTypographyProps={{
                        variant: 'button',
                    }}
                />
            </AccordionSummary>
            <AccordionDetails>
                <MenuList items={items}/>
            </AccordionDetails>
        </Accordion>
    )
};

ServicesList.Icon = ({children}: { children: ReactNode }) => <>{children}</>;
ServicesList.Text = ({children}: { children: ReactNode }) => <>{children}</>;

export default ServicesList;
