import { SystemStyleObject, Theme } from "@mui/system";

interface IStyles {
  styleGroup: SystemStyleObject<Theme>;
  styleSingle: (colorHex: string) => SystemStyleObject<Theme>;
}

export const styles: IStyles = {
  styleGroup: {
    '[role="tablist"]': {
      overflowX: { laptop: "auto" },
    },
    mx: {
      mobile: "-16px",
      laptop: "-32px",
    },
    ".MuiTabs-scroller": {
      pl: {
        mobile: "10px",
        laptop: "26px",
      },
      pr: {
        mobile: "16px",
        laptop: "32px",
      },
    },
    ".MuiButtonBase-root": {
      borderRadius: "60px",
      fontSize: "14px",
      lineHeight: "20px",
      padding: "10px 12px",
      margin: "0px 6px",
      backgroundColor: "rgba(203, 225, 255, 0.17)",
      color: "rgba(229, 235, 242, 0.65)",
      "&:last-of-type": {
        position: "relative",
        "&::after": {
          position: "absolute",
          content: "''",
          right: { mobile: "-16px", laptop: "-32px" },
          width: { mobile: "16px", laptop: "32px" },
          height: "100%",
        },
      },
    },
    ".MuiTabs-indicator": {
      height: "0px",
    },
  },
  styleSingle: (colorHex) => ({
    "&.MuiButtonBase-root.Mui-selected": {
      backgroundColor: colorHex,
      color: "rgba(230, 237, 245, 1)",
    },
  }),
};
