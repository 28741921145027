import {GridRenderCellParams} from "@mui/x-data-grid";
import {ReviewEditStatus, ReviewNotifications} from "../../../constants/review";

export const ActiveStatusPopoverButtons = (params: GridRenderCellParams,
                                           openModalWithStatus: (status: ReviewNotifications, reviewId:string) => void,
                                           handleOpenEditModalWithStatus : (status: ReviewEditStatus, params: GridRenderCellParams) => void,
                                           deleteReview: ( params: GridRenderCellParams) => void,
                                           archiveReview: ( params: GridRenderCellParams) => void
                                           ) => [
    {name: 'Редактировать', action: () => handleOpenEditModalWithStatus(ReviewEditStatus.edit, params)},
    {name: 'Уведомление всем', action: () => openModalWithStatus(ReviewNotifications.noticeAll, params.row.id)},
    {name: 'Ревьюерам без оценки', action: () => openModalWithStatus(ReviewNotifications.noticeShelvedPerson, params.row.id)},
    {name: 'Уведомление сотруднику', action: () => openModalWithStatus(ReviewNotifications.noticeReviewedPerson, params.row.id)},
    {name: 'Перейти к целям', action: () => {}},
    {name: 'Удалить ревью', action: () => deleteReview(params)},
    {name: 'Добавить в архив', action: () => archiveReview(params)},
];

export const ArchivedStatusPopoverButtons = (params: GridRenderCellParams,
                                             handleOpenEditModalWithStatus : (status: ReviewEditStatus, params: GridRenderCellParams) => void,
                                             deleteReview: ( params: GridRenderCellParams) => void
                                             ) => [
    {name: 'Перейти к целям', action: () => {}},
    {name: 'Удалить ревью', action: () => deleteReview(params)},
    {name: 'Вернуть в активные',  disabled: params.row.status === 'DONE', action: () => handleOpenEditModalWithStatus(ReviewEditStatus.unArchive,params)},
];

