import { profilesInitial } from "../actions/profiles";
import { PROFILES } from "../constants/actions";
import { ReturnActionsTypes } from "../store";
import { PersonView } from "../models/profile.models";
import { initialProfiles } from "./initialStates/initialProfile";

export type ProfilesInfo = {
  profile: PersonView;
  filteredProfiles: PersonView[];
  editProfile: PersonView;
  allProfiles: PersonView[];
  totalCountProfiles: number;
};

const initialState: ProfilesInfo = initialProfiles;

export const profileReducer = (
  state = initialState,
  action: ReturnActionsTypes<typeof profilesInitial>
): ProfilesInfo => {
  switch (action.type) {
    case PROFILES.PROFILE:
      return { ...state, profile: action.payload };
    case PROFILES.FILTERED_PROFILES:
      return { ...state, filteredProfiles: action.payload };
    case PROFILES.EDIT_PROFILE:
      return { ...state, editProfile: action.payload };
    case PROFILES.ALL_PROFILES:
      return { ...state, allProfiles: action.payload };
    case PROFILES.TOTAL_COUNTS:
      return { ...state, totalCountProfiles: action.payload };
    default:
      return state;
  }
};
