import React, {FC, memo} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ImageIcon from '@mui/icons-material/Image';
import {styled} from '@mui/material/styles';

import DialogWrapper from '../../../../common/Modals/DialogWrapper/DialogWrapper';
import InputLine from '../../../../common/Inputs/InputLine/InputLine';
import ModalButtons from '../../../../common/Buttons/ModalButtons/ModalButtons';
import InputFilled from '../../../../common/Inputs/InputFilled/InputFilled';
import {LoadProgress} from '../../../../common/LoadProgress/LoadProgress';
import {useGiftModal} from './helpers/useGiftModal';

import {createGift} from './styles/createGift';


export const CreateGift: FC = () => {
    const {
        handleClose,
        handleSend,
        handleImageUpload,
        image,
        imageSrc,
        cost,
        setCost,
        isFetching,
        isOpenCreate,
        isOpenEdit,
        name,
        setName,
        comment,
        setComment,
        inputRef
    } = useGiftModal();

    const isDisabled = !name || !comment;
    const buttons = (
        <ModalButtons
            handleCancel={handleClose}
            handleSend={handleSend}
            disableSend={isDisabled}
            fullWidth
            nameButtonSend="Сохранить"
            justifyContent="flex-end"
        />
    );
    const isValidImage = image && imageSrc && !imageSrc.includes('null');

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    return (
        <DialogWrapper
            openModal={isOpenCreate || isOpenEdit}
            handleModal={handleClose}
            width="40%"
            header={isOpenEdit && isFetching ? null : (
                <Box sx={createGift.title} display={'flex'} justifyContent={'center'} flexDirection={'column'}>
                    <InputLine
                        placeholder={'Введите название подарка'}
                        size={'medium'}
                        inputProps={{sx: createGift.input_title}}
                        value={name ?? ""}
                        onChange={(e) => setName(e.target.value)}
                    />

                </Box>)}
            buttons={buttons}

        >
            {isOpenEdit && isFetching ? (
                <LoadProgress/>
            ) : <Box display="flex" justifyContent="space-between" sx={{my: 2}}>
                <Box sx={{flex: 1}}>
                    <Box sx={{mr: 2}} display={'flex'} justifyContent={'center'} flexDirection={'column'}
                         alignItems={'flex-start'}>
                        <Typography mb={2}>Введите описание подарка</Typography>
                        <InputFilled
                            multiline
                            value={comment ?? ""}
                            handleValue={(e) => setComment(e.target.value)}
                        />
                    </Box>
                    <Box sx={createGift.title} display={'flex'} justifyContent={'center'} flexDirection={'column'}
                         mt={2}>
                        <InputLine
                            placeholder={'Стоимость подарка'}
                            size={'medium'}
                            value={cost ?? ""}
                            onChange={(e) => setCost(e.target.value)}
                            isNumber
                        />
                    </Box>
                </Box>
                <Box
                    sx={createGift.imgBox}
                    onClick={() => inputRef.current?.click()}
                >
                    {isValidImage ? (
                        <img src={imageSrc} alt="Выбранное изображение" style={{maxWidth: '100%', maxHeight: '100%'}}/>
                    ) : (
                        <ImageIcon fontSize="large"/>
                    )}
                    <VisuallyHiddenInput
                        ref={inputRef}
                        type="file"
                        onChange={handleImageUpload}
                        accept="image/*"
                    />
                </Box>
            </Box>}
        </DialogWrapper>
    );
};

export default memo(CreateGift);