import axios from 'axios';
import {AxiosInstance, InternalAxiosRequestConfig} from 'axios';
import {authController} from './auth.controller';

const jwtInterceptor: AxiosInstance = axios.create();
let isRefreshing = false;
let subscribers: ((token: string) => void)[] = [];

jwtInterceptor.interceptors.request.use(
    (config: InternalAxiosRequestConfig<any>) => {
        const tokensData = JSON.parse(
            localStorage.getItem('REACT_TOKEN_AUTH_KEY') || '{}'
        );

        if (tokensData?.accessToken) {
            config.headers.Authorization = `Bearer ${tokensData?.accessToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

jwtInterceptor.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const authData = JSON.parse(
            localStorage.getItem('REACT_TOKEN_AUTH_KEY') || '{}'
        );
        const config = error?.config;
        if (error.response.status !== 403 || config._retry) {
            return Promise.reject(error);
        }
        config._retry = true;

        if (isRefreshing) {
            return new Promise((resolve) => {
                subscribers.push((token) => {
                    config.headers.Authorization = `Bearer ${token}`;
                    resolve(jwtInterceptor(config));
                });
            });
        }
        isRefreshing = true;
        let refreshPromise = await authController
            .refreshToken(authData.refreshToken)
            .catch((error) => {
                if (error.response.status === 400) {
                    localStorage.removeItem('REACT_TOKEN_AUTH_KEY');
                    window.location.replace('/');
                }
            });
        authData.refreshToken = refreshPromise.data.refreshToken;
        authData.accessToken = refreshPromise.data.accessToken;

        try {
            const newToken = refreshPromise.data.accessToken;
            const updatedAuthData = {...authData, accessToken: newToken};
            localStorage.setItem('REACT_TOKEN_AUTH_KEY', JSON.stringify(updatedAuthData));
            jwtInterceptor.defaults.headers.Authorization = `Bearer ${newToken}`;
            subscribers.forEach((cb) => cb(newToken as string));
            subscribers = [];

            return jwtInterceptor(config);
        } catch (error) {
            localStorage.removeItem('REACT_TOKEN_AUTH_KEY');
            window.location.replace('/');
            return Promise.reject(error)
        } finally {
            isRefreshing = false;
            refreshPromise = null;
        }
    }
);


export default jwtInterceptor;
