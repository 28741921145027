import {useState} from "react";

export const useReviewDateState = () => {
    const [selectedReviewDate, setSelectedReviewDate] = useState<{
        selectedStartReviewDate: string,
        selectedEndReviewDate: string
    }>({
        selectedStartReviewDate: '',
        selectedEndReviewDate: ''
    });

    return {selectedReviewDate, setSelectedReviewDate};
};