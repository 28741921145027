import React, {FC, useState} from "react";
import {ProjectsView} from "../../../models/profile.models";
import Stack from "@mui/material/Stack";
import CustomToggleButtons from "../../common/Buttons/CustomToggleButtons/CustomToggleButtons";
import Chip from "@mui/material/Chip";
import {useTheme} from "@mui/material/styles";
import {Typography} from "@mui/material";

export const Projects: FC<{ projects: ProjectsView[] }> = ({projects}) => {
  const ProjectsTitles = {
    external: 'Внешний проект',
    internal: 'Внутренний проект'
  } as const;
  type TProjectsTitles = typeof ProjectsTitles[keyof typeof ProjectsTitles];
  // Всегда будет один из вариантов ключ-значение,
  // т.к. проверка на наличие эл-в в массиве выше в родительском компоненте
  type TSectionsProject = Partial<{
    [key in TProjectsTitles]: ProjectsView[]
  }>

  const theme = useTheme();
  const chipStyles = {
    borderRadius: "60px",
    backgroundColor: "rgba(203, 225, 255, 0.17)",
    fontSize: "14px",
    lineHeight: "20px",
    color: theme.palette.text.secondary,
    padding: "10px 12px",
    marginLeft: "0",
    height: "40px",
    "& .MuiChip-label": {
      padding: "0",
    },
  }

  const externalProjects = projects.filter(project => project.isExternal);
  const internalProjects = projects.filter(project => !project.isExternal)

  const sectionsProjects: TSectionsProject = {
    ...(externalProjects.length > 0 && { [ProjectsTitles.external]: externalProjects }),
    ...(internalProjects.length > 0 && { [ProjectsTitles.internal]: internalProjects })
  }

  const [currentProjectTitle, setProjectTitle] = useState<TProjectsTitles>(Object.keys(sectionsProjects)[0] as TProjectsTitles)
  const handleSectionProject = (projectTitle: TProjectsTitles) => {
    setProjectTitle(projectTitle)
  }

  return (
      <Stack spacing={2}>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
          <Typography variant="h4">
            Проекты
          </Typography>
          <CustomToggleButtons
              items={Object.keys(sectionsProjects)}
              onChange={handleSectionProject}
              currentValue={currentProjectTitle}
          />
        </Stack>
        <Stack
            direction="row"
            useFlexGap
            sx={{flexWrap: 'wrap'}}
            spacing={2}
        >
          {sectionsProjects[currentProjectTitle]!.map(project => {
            return (<Chip sx={chipStyles} size="medium" label={project.name} />)
          })}
        </Stack>
      </Stack>
  )
}

export default Projects;
