import React, { FC, forwardRef, useRef } from 'react';

import Button from "@mui/material/Button";

interface CustomButtonProps {
  color?: "secondary" | "primary";
  fullWidth?: boolean;
  startIcon?: React.ReactElement;
  onClick?: (props?: any) => void;
  styles?: any;
  text: string;
  disabled?: boolean;
  lightType?: boolean;
  attachButton?: boolean;
  onChange?: (event: any) => void;
  acceptFile?: string;
  variant?: "contained" | "text";
  ref?: HTMLElement
}

export const CustomButton = forwardRef(({
  color,
  fullWidth,
  startIcon,
  onClick,
  styles,
  text,
  disabled,
  lightType,
  attachButton = false,
  onChange,
  acceptFile,
  variant = "contained"
}: CustomButtonProps, ref: React.ForwardedRef<HTMLDivElement | null>) => {
  const primaryButton = {
    "&:hover": {
      backgroundColor: "rgba(26, 117, 222, 1)",
    },
  };

  const secondaryButton = {
    backgroundColor: "rgba(51, 146, 255, 0.25)",
    color: "rgba(24, 132, 255, 1)",
    backdropFilter: "blur(12.5px)",
    "&:hover": {
      backgroundColor: "rgba(51, 146, 255, 0.4)",
    },
  };

  const lightButton = {
    backgroundColor: "rgba(203, 225, 255, 0.17)",
    "&:hover": {
      backgroundColor: "rgba(185, 215, 255, 0.08)",
    },
    color: "rgba(229, 235, 242, 0.65)",
    backdropFilter: "blur(12.5px)",
  };

  const inheritButton = {
    "&:hover": {
      backgroundColor: "rgba(185, 215, 255, 0.08)",
    },
    color: "rgba(229, 235, 242, 0.65)",
  };

  const buttonStyles = {
    borderRadius: "60px",
    p: "4px 16px",
    height: "40px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    ...(variant === "contained" && primaryButton),
    ...(color === "secondary" && secondaryButton),
    ...(lightType && lightButton),
    ...(variant === "text" && inheritButton),
    ...styles,
    whiteSpace: "nowrap",
    minWidth: "auto",
  };

  return (
    <div ref={ref}>
      <Button
        variant={variant}
        color={color}
        sx={{ ...buttonStyles }}
        fullWidth={fullWidth}
        startIcon={startIcon}
        onClick={onClick}
        size="small"
        disabled={disabled}
        component={attachButton ? "label" : "button"}
        onChange={onChange}

      >
        {attachButton && <input hidden accept={acceptFile} type="file" />}
        {text}
      </Button>
    </div>
  );
});

export default CustomButton;
