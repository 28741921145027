import React, { FC, useContext } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/useAppSelector";

import Box from "@mui/material/Box";
import {
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { SystemStyleObject, Theme } from "@mui/system";

import EditPopover from "../../common/Modals/EditPopover/EditPopover";
import {
  useFetchEventTypesQuery,
  useLazyFetchFileQuery,
} from "../../../services/calendar.controller";
import { changeTagsQuery } from "../../../reducer/calendar";
import { CalendarContext } from "../CalendarContext/CalendarContextProvider";
import { CalendarApplications } from "../CalendarApplications";
import { useCheckSelected } from "../hooks";

import { styleGroup } from "./styles";

interface CalendarButtonsProps {
  styles?: SystemStyleObject<Theme>;
}

const buttons: { id: number; tags: string[]; text: string }[] = [
  { id: 0, tags: ["GENERAL", "REQUEST"], text: "Мои события" },
  { id: 1, tags: ["CORPORATE"], text: "События компании" },
  { id: 2, tags: ["BIRTHDAY"], text: "Дни рождения" },
];

export const CalendarButtons: FC<CalendarButtonsProps> = ({
  styles = null,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));
  const { onCalendarError: handleError } = useContext(CalendarContext);
  const dispatch = useDispatch();

  const { isError } = useFetchEventTypesQuery();
  if (isError) handleError();
  const [ fetchFile ] = useLazyFetchFileQuery();

  const { allEventsQuery } = useAppSelector(
    (state) => state.calendarReducer
  );

  const { isSelectedCheck } = useCheckSelected();

  const handleExport = async () => {
    try {
      if (
        !allEventsQuery || !("tags" in allEventsQuery) || !allEventsQuery.tags.length
      ) return;
      const response = await fetchFile({eventTypesTag: allEventsQuery.tags})
      if (typeof response.data !== "string") throw new Error(response.error?.message)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "calendar.ics");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      handleError();
    }
  };

  const exportButton =
    allEventsQuery && "tags" in allEventsQuery && allEventsQuery.tags.length > 0
      ? [{ name: "Экспортировать", action: handleExport }]
      : [];

  const popoverButtons = [...exportButton];

  return (
    <>
      <Box display="flex" justifyContent="space-between" px="16px">
        <Tabs
          value={false}
          variant="scrollable"
          scrollButtons={isMobile ? false : "auto"}
          sx={[styleGroup(theme), styles]}
        >
          <Box>
            <ToggleButtonGroup
              aria-label="scrollable events"
              sx={[styleGroup(theme), styles]}
            >
              {buttons.map(({ id, tags, text }) => (
                <ToggleButton
                  key={id}
                  selected={isSelectedCheck(tags)}
                  value={tags}
                  onChange={() => dispatch(changeTagsQuery(tags))}
                >
                  {text}
                </ToggleButton>
              ))}
              <CalendarApplications />
            </ToggleButtonGroup>
          </Box>
        </Tabs>
        {Boolean(popoverButtons.length) && (
          <EditPopover buttonsData={popoverButtons} />
        )}
      </Box>
    </>
  );
};
