import React, { Dispatch, FC, SetStateAction } from 'react';
import moment from 'moment';

import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import InputWrapper from '../../common/Inputs/InputWrapper/InputWrapper';
import { UserInfo, UserInfoValidation } from '../../../types/UserInfo.types';
import { roles } from '../../../constants/role';
import Post from '../PersonalInfo/Post/Post';
import Department from '../PersonalInfo/Department/Department';
import { PersonView } from '../../../models/profile.models';
import Projects from '../PersonalInfo/Projects/Projects';

interface PersonalInfoProps {
  personData: UserInfo;
  setPersonData: Dispatch<SetStateAction<UserInfo>>;
  newAvatar: File | null;
  setNewAvatar: Dispatch<SetStateAction<File | null>>;
  validation: UserInfoValidation;
  bgInfo: PersonView['background']
}

export const Career: FC<PersonalInfoProps> = ({
  personData,
  setPersonData,
  validation,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('laptop'));

  const currentUserRole = JSON.parse(
    localStorage.getItem('REACT_TOKEN_AUTH_KEY') || '{}',
  ).role;

  const disableEditInfo = currentUserRole === roles.user;

  return (
    <Stack spacing={4} width="100%">
      <Stack
        direction={isMobile ? 'column' : 'row'}
        sx={{ width: '100%', minWidth: 0 }}
        gap={isMobile ? '3rem' : '3rem'}
      >
        <InputWrapper key={'Должность'} title={'Должность'} styles={{ width: '100%' }} element={
          <Post
            personData={personData}
            setPersonData={setPersonData}
            disabled={disableEditInfo}
            validation={validation}
          />}
        />
        <InputWrapper key={'Департамент'} title={'Департамент'} nameAlign="right" element={
          <Department
            personData={personData}
            setPersonData={setPersonData}
            disabled={disableEditInfo}
            validation={validation}
          />}
        />
      </Stack>
      <Stack
        direction={isMobile ? 'column' : 'row'}
        sx={{ width: '100%', minWidth: 0 }}
        gap={isMobile ? '3rem' : '1rem'}
      >
        <InputWrapper key={'Проекты'} title={'Проекты'} element={
          <Projects personData={personData} setPersonData={setPersonData} disabled={false} />
        } />
      </Stack>
    </Stack>
  );
};

export default Career;
