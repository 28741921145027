import React, {createContext, Dispatch, SetStateAction, useContext, useState} from 'react'
import Snackbars, {ISnackBar} from '../../common/Snackbars/Snackbars'
import {useReviewsState} from "../hooks/useReviewsState";
import {usePaginationState} from "../hooks/usePaginationState";
import {useModalState} from "../hooks/useModalState";
import {useSearchState} from "../hooks/useSearchState";
import {useRowIdState} from "../hooks/useRowIdState";
import {useReviewDateState} from "../hooks/useReviewDateState";
import {defaultValue} from "./ReviewPageContextDefaultValue";

interface IReviewContextProps {
    children: React.ReactNode
}

export interface IReviewContextValue {
    handleClose: (value: boolean) => void;
    snackBar: ISnackBar;
    setSnackBar: Dispatch<SetStateAction<ISnackBar>>;
    reviewsState: ReturnType<typeof useReviewsState>;
    paginationState: ReturnType<typeof usePaginationState>;
    modalState: ReturnType<typeof useModalState>;
    searchState: ReturnType<typeof useSearchState>;
    sortDirection: 'asc' | 'desc' | null | undefined;
    setSortDirection: Dispatch<SetStateAction<'asc' | 'desc' | null | undefined>>;
    row: ReturnType<typeof useRowIdState>;
    reviewDateState: ReturnType<typeof useReviewDateState>
}

const ReviewPageContext = createContext<IReviewContextValue>(defaultValue);

export const ReviewPageContextProvider = ({children}: IReviewContextProps) => {
    const [snackBar, setSnackBar] = useState<ISnackBar>({message: '', open: false, type: 'success'});

    const reviewsState = useReviewsState();
    const paginationState = usePaginationState();
    const modalState = useModalState();
    const searchState = useSearchState();

    const reviewDateState = useReviewDateState()
    const row = useRowIdState()

    const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | null | undefined>('desc');

    const handleClose = (value: boolean) => {
        setSnackBar(prev => ({...prev, open: value}));
    };

    return (
        <ReviewPageContext.Provider
            value={{
                handleClose,
                snackBar,
                setSnackBar,
                reviewsState,
                paginationState,
                modalState,
                searchState,
                sortDirection,
                setSortDirection,
                row,
                reviewDateState

            }}
        >
            {children}
            <Snackbars
                open={snackBar.open}
                setOpen={handleClose}
                type={snackBar.type}
                position={'center'}
                message={snackBar.message}
            />
        </ReviewPageContext.Provider>
    );
};

export const useReviewPage = () => {
    return useContext(ReviewPageContext);
};
