import React, { FC } from "react";

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import bg from "../../../assets/images/backgroudDefault.png";
import { PersonView } from "../../../models/profile.models";

export interface MainInfoProps {
  userInfo: PersonView;
}
export const Media: FC<MainInfoProps> = ({ userInfo }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const styleCardContent = {
    position: "absolute",
    top: "50%",
    left: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    transform: "translate(-50%, -50%)",
    width: "100%",
  };

  const imgSrc = userInfo.background
    ? `/loyalty-portal/api/attachments/${encodeURIComponent(
      userInfo.background.name
    )}${userInfo.background.extension}`
    : bg;

  const bgStyle = {
    width: "100%",
    height: isMobile ? 396 : 468,
    position: "relative",
    backgroundImage: `linear-gradient(to bottom, rgba(19, 23, 34, 0.15) 0%, rgba(19, 23, 34, 0.75) 100%), url("${imgSrc}")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  return (
    <Card sx={{ position: "relative", borderRadius: isMobile ? 0 : "16px" }}>
      <CardMedia component="div" sx={bgStyle} />
      <CardContent sx={styleCardContent} />
    </Card>
  );
};

export default Media;
