import React, {FC, useEffect, useState} from 'react';
import {RowCardTable} from '../RowCardTable/RowCardTable';
import Box from '@mui/material/Box';
import {tableActivity} from './styles/tableActivity';
import {activityController, useDeleteActivityMutation} from '../../../../services/activity.controller';
import {useScrollShadow} from '../../helpers/useScrollShadow';
import {toggleModal} from '../../../../reducer/bonusesModalSlice';
import {useDispatch} from 'react-redux';
import CustomButton from '../../../common/Buttons/CustomButton/CustomButton';
import ConfirmModal from '../../../common/Modals/ConfirmModal/ConfirmModal';
import {useAppSelector} from '../../../../hooks/useAppSelector';

export const TableActivity: FC = () => {
    const dispatch = useDispatch();
    const {data} = activityController.useGetAllActivityQuery('');
    const activity = data?.items;
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const {scrollBoxRef, applyShadowEffect} = useScrollShadow<HTMLDivElement>();
    const [deleteActivity] = useDeleteActivityMutation();
    const {selectedEntityId} = useAppSelector((state) => state.bonusesModal);
    const user = useAppSelector((state) => state.profileReducer.profile);
    const roles = ['EDITOR', 'ADMIN']
    const userRole = user.role.role;
    const hasRole = roles.some(role => userRole.includes(role));

    const handleClick = () => {
        dispatch(toggleModal({type: 'createActivity', isOpenView: true}));
    };

    useEffect(() => {
        applyShadowEffect();
    }, [activity, applyShadowEffect]);

    const handleConfirmModal = (id?: string) => {
        setOpenConfirmModal((prev) => !prev);
        dispatch(toggleModal({type: 'delete', isOpenView: true, id}));
    };
    const handleDelete = () => {
        deleteActivity(selectedEntityId)
        setOpenConfirmModal(false);
        dispatch(toggleModal({type: null, isOpenView: false}));
    };

    return (
        <Box>
            {hasRole && <Box display={'flex'} justifyContent={'flex-end'} my={1}>
                <CustomButton text={'+Добавить'} styles={{width: '25%'}} onClick={handleClick} color={'secondary'}/>
            </Box>}
            <Box ref={scrollBoxRef} maxHeight={300} overflow="auto" sx={{
                ...tableActivity.scrollBox, position: 'relative',
                p: 2
            }}>
                {activity?.map(({title, cost, id}) =>
                    <RowCardTable
                        key={id}
                        title={title}
                        cost={cost}
                        type="activity"
                        id={id}
                        onConfirmModal={() => handleConfirmModal(id)}
                        typeEdite={'editeActivity'}
                    />
                )}
                <ConfirmModal
                    openConfirmModal={openConfirmModal}
                    handleModal={handleConfirmModal}
                    nameConfirmButton="Удалить"
                    handleConfirm={handleDelete}
                    text="Активность будет удалена."
                />
            </Box>
        </Box>
    )
}
