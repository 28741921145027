import React, {useContext} from 'react';
import {Navigate, Route, Routes as RouterRoutes} from "react-router";
import Feed from "../components/Feed/Feed";
import MessageComments from "../components/Feed/MessageComments/MessageComments";
import MorePage from "../components/layout/mobile/RemainingMenu/RemainingMenu";
import Profile from "../components/Profile/Profile";
import UserEditing from "../components/UserEditing/UserEditing";
import Company from "../components/Company/Company";
import KnowledgeBase from "../components/KnowledgeBase/KnowledgeBase";
import Calendar from "../components/Calendar/Calendar";
import {ReviewPageWrapper} from "../components/Review/ReviewPage/ReviewPageWrapper";
import Ideas from "../components/Ideas/Ideas";
import Bonuses from "../components/Bonuses/Bonuses";
import IdeaComments from "../components/Ideas/IdeaComments/IdeaComments";
import Community from "../components/Community/Community";
import Notifications from "../components/Notifications/Notifications";
import PasswordReset from "../components/PasswordReset/PasswordReset";
import LoginPage from "../components/LoginPage/LoginPage";
import LoginNew from "../components/LoginPage/LoginNew/LoginNew";
import {AppContext} from "../components/shared/AppContextProvider";

interface ProtectedRouteProps {
    allowedRoles: string[];
    children: React.ReactNode;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({allowedRoles, children}) => {
    const {user} = useContext(AppContext);

    if (!user || !allowedRoles.includes(user.role)) {
        return <Navigate to="/feed" replace/>;
    }
    return <>{children}</>;
};

const Routes = () => {
    const {user} = useContext(AppContext);
    return (
        <>
            {user && (
                <RouterRoutes>
                    <Route path="/feed" element={<Feed/>}/>
                    <Route path="/feed/:feedId" element={<MessageComments/>}/>
                    <Route path="/more" element={<MorePage/>}/>
                    <Route path="/profile/:personId/*" element={<Profile/>}/>
                    <Route path="/profile/edit" element={<UserEditing/>}/>
                    <Route path="/company" element={<Company/>}/>
                    <Route path="/knowledge/*" element={<KnowledgeBase/>}/>
                    <Route path="/calendar/*" element={<Calendar/>}/>
                    <Route path="/review/*" element={<ProtectedRoute allowedRoles={['ADMIN', 'EDITOR']}><ReviewPageWrapper/></ProtectedRoute>}/>
                    <Route path="/ideas" element={<Ideas/>}/>
                    <Route path="/bonuses" element={<Bonuses/>}/>
                    <Route path="/ideas/:ideaId" element={<IdeaComments/>}/>
                    <Route path="/community/*" element={<Community/>}/>
                    <Route path="/notifications" element={<Notifications/>}/>
                    <Route path="*" element={<Navigate to="/feed" replace/>}/>
                </RouterRoutes>
            )}
            {!user && (
                <RouterRoutes>
                    <Route
                        path="/reset_password/:resetCode"
                        element={<PasswordReset/>}
                    />
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route path="/login/new" element={<LoginNew/>}/>
                    <Route
                        path="/login_link/:loginCode"
                        element={<LoginPage/>}
                    />
                    <Route path="*" element={<Navigate to="/login" replace/>}/>
                </RouterRoutes>
            )}
        </>
    );
};

export default Routes;