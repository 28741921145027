import React, {FC, useContext} from "react";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import Groups from "../../LeftSidebar/CommunityList/CommunityList";
import BugReport from "../../../BugReport/BugReport";
import MenuList from "../../../common/MenuList/MenuList";
import {AppContext} from "../../../shared/AppContextProvider";
import {useMenuItems} from "../../Navigation/hooks/useMenuItems";
import {navigationItems} from "../../Navigation/navigationItems";
import {Role} from "../../../../constants/role";
import ServicesList from "../../LeftSidebar/ServicesList/ServicesList";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {servicesItems} from "../../LeftSidebar/ServicesList/servicesItems";
import {iconProps} from "../../LeftSidebar/ServicesList/styles/styles";
import SvgIcon from "@mui/material/SvgIcon";
import {ReactComponent as ServicesIcon} from "../../../../assets/icons/services.svg";

export const RemainingMenu: FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));
    const {user} = useContext(AppContext);

    const filteredRolesItems = navigationItems
        .filter((item) => !item.requiredRoles || item.requiredRoles.includes(user.role as Role))

    const {hiddenItems} = useMenuItems({filteredRolesItems})

    return (
        <Container>
            <Typography variant="h4" component="h4" mb={1}>
                Ещё
            </Typography>
            <MenuList
                testId="mainMenu"
                items={hiddenItems}
            />
            {isMobile && (
                <>
                <ServicesList items={servicesItems}>
                    <ServicesList.Icon>
                        <SvgIcon component={ServicesIcon} {...iconProps} />
                    </ServicesList.Icon>
                    <ServicesList.Text>
                        <>Сервисы</>
                    </ServicesList.Text>
                </ServicesList>
                <BugReport type="mobile"/>
                </>
            )}
            <Groups forRemainingMenu/>
        </Container>
    );
};

export default RemainingMenu;