import React, {FC, useState, useEffect, useContext, useMemo} from "react";

import {Box} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {CalendarPicker} from "@mui/x-date-pickers/CalendarPicker";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";

import moment, {Moment} from "moment";
import "moment/locale/ru";

import {CalendarButtons} from "../CalendarButtons";
import CalendarEventCreator from "../CalendarEventCreator/CalendarEventCreator";
import CalendarDay from "../CalendarDay/CalendarDay";
import {CalendarContext} from "../CalendarContext/CalendarContextProvider";
import CalendarPopup from "../CalendarPopup/CalendarPopup";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {WorkCalendarToggle} from "../WorkCalendarToggle";

import {useAllEvents} from "./hooks";

import {getCalendarStyles} from "./styles";

export const CalendarContent: FC = () => {
    const [date, setDate] = useState<Moment>(moment());

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

    const {setYear, onCalendarError: handleError} = useContext(CalendarContext);

    const {eventsError} = useAllEvents();
    if (eventsError) handleError();

    const [isEventDialogOpen, setIsEventDialogOpen] = useState<boolean>(false);
    const closeNotificationEventDialog = () => {
        setIsEventDialogOpen(false);
        window.history.replaceState(null, "", `/calendar/${date.format("DD-MM-YYYY")}`);
    };
    const eventId = window.location.search.slice(7);

    const dayEvents = useAppSelector(
        (state) => state.calendarReducer.allEvents[date.format("DD-MM-YYYY")]
    );
    const dayEvent = dayEvents?.find(
        (calendarEvent) => calendarEvent.id === eventId
    );

    useEffect(() => {
        if (eventId) {
            setDate(moment(window.location.pathname.split("/")[2], "DD-MM-YYYY"));
            setIsEventDialogOpen(true);
        }
    }, [eventId]);

    const calendarStyles = useMemo(() => {
        return getCalendarStyles(isMobile)
    }, [isMobile])

    return (
        <>
            <CalendarButtons/>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Box sx={calendarStyles} display="flex" justifyContent="space-between">
                    <CalendarPicker
                        views={["day"]}
                        date={date}
                        onChange={dateValue => {
                            if (!dateValue) return
                            window.history.replaceState(null, "", `/calendar/${dateValue.format("DD-MM-YYYY")}`);
                            setDate(dateValue);
                        }}
                        onMonthChange={date => setYear(Math.min(date.year(), moment().year() + 1))}
                        showDaysOutsideCurrentMonth
                        renderDay={(date, _, pickersDayProps) => {
                            return <CalendarDay
                                key={date.format("DD-MM-YYYY")}
                                pickersDayProps={pickersDayProps}
                                date={date.format("DD-MM-YYYY")}/>
                        }
                        }
                    />
                </Box>
            </LocalizationProvider>
            <WorkCalendarToggle sx={[!isMobile && {paddingLeft: "1rem"}]}/>
            <Dialog
                open={isEventDialogOpen}
                onClose={closeNotificationEventDialog}
                fullScreen={isMobile}
                closeAfterTransition={false}
            >
                <CalendarPopup calendarDayEvents={dayEvent ? [dayEvent] : []} onClose={closeNotificationEventDialog}/>
            </Dialog>
            <CalendarEventCreator selectedDate={date.format("DD-MM-YYYY")}/>
        </>
    );
};

export default CalendarContent;
