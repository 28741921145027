export function formatCount(n: number, word: string, withNumber: boolean): string {
  const cases = [2, 0, 1, 1, 1, 2]; // Индексы для склонений

  // Функция для генерации форм слова
  function getWordForms(word: string): [string, string, string] {
    if (word.endsWith("й")) {
      return [word, word.slice(0, -1) + "я", word.slice(0, -1) + "ев"];
    }
    if (word.endsWith("а")) {
      return [word, word.slice(0, -1) + "ы", word.slice(0, -1)];
    }
    if (word.endsWith("я")) {
      return [word, word.slice(0, -1) + "и", word.slice(0, -1)];
    }
    if (word.endsWith("о") || word.endsWith("е")) {
      return [word, word, word];
    }
    return [word, word + "а", word + "ов"]; // По умолчанию
  }

  const [one, few, many] = getWordForms(word);

  const w = [one, few, many][n % 100 > 4 && n % 100 < 20 ? 2 : cases[Math.min(n % 10, 5)]]

  return withNumber ? `${n} ${w}` : w;
}