import React, { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';

import { UserInfo, UserInfoValidation } from '../../../../types/UserInfo.types';
import CustomSelect from '../../../common/Inputs/CustomSelect/CustomSelect';
import { useDepartments } from '../../../../hooks/useDepartments';
import { findItemById } from '../../../../operation/findItemById';

interface PersonalInfoProps {
  personData: UserInfo;
  setPersonData: Dispatch<SetStateAction<UserInfo>>;
  disabled: boolean;
  validation: UserInfoValidation;
}

const pageSize = 10;

export const Department: FC<PersonalInfoProps> = ({
  personData,
  setPersonData,
  disabled,
}) => {
  const [pageDepartment, setPageDepartment] = useState<number>(0);

  const departments = useDepartments(pageDepartment);

  const findDepartmentById = (id: string) => findItemById(departments.departments, id);

  const handleChangeDepartment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPersonData({ ...personData, department: findDepartmentById(event.target.value) });
  };

  // +1 - it's test, for some users, scrolling does not work because 1px is lost
  const isScrolledToBottom = (event: any) => {
    const target = event.target;
    return target.scrollTop + target.clientHeight + 1 >= target.scrollHeight;
  };

  const handleDeptsScroll = (event: any) => {
    if (
      isScrolledToBottom(event)
      && departments.departments.length < departments.size
      && !departments.loading
    ) {
      setPageDepartment(departments.departments.length / pageSize);
    }
  };

  const jobDeptItems = useMemo(() => (
    (!personData?.department || findDepartmentById(personData?.department?.id))
      ? departments.departments
      : [personData?.department!, ...departments.departments].filter((item) => !!item)
  ), [personData?.department, departments.departments]);

  return (
    <CustomSelect
      testid="department"
      items={jobDeptItems}
      value={personData?.department?.id ?? ""}
      onChange={handleChangeDepartment}
      disabled={disabled}
      onScroll={handleDeptsScroll}
      placeholder="Выберите департамент"
      height="400px"
    />
  );
};

export default Department;
