import React, { FC, useContext, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';

import { useAppSelector } from '../../../hooks/useAppSelector';
import { useCreateEventMutation } from '../../../services/calendar.controller';
import type { IEventsState, INewEvent } from '../../../types/CalendarEvent.type';
import CustomButton from '../../common/Buttons/CustomButton/CustomButton';
import ModalButtons from '../../common/Buttons/ModalButtons/ModalButtons';
import DialogWrapper from '../../common/Modals/DialogWrapper/DialogWrapper';
import { CalendarContext } from '../CalendarContext/CalendarContextProvider';
import EventModalContent from '../EventModalContent/EventModalContent';
import { transformEventForPostRequest } from '../helpers';

interface CalendarEventCreatorProps {
  selectedDate: string;
}

export const CalendarEventCreator: FC<CalendarEventCreatorProps> = ({ selectedDate }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [eventState, setEventState] = useState<INewEvent & Partial<IEventsState> | null>(null)
  const [createEvent, createEventData] = useCreateEventMutation();

  const eventTypes = useAppSelector((state) => state.calendarReducer.eventTypes);
  const userProfile = useAppSelector((state) => state.profileReducer.profile);
  const { onCalendarError: handleError, editTagsFilter } = useContext(CalendarContext);

  const isInvalidEvent = !eventState;
  const isLoading = createEventData.isLoading;
  const isInvalidTime =eventState && eventState?.finishTime <= eventState?.startTime;
  const isEmptyName = !eventState?.name;
  const isInvalidDate = !eventState?.date || eventState.date === "Invalid date"

  const disabled: boolean =
    isInvalidEvent || isLoading || isInvalidTime || isEmptyName || isInvalidDate;

  const defaultState: INewEvent | null = useMemo(() => {
    if (!eventTypes.length) return null
    const editEventsTypes = eventTypes.filter(({ tag }) =>
      editTagsFilter.includes(tag)
    );
    const newEventState: INewEvent & Partial<IEventsState> = {
      name: "",
      description: "",
      date: selectedDate,
      startTime: "00:00",
      finishTime: "23:59",
      recurrence: 0,
      calendarEventType: editEventsTypes[0]?.calendarEventType,
      eventTypeId: editEventsTypes[0]?.id,
      participantsEvent: [userProfile]
    };
    setEventState(newEventState);
    return newEventState;
  }, [editTagsFilter, eventTypes, selectedDate, userProfile]);

  const handleModal = () => {
    if (defaultState) setEventState({...defaultState});
    setOpenModal((prev) => !prev);
  };

  const handleCancel = () => {
    if (defaultState) setEventState({...defaultState});
    setOpenModal(false);
  };

  const handleSend = async () => {
    if (!eventState) return;
    const res = await createEvent({
      body: transformEventForPostRequest(eventState),
    });
    if ("error" in res) {
      const message: any = (res.error as any).data.errorMessage;
      handleError(typeof message === "string" ? message : undefined);
    } else {
      handleCancel();
    }
  };

  const modalButtons: React.ReactNode = (
    <ModalButtons
      nameButtonSend="Сохранить"
      handleSend={handleSend}
      handleCancel={handleCancel}
      justifyContent="end"
      disableSend={disabled}
      fullWidth
    />
  );

  const wrapperButtonStyle = {
    position: "fixed",
    left: "50%",
    bottom: { mobile: "128px", laptop: "40px" },
    transform: "translateX(-55%)",
  };

  return (
    <>
      <Box sx={wrapperButtonStyle}>
        <CustomButton
          color="secondary"
          text="Создать"
          startIcon={<AddIcon />}
          onClick={() => setOpenModal((prev) => !prev)}
        />
      </Box>
      <DialogWrapper
        openModal={openModal}
        handleModal={handleModal}
        width="862px"
        contentDividers
        stylesContent={{ padding: '0px', borderTop: 'none' }}
        buttons={modalButtons}
      >
        {eventState && <EventModalContent eventState={eventState} setEventState={setEventState} />}
      </DialogWrapper>
    </>
  );
};

export default CalendarEventCreator;
