import {IReviewContextValue} from "./ReviewPageContextProvider";

export const defaultValue: IReviewContextValue = {
    handleClose: () => {
    },
    snackBar: {message: '', open: false, type: 'success'},
    setSnackBar: () => {
    },
    reviewsState: {
        reviews: [],
        setReviews: () => {
        },
        loading: true,
        setLoading: () => {
        },
        status: "Активные",
        setStatus: () => {
        },
        totalRecords: 0,
        setTotalRecords: () => {
        }
    },
    paginationState: {
        activePage: 0, setActivePage: () => {
        }, archivePage: 0, setArchivePage: () => {
        }
    },
    modalState: {
        modalStatus: null,
        setModalStatus: () => {
        },
        modalEditStatus: null,
        setModalEditStatus: () => {
        },
        openEditModal: false,
        setOpenEditConfirmModal: () => {
        },
        openNotificationModal: false,
        setOpenNotificationModal: () => {
        }
    },
    searchState: {
        searchValue: '', setSearchValue: () => {
        }
    },
    sortDirection: 'desc',
    setSortDirection: () => {
    },
    row: {
        rowId: null,
        setRowId: () => {
        }
    },
    reviewDateState: {
        selectedReviewDate: {
            selectedStartReviewDate: '',
            selectedEndReviewDate: ''
        },
        setSelectedReviewDate: () => {
        }
    },
};