import React, { FC, useContext, useState } from "react";

import AddIcon from "@mui/icons-material/Add";

import CustomButton from "../../../common/Buttons/CustomButton/CustomButton";
import { AchievContext } from "../../AchievContextProvider/AchievContextProvider";
import ClearArchive from "./ClearArchive/ClearArchive";
import AchievModal from "../AchievModal/AchievModal";
import { TAchiveStatus } from '../../../../types/Achievements.types';
import { EAchivementType } from '../../../../constants/achivement';
import { useAppSelector } from "../../../../hooks/useAppSelector";

interface AchievActionsProps {
  currentAchivementsType: TAchiveStatus;
  fullWidth?: boolean;
}
export const AchievActions: FC<AchievActionsProps> = ({
                                                        currentAchivementsType,
  fullWidth,
}) => {
  const { blocked } = useAppSelector((state) => state.profileReducer.editProfile);
  const { editableAcces } = useContext(AchievContext);
  const [openClearModal, setOpenClearModal] = useState<boolean>(false);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const archived = currentAchivementsType === EAchivementType.ARCHIVE

  const handleCreateModal = () => {
    setOpenCreateModal(!openCreateModal);
  };

  const clearArchive = () => setOpenClearModal(!openClearModal);

  const openModal = () => {
    handleCreateModal();
  };

  return (
    <>
      {editableAcces && (
        <CustomButton
          color="secondary"
          text={archived ? "Очистить архив" : "Добавить цель"}
          startIcon={!archived ? <AddIcon /> : undefined}
          onClick={archived ? clearArchive : openModal}
          fullWidth={fullWidth}
          disabled={blocked}
        />
      )}
      <ClearArchive isOpen={openClearModal} handleModal={clearArchive} />
      <AchievModal
        type="create"
        openModal={openCreateModal}
        handleModal={handleCreateModal}
      />
    </>
  );
};

export default AchievActions;
