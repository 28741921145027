import React, { FC } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import {theme} from "../../../constants/theme";

export interface PapersDataObj {
  title: string
  name: string
  info: string
  link?: string
  icon?: React.JSX.Element
}
export interface PapersDataProps {
  data: PapersDataObj[],
  title?: string
}

export const PapersElement: FC<PapersDataProps> = ({ data, title }) => {
  const stylePaper = {
    p: 1,
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "18px",
  };

  const styleTitle = {
    color: theme.palette?.text?.primary,
    textDecorationColor: theme.palette?.text?.primary,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  if (data.length === 0) {
    return null;
  }

  const PaperItemInfo = ({ link, info }: PapersDataObj) => {
    if (link) {
      return <Link variant="body2" href={link} sx={styleTitle} title={info}>{info}</Link>
    }
    return <Typography variant="body2" sx={styleTitle}>{info}</Typography>
  }

  return (
    <Box sx={{ flexGrow: 1, width: "100%" }}>
      {title && <Typography align="center" variant="h4" mb={4}>
        {title}
      </Typography>}
      <Grid container spacing={2}>
        {data.map((item, index) => item.info ? (
          <Grid item mobile={12} laptop={6} key={index}>
            <Paper sx={stylePaper}>
              <Typography
                  variant="subtitle1"
                  color={(theme) => theme?.palette?.primary.main}
              >
                {item.title}
              </Typography>
              <Stack direction="row" maxWidth="100%" spacing={2}>
                {item?.icon}
                <PaperItemInfo {...item} />
              </Stack>
            </Paper>
          </Grid>
        ) : null)}
      </Grid>
    </Box>
  );
};

export default PapersElement;
