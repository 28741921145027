import { createApi } from '@reduxjs/toolkit/query/react';

import { apiUrlPortal } from '../constants/apiUrl';
import { ISkill, ISkillFetchQuery } from '../types/Skill.type';
import { customBaseQuery } from './customBaseQuery';
import jwtInterceptor from './jwtInterceptor';

export const skillController = {
  getSkills: async (): Promise<any> => {
    return await jwtInterceptor.get(`${apiUrlPortal}/skill`);
  },
};

export const skillApi = createApi({
  reducerPath: 'skillApi',
  baseQuery: customBaseQuery,
  tagTypes: ['Skills'],
  endpoints: (builder) => ({
    fetchSkill: builder.query<{ items: ISkill[]; totalItems: number }, ISkillFetchQuery | void>({
      query: (params) => ({
        url: 'skill',
        method: 'GET',
        params: params ?? undefined,
      }),
      providesTags: ['Skills'],
    }),
  }),
});

export const { useFetchSkillQuery } = skillApi;
