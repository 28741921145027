import React, {FC, memo, useEffect, useState} from 'react';
import {formChangeBonuses} from './styles/formChangeBonuses';
import {Box, Typography} from '@mui/material';
import DialogWrapper from '../../../common/Modals/DialogWrapper/DialogWrapper';
import CustomSelect from '../../../common/Inputs/CustomSelect/CustomSelect';
import InputFilled from '../../../common/Inputs/InputFilled/InputFilled';
import {useLazyGetAllGiftsByUserIdQuery} from '../../../../services/gift.controller';
import {useAppSelector} from '../../../../hooks/useAppSelector';
import {TGiftView} from '../../../../models/gifts.models';
import {getPointsText} from '../../helpers/getPointsText';
import {useCreateReviewGiftMutation} from '../../../../services/person_gifts.controller';
import {showSnackbar} from '../../../../reducer/snackbarSlice';
import {useDispatch} from 'react-redux';
import {ModalActionButtons} from '../ModalActionButtons/ModalActionButtons';

interface FormChangeBonusesProps {
    toggleModal: (modal: 'actives' | 'gift', state: boolean) => () => void;
    openModal: boolean;
}

export const FormChangeBonuses: FC<FormChangeBonusesProps> = ({openModal, toggleModal}) => {
    const dispatch = useDispatch()
    const [formData, setFormData] = useState<{
        gift: string;
        message: string;
        selectGift?: TGiftView;
    }>({
        gift: '',
        message: '',
        selectGift: undefined,
    });
    const updateFormData = (key: keyof typeof formData, value: any) => {
        setFormData((prev) => ({
            ...prev,
            [key]: value,
        }));
    };
    const currentUser = useAppSelector((state) => state.profileReducer.profile);
    const [fetchGifts, {data: userData}] = useLazyGetAllGiftsByUserIdQuery()
    useEffect(() => {
        if (openModal) {
            fetchGifts(currentUser.id ?? '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openModal]);
    const giftsUserId = userData?.items?.map(item => ({
        id: item.id,
        name: item.title,
    }))
    const [createReviewGift] = useCreateReviewGiftMutation()

    useEffect(() => {
        if (!formData.gift) return;
        const selected = userData?.items?.find(item => item.id === formData.gift)
        updateFormData('selectGift', selected)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.gift]);

    const chosenGift = (value: string) => {
        updateFormData('gift', value)
    }
    const handleClose = () => {
        setFormData({
            gift: '',
            message: '',
            selectGift: undefined,
        });
        toggleModal('gift', false)()
    }

    const handleChangeBonuses = async () => {
        try {
            const newReview = {
                giftId: formData.gift,
                message: formData.message,
                ownerId: currentUser.id
            }
            await createReviewGift(newReview).unwrap();
            dispatch(showSnackbar({
                message: 'Заявка успешно создана!',
                type: 'success',
                position: 'center',
            }));

            handleClose();
        } catch (error) {

            dispatch(showSnackbar({
                message: 'Ошибка: заявка не создана. Проверьте данные.',
                type: 'error',
                position: 'center',
            }));
        }
    }

    return (
        <DialogWrapper
            openModal={openModal}
            handleModal={handleClose}
            width="532px"
            contentDividers
            buttons={<ModalActionButtons
                onCancel={handleClose}
                onSubmit={handleChangeBonuses}
            />}
            header={'Заявка на получение подарка'}
        >
            <Box
                display="flex"
                alignItems="center"
                gap={2}>
                <Typography sx={formChangeBonuses.text}>Выберите доступный подарок</Typography>
                <Box width={'44%'}>
                    <CustomSelect
                        items={giftsUserId ?? []}
                        value={formData.gift}
                        onChange={(e) => chosenGift(e.target.value)}
                    />
                </Box>
                <Typography
                    sx={formChangeBonuses.text}>
                    {formData.selectGift?.cost ?
                        `Стоимость ${formData.selectGift?.cost} ${getPointsText(formData.selectGift.cost)}` :
                        ''
                    }
                </Typography>
            </Box>
            <Box
                mt={2}
            >
                <Typography sx={formChangeBonuses.text}>Введите ваше сообщение</Typography>
                <InputFilled
                    margin={'8px 0'}
                    multiline
                    value={formData.message}
                    handleValue={e => updateFormData('message', e.target.value)}
                />
            </Box>

        </DialogWrapper>
    );
};

export default memo(FormChangeBonuses);
