import { apiUrlPortal } from "../constants/apiUrl";
import {
  DeleteAttachmentsRequest,
  UpdateRecordRequest,
} from "../models/record.models";
import jwtInterceptor from "./jwtInterceptor";

export const feedController = {
  //Feed
  feed: async (
    page?: number,
    important?: boolean,
    size?: number
  ): Promise<any> => {
    return await jwtInterceptor.get(
      `${apiUrlPortal}/feed?sort=date,desc&page=${page ?? 0}&important=${
        important ?? false
      }&size=${size ?? ""}`
    );
  },
  countsPinnedRecords: async (important: boolean): Promise<any> => {
    return await jwtInterceptor.get(`${apiUrlPortal}/feed/getCountsSequence?important=${important}`);
  },
  newFeed: async (body?: any): Promise<any> => {
    return await jwtInterceptor.post(`${apiUrlPortal}/feed`, body);
  },
  feedItem: async (id?: string | null) => {
    return await jwtInterceptor.get(`${apiUrlPortal}/feed/${id}`);
  },
  updateFeedItem: async (
    feedId: string | null,
    body: any
  ): Promise<any> => {
    return await jwtInterceptor.put(`${apiUrlPortal}/feed/${feedId}`, body);
  },
  deleteFeedItem: async (feedId: string | null): Promise<any> => {
    return await jwtInterceptor.delete(`${apiUrlPortal}/feed/${feedId}`);
  },
  addAttachFeedItem: async (
    feedId: string | null,
    body: FormData
  ): Promise<any> => {
    return await jwtInterceptor.put(
      `${apiUrlPortal}/feed/${feedId}/attachments`,
      body
    );
  },
  deleteAttachFeedItem: async (
    feedId: string | null,
    body: DeleteAttachmentsRequest
  ): Promise<any> => {
    return await jwtInterceptor.delete(
      `${apiUrlPortal}/feed/${feedId}/attachments`,
      { data: body }
    );
  },

  //Comments
  feedItemComments: async (
    id?: string | null,
    page?: number,
    size?: number
  ): Promise<any> => {
    return await jwtInterceptor.get(
      `${apiUrlPortal}/feed/${id}/comments?sort=date,desc&page=${page}&size=${
        size ?? ""
      }`
    );
  },
  newFeedItemComment: async (id?: string | null, body?: any): Promise<any> => {
    return await jwtInterceptor.post(
      `${apiUrlPortal}/feed/${id}/comments`,
      body
    );
  },
  editFeedItemComments: async (
    feedId: string | null,
    commentId: string,
    body: UpdateRecordRequest
  ): Promise<any> => {
    return await jwtInterceptor.put(
      `${apiUrlPortal}/feed/${feedId}/comments/${commentId}`,
      body
    );
  },
  deleteFeedItemComments: async (
    feedId: string | null,
    commentId: string
  ): Promise<any> => {
    return await jwtInterceptor.delete(
      `${apiUrlPortal}/feed/${feedId}/comments/${commentId}`
    );
  },
  addAttachmentsFeedComments: async (
    feedId: string | null,
    commentId: string,
    body: FormData
  ): Promise<any> => {
    return await jwtInterceptor.put(
      `${apiUrlPortal}/feed/${feedId}/comments/${commentId}/attachments`,
      body
    );
  },
  deleteAttachmentsFeedComments: async (
    feedId: string | null,
    commentId: string,
    body: DeleteAttachmentsRequest
  ): Promise<any> => {
    return await jwtInterceptor.delete(
      `${apiUrlPortal}/feed/${feedId}/comments/${commentId}/attachments`,
      { data: body }
    );
  },
};
