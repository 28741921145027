import {TStylesType} from '../../../../../types/Style.types';

export const headerButtons: TStylesType = {
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        gap: 0.9
    },
    button: {
        backgroundColor: 'rgba(185, 215, 255, 0.08)',
        width: '24%',
        color: 'rgba(230, 237, 245, 1)',
        fontSize: '12px',
        lineHeight: '12px',
        "&:hover": {
            backgroundColor: 'rgba(203, 225, 255, 0.17)'
        }
    }
}