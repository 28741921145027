import {createApi} from '@reduxjs/toolkit/query/react';
import {customBaseQuery} from './customBaseQuery';
import {Activity, ActivityView} from '../models/activity.models';

export const activityController = createApi({
    reducerPath: 'activityController',
    baseQuery: customBaseQuery,
    tagTypes: ['Activity'],
    endpoints: (builder) => ({
        getAllActivity: builder.query<Activity, ''>({
            query: () => ({
                url: 'activity?size=1000'
            }),
            providesTags: ['Activity'],
        }),
        getActivityByID: builder.query<ActivityView, string>({
            query: (id) => ({
                url: `activity/get/${id}`
            }),
        }),
        createActivity: builder.mutation<Omit<ActivityView, 'id'>, unknown>({
            query: (body) => ({
                url: 'activity',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Activity'],
        }),
        editActivity: builder.mutation<ActivityView, { id: string; body: Omit<ActivityView, 'id'>  }>({
            query: ({id, body}) => ({
                url: `activity/${id}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['Activity'],
        }),
        deleteActivity: builder.mutation<unknown, string>({
            query: (id) => ({
                url: `activity/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Activity'],
        }),
    }),
});

export const {
    useGetAllActivityQuery,
    useLazyGetAllActivityQuery,
    useGetActivityByIDQuery,
    useLazyGetActivityByIDQuery,
    useCreateActivityMutation,
    useEditActivityMutation,
    useDeleteActivityMutation,
} = activityController;

