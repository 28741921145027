import React, {FC} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../hooks/useAppSelector";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import {determineDate} from "../../../operation/determineDate";
import CustomButton from "../../common/Buttons/CustomButton/CustomButton";
import {ReactComponent as NotificationsIcon} from "../../../assets/icons/notifications.svg";
import {dataInitial} from "../../../actions/data";
import {notificationsController} from "../../../services/notifications.controller";

import type {NotificationView} from "../../../models/notifications.model";

interface NotificationsPopupProps {
    isOpened: boolean;
    handleClose: () => void;
}

export const NotificationsPopup: FC<NotificationsPopupProps> = ({
                                                                    isOpened,
                                                                    handleClose,
                                                                }) => {
    const navigate = useNavigate();
    const handleShowAll = () => {
        navigate("/notifications");
        handleClose();
    };

    const dispatch = useDispatch();
    const notifications: NotificationView[] = useAppSelector(
        (state) => state.dataReducer.notifications
    );
    const liveNotifications: NotificationView[] = useAppSelector(
        (state) => state.dataReducer.liveNotifications
    );

    const notificationBoxStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        padding: "12px 16px",
        borderTop: "1px solid rgba(241, 247, 255, 0.12)",
        ":hover": {
            backgroundColor: "rgba(185, 215, 255, 0.08)",
            cursor: "pointer",
        },
        ":active": {
            backgroundColor: "rgba(51, 146, 255, 0.25)",
        },
        color: "rgba(230, 237, 245, 1)",
        textDecoration: "none",
        wordBreak: "break-word",
        whiteSpace: "pre-wrap",
    };

    const notificationMarkStyle = {
        width: "8px",
        height: "8px",
        backgroundColor: "rgba(24, 132, 255, 1)",
        borderRadius: "4px",
    };

    const cardStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        minWidth: 280,
        backgroundColor: "#212633",
        boxShadow: "none",
    };

    const cardHeaderStyle = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "24px 16px 0px",
        paddingBottom: "24px",
    };

    const cardContentStyle = {
        display: "flex",
        flexDirection: "column",
        padding: "0px 16px 16px",
        height: "100%",
        overflowY: "scroll",
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
            display: "none",
        },
    };

    const cardActionsStyle = {
        borderTop: "1px solid rgba(241, 247, 255, 0.1)",
        padding: "16px",
    };

    return (
        <Dialog
            open={isOpened}
            onClose={handleClose}
            aria-labelledby="dialog"
            aria-describedby="dialog-open"
            fullScreen
            closeAfterTransition={false}
        >
            <Card sx={cardStyle}>
                <Box {...cardHeaderStyle}>
                    <Typography variant="h4">Уведомления</Typography>
                    <IconButton color="secondary" onClick={handleClose}>
                        <CloseIcon sx={{width: "24px", height: "24px"}}/>
                    </IconButton>
                </Box>
                <CardContent sx={cardContentStyle}>
                    {liveNotifications.length > 0 ? (
                        liveNotifications.map((notification) => {
                            const notificationBoxProps = {
                                sx: notificationBoxStyle,
                                component: NavLink,
                                to: `${notification.link}`,
                                onClick: () => {
                                    handleClose();
                                    notificationsController.markNotification(notification.id);
                                    dispatch(dataInitial.liveNotifications(
                                        liveNotifications.filter(liveNotification => liveNotification.id !== notification.id)
                                    ));
                                    dispatch(dataInitial.notifications(
                                        notifications.filter(notificationItem => notificationItem.id !== notification.id)
                                    ));
                                },
                            };
                            return (
                                <Box {...notificationBoxProps} key={notification.id}>
                                    <Typography variant="body2" pr="24px">{notification.content}</Typography>
                                    <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
                                        <Typography variant="body1"
                                                    color="rgba(229, 235, 242, 0.65)">{determineDate(notification.date)}</Typography>
                                        <Box sx={notificationMarkStyle}/>
                                    </Box>
                                </Box>
                            );
                        })
                    ) : (
                        <Box display="flex" flexDirection="column" alignItems="center" gap="16px" height="100%"
                             justifyContent="center">
                            <NotificationsIcon/>
                            <Typography variant="body1" color="rgba(229, 235, 242, 0.65)">
                                Нет новых уведомлений
                            </Typography>
                        </Box>
                    )}
                </CardContent>
                <CardActions sx={cardActionsStyle}>
                    <CustomButton onClick={handleShowAll} color="secondary" text="Показать все"
                                  styles={{width: "100%"}}/>
                </CardActions>
            </Card>
        </Dialog>
    );
};

export default NotificationsPopup;
