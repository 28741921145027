import React, {FC, useCallback, useState} from 'react';

import Container from '@mui/material/Container';

import HeaderButtons from './HeaderButtons/HeaderButtons';
import {UserActivityTable} from './UserActivityManagement/UserActivityTable';
import {UserGiftsTable} from './UserGiftsManagement/UserGiftsTable';
import type {PersonView} from '../../../models/profile.models';
import {useAppSelector} from '../../../hooks/useAppSelector';
import {TableGifts} from './TableGifts/TableGifts';
import {TableActivity} from './TableActivity/TableActivity';
import {setDateRange, setSearchName} from '../../../reducer/searchFiltersSlice';
import {useDispatch} from 'react-redux';

type TableType = 'gifts' | 'active' | 'managementActivity' | 'managementGifts';

export const TableSection: FC = () => {
    const [activeTable, setActiveTable] = useState<TableType>('gifts');
    const profile: PersonView = useAppSelector(
        (state) => state.profileReducer.profile
    );
    const isManagementActivity = !!profile?.managedPermissions?.find(item => item.type === 'PERSON_ACTIVITY');
    const isManagementGifts = !!profile?.managedPermissions?.find(item => item.type === 'PERSON_GIFT');
    const dispatch = useDispatch();
    const handleButtonClick = useCallback((key: TableType) => {
        dispatch(setSearchName(''));
        dispatch(setDateRange({
            createdAtFrom: null,
            createdAtTo: null,
        }));
        setActiveTable(key);
    },[setSearchName, setDateRange])
    return (
        <Container sx={{mt: 2, padding: 0}}>
            <HeaderButtons onButtonClick={handleButtonClick} activeButton={activeTable}/>
            {activeTable === 'gifts' && <TableGifts />}
            {activeTable === 'active' && <TableActivity />}
            {activeTable === 'managementGifts' && isManagementGifts && <UserGiftsTable />}
            {activeTable === 'managementActivity' && isManagementActivity && <UserActivityTable/>}
        </Container>
    );
};

export default TableSection;
