import React, {FC} from 'react';

import {Box, Paper} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useDispatch} from 'react-redux/es/hooks/useDispatch';

import {toggleModal} from '../../../../reducer/bonusesModalSlice';
import EditPopover from '../../../common/Modals/EditPopover/EditPopover';

import {rowCardTable} from './styles/rowCardTable';
import {useAppSelector} from "../../../../hooks/useAppSelector";

interface IRowCardTable {
    title: string;
    cost: number;
    id: string;
    type: 'gift' | 'activity';
    onConfirmModal: (id: string) => void;
    typeEdite: 'editeGift' | 'editeActivity';
}

export const RowCardTable: FC<IRowCardTable> = (
    {
        title,
        cost,
        id,
        type,
        onConfirmModal,
        typeEdite
    }) => {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(toggleModal({type: type, isOpenView: true, id}));
    };
    const handleClickEdite = () => {
        dispatch(toggleModal({type: typeEdite, isOpenView: true, id}));
    };
    const handleIconClick = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    const popoverButtons = [
        {name: 'Редактировать', action: handleClickEdite},
        {
            name: 'Удалить',
            action: () => onConfirmModal(id),
        },
    ];
    const user = useAppSelector((state) => state.profileReducer.profile);
    const roles = ['EDITOR', 'ADMIN']
    const userRole = user.role.role;
    const hasRole = roles.some(role => userRole.includes(role));

    return (
        <Paper sx={rowCardTable.row} onClick={handleClick}>
            <Box sx={rowCardTable.row__content} width={'80%'}>
                <Box>
                    <Typography sx={rowCardTable.row__title}>{title}</Typography>
                </Box>
            </Box>
            <Box sx={rowCardTable.row__content} width={'15%'}>
                <Typography sx={rowCardTable.row__cost}>{cost}</Typography>
            </Box>
            {hasRole && <Box sx={rowCardTable.row__icon} width={'6%'} onClick={handleIconClick}>
                <EditPopover buttonsData={popoverButtons}/>
            </Box>}
        </Paper>
    )
}
