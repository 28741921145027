import React, {FC} from 'react';
import {Box, Grid, Paper} from '@mui/material';
import Typography from '@mui/material/Typography';
import { ChevronRight } from '@mui/icons-material';
import {heroButton} from './styles/heroButton';

export const HeroButton: FC<{ title: string; desc: string; icon: JSX.Element; width: string; onClick?: () => void }> = ({
  title,
  desc,
  icon,
  width,
  onClick
}) => (
  <Grid item sx={{ width }}>
    <Paper sx={heroButton.button} onClick={onClick}>
      <Box sx={heroButton.button__content}>
        <Box sx={heroButton.button__icon__wrapper}>{icon}</Box>
        <Box>
          <Typography sx={heroButton.button__title}>{title}</Typography>
          <Typography sx={heroButton.button__desc}>{desc}</Typography>
        </Box>
      </Box>
      <ChevronRight />
    </Paper>
  </Grid>
)