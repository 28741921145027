import React, { Dispatch, SetStateAction } from 'react'
import { Box, ThemeProvider, createTheme } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { IconContainerProps } from '@mui/material/Rating'
import StarIcon from '@mui/icons-material/Star'
import { getColorByValue } from '../utils/getColorByValue'
import useMediaQuery from '@mui/material/useMediaQuery'
import star from '../../../assets/icons/star.svg'
import Slider from '@mui/material/Slider'
import {styleBox, StyledRating, styleRating} from "./styles/ratingCustomStyles";


interface IProps {
  estimation: number
  setEstimation: Dispatch<SetStateAction<number>>
}

function RatingCustom({ setEstimation, estimation }: IProps) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.only('mobile'))

  const [hover, setHover] = React.useState(-1)

  const customIcons: {
    [index: string]: {
      icon: React.ReactElement
    }
  } = {}

  for (let i = 1; i <= 10; i++) {
    customIcons[i] = {
      icon: <StarIcon sx={{ color: getColorByValue(hover !== -1 ? hover : estimation) }} />,
    }
  }

  const AmountSlider = createTheme({
    components: {
      MuiSlider: {
        styleOverrides: {
          root: {
            color: theme.palette.primary.main,
            height: 8,
          },
          thumb: {
            height: 24,
            width: 24,
            backgroundColor: '#4a515d',
            background: `url(${star}) no-repeat center center`,
            backgroundSize: 'cover',
          },
          active: {},
          track: {
            border: 'none',
            backgroundColor: getColorByValue(estimation),
          },
          rail: {
            opacity: 0.5,
            backgroundColor: theme.palette.secondary.contrastText,
          },
        },
      },
    },
  })

  function IconContainer(props: IconContainerProps) {
    const { value, ...others } = props
    return <span {...others}>{customIcons[value].icon}</span>
  }

  const handleChange = (e: any, value: number | number[] | null) => {
    setEstimation(value as number)
  }

  const handleHoverChange = (e: any, value: number) => {
    setHover(value)
  }

  return (
    <Box sx={styleBox}>
      {isMobile ? (
        <>
          <ThemeProvider theme={AmountSlider}>
            <Slider
              value={estimation}
              onChange={handleChange}
              min={0}
              max={10}
              sx={{ width: '300px', marginInline: '10px' }}
            />
          </ThemeProvider>
        </>
      ) : (
        <StyledRating
          max={10}
          name='highlight-selected-only'
          value={estimation}
          onChange={(_event, value) => handleChange(_event, value)}
          onChangeActive={(_event, value) => handleHoverChange(_event, value)}
          sx={styleRating}
          IconContainerComponent={IconContainer}
        />
      )}

      {estimation !== null && (
        <Box color={getColorByValue(hover !== -1 ? hover : estimation)}>
          {`${hover !== -1 ? hover : estimation} из 10`}
        </Box>
      )}
    </Box>
  )
}

export default RatingCustom
