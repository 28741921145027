import React, { FC } from 'react';

import Box from '@mui/material/Box';
import moment from 'moment/moment';

import { StoryView } from '../../../../../models/story.model';
import { monthTranslate } from '../../../../../translators/month.translators';
import FixedReactions from '../../../../common/Reactions/FixedReactions/FixedReactions';
import { GroupInfo } from '../../Stories';
import ModalStoriesButton from '../ModalStoriesButton/ModalStoriesButton';
import StoryContent from './StoryContent/StoryContent';
import { styles } from './styles';

interface StoryContentProps {
  story: StoryView;
  closeModal?: () => void;
  groupInfo?: GroupInfo;
  contentAction?: any;
  handleError: () => void;
}

export const StoryWrapper: FC<StoryContentProps> = ({
  story,
  groupInfo,
  closeModal,
  contentAction,
  handleError,
}) => {
  const adminStory = groupInfo?.type === 'ADMIN';
  const urlImg = `url("/loyalty-portal/api/attachments/${
    encodeURIComponent(story.attachments[0]?.name) + story?.attachments[0]?.extension
  }")`;

  const content: string =
    groupInfo?.type === 'BIRTHDAY'
      ? `${moment(story.author?.dob).format('DD')} ${monthTranslate(
          moment(story.author?.dob).format('MMMM')
        )} ${moment().year()}`
      : groupInfo?.type === 'WELCOME'
        ? story.author?.job?.title?.name
        : story.content;

  return (
    <Box sx={styles.storyWrapper}>
      <ModalStoriesButton
        groupInfo={groupInfo}
        closeModal={closeModal}
        contentAction={contentAction}
      />
      <Box
        sx={[
          styles.styleWrapper(adminStory),
          adminStory && styles.bgImageStyle({ adminStory, urlImg }),
        ]}
      >
        <StoryContent
          author={groupInfo?.type === 'ADMIN' ? undefined : story.author}
          header={story.header}
          content={content}
          groupType={groupInfo?.type}
        />
        {groupInfo?.type && groupInfo.type !== 'ADMIN' && (
          <FixedReactions
            reactionId={story.reaction.id}
            type={groupInfo.type}
            handleError={handleError}
          />
        )}
      </Box>
    </Box>
  );
};

export default StoryWrapper;
