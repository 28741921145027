import React, {FC, useContext} from "react";

import BottomNavigations from "../mobile/BottomNavigations/BottomNavigations";
import MenuList from "../../common/MenuList/MenuList";
import ServicesList from '../LeftSidebar/ServicesList/ServicesList';
import {AppContext} from "../../shared/AppContextProvider";
import {useMenuItems} from "./hooks/useMenuItems";
import {Role} from "../../../constants/role";
import {navigationItems} from "./navigationItems";
import SvgIcon from "@mui/material/SvgIcon";
import {iconProps} from "../LeftSidebar/ServicesList/styles/styles";
import {servicesItems} from "../LeftSidebar/ServicesList/servicesItems";
import {ReactComponent as ServicesIcon} from "../../../assets/icons/services.svg";
import {ReactComponent as MoreIcon} from "../../../assets/icons/more.svg";
import {MenuListItem, ServiceListItem} from "../../../types/MenuListItems.types";

interface NavigationProp {
    isMobile?: boolean;
}

interface MenuElement {
    icon: React.JSX.Element;
    text: React.JSX.Element;
    items: MenuListItem[] | ServiceListItem[];
}

export const Navigation: FC<NavigationProp> = ({isMobile = false}) => {
    const {user} = useContext(AppContext);

    const filteredRolesItems = navigationItems
        .filter((item) => !item.requiredRoles || item.requiredRoles.includes(user.role as Role));

    const {menuItems, hiddenItems, mobileMenuItems} = useMenuItems({filteredRolesItems})

    const MenuElements: MenuElement[] = [
        {
            items: hiddenItems,
            icon: <SvgIcon component={MoreIcon} {...iconProps} />,
            text: <>Еще</>
        },
        {
            items: servicesItems,
            icon: <SvgIcon component={ServicesIcon} {...iconProps} />,
            text: <>Сервисы</>
        }
    ]

    return (
        <>
            {isMobile ? (
                <BottomNavigations items={mobileMenuItems}/>
            ) : (
                <>
                    <MenuList
                        testId="mainMenu"
                        items={menuItems}
                    />
                    {MenuElements.map((el, i) => {
                        return (
                            <ServicesList items={el.items} key={i}>
                                <ServicesList.Icon>
                                    {el.icon}
                                </ServicesList.Icon>
                                <ServicesList.Text>
                                    {el.text}
                                </ServicesList.Text>
                            </ServicesList>)
                    })}
                </>
            )}
        </>
    );
};

export default Navigation;
