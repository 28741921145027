import React, { FC } from "react"
import { MainInfoProps } from "../Media/Media";
import UserAvatar from "../../common/User/UserAvatar/UserAvatar";
import { ReactComponent as TelegramIcon } from "../../../assets/icons/logo_telegram.svg";
import { ReactComponent as WhatsAppIcon } from "../../../assets/icons/logo_whatsapp.svg";
import PapersElement, { PapersDataObj } from "../Contacts/Contacts";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import moment from "moment/moment";
import Stack from "@mui/material/Stack";

export const MainInfo: FC<Omit<MainInfoProps, 'isCurrentUser'>> = ({ userInfo }) => {
  const phoneNumber = userInfo?.contact?.phone?.replaceAll(/[\D^+]/g,'') ?? '';
  const telegramLogin = userInfo?.contact?.telegram?.replaceAll(/^@/g,'') ?? '';

  const personalData: { data: PapersDataObj[], title: string } = {
    title: "Персональные данные",
    data: [
      {
        title: 'Имя',
        name: 'name',
        info: userInfo?.name,
      },
      {
        title: 'Фамилия',
        name: 'surname',
        info: userInfo?.surname,
      },
      {
        title: 'Отчество',
        name: 'patronymic',
        info: userInfo?.patronymic,
      },
      {
        title: 'Дата рождения',
        name: 'dob',
        info: moment(userInfo?.dob).format("DD.MM.YYYY"),
      },
      {
        title: 'Город',
        name: 'city',
        info: userInfo?.city?.name,
      }
    ]
  };
  const contactsData: { data: PapersDataObj[], title: string } = {
    title: "Контактные данные",
    data: [
      {
        title: 'Почта',
        name: 'email',
        info: userInfo?.contact?.email,
        link: `mailto:${userInfo?.contact?.email}`,
        icon: <EmailIcon color="secondary" />,
      },
      {
        title: 'Телефон',
        name: 'phone',
        info: userInfo?.contact?.phone,
        link: `tel:${phoneNumber}`,
        icon: <PhoneIcon color="secondary" />,
      },
      {
        title: 'WhatsApp',
        name: 'whatsApp',
        info: userInfo?.contact?.phone,
        link: (phoneNumber) ? `https://wa.me/${phoneNumber}` : '',
        icon: <WhatsAppIcon color="secondary" />,
      },
      {
        title: 'Telegram',
        name: 'telegram',
        info: userInfo?.contact?.telegram,
        link: (telegramLogin) ? `https://t.me/${telegramLogin}` : '',
        icon: <TelegramIcon color="secondary" />,
      },
    ]
  };

  const Papers = () => {
    return <>{
      [personalData, contactsData].map((el, i) => (
          <PapersElement key={`MainInfo_papers_${i}`} {...el} />
      ))
    }</>
  }

  return (
      <Stack spacing={4} alignItems="center">
        <UserAvatar styles={{ width: 200, height: 200 }} userInfo={userInfo} />
        <Papers />
      </Stack>
  )
}

export default MainInfo;
