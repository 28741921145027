import { SystemStyleObject, Theme } from "@mui/system";

const keys = ["itemButtonMore", "buttonMore"] as const;

type StylesType = Record<(typeof keys)[number], SystemStyleObject<Theme>>;

export const styles: StylesType = {
  itemButtonMore: { padding: 0, display: "flex", alignItems: "stretch" },
  buttonMore: { padding: 0, width: "100%" },
};
