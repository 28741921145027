import { useEffect } from "react";

import { useAppSelector } from "../../../../hooks/useAppSelector";
import {
  useFetchAllEventsQuery,
  useFetchEventTypesQuery,
} from "../../../../services/calendar.controller";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { allEvents } from "../../../../reducer/calendar";

import { transformResDataToCalendar } from "../helpers";

export const useAllEvents = () => {
  const dispatch = useAppDispatch();
  const allEventsQuery = useAppSelector(
    (state) => state.calendarReducer.allEventsQuery
  );
  const { data, isError } = useFetchAllEventsQuery(allEventsQuery ?? undefined);
  const isEventTypesError = useFetchEventTypesQuery().isError;

  useEffect(() => {
    const transformData = transformResDataToCalendar(data?.items ?? []);
    dispatch(allEvents(transformData));
  }, [data, dispatch]);

  return { eventsError: isError || isEventTypesError ? true : false };
};
