import React, { useEffect, useState } from 'react';

import { useFetchSkillQuery } from '../../../../services/skill.controller';
import { ISkill, ISkillFetchQuery } from '../../../../types/Skill.type';
import CustomSelect from '../../../common/Inputs/CustomSelect/CustomSelect';
import InputWrapper from '../../../common/Inputs/InputWrapper/InputWrapper';

interface IProps {
  currentGrade: ISkill | null;
  setCurrentGrade: React.Dispatch<React.SetStateAction<ISkill | null>>;
}

const increment = 10;

const GradeSelect = ({ currentGrade, setCurrentGrade }: IProps) => {
  const [skillMap, setSkillMap] = useState<Map<string, ISkill> | null>(null);
  const [queryParams, setQueryParams] = useState<ISkillFetchQuery | undefined>({ size: increment });
  const { data } = useFetchSkillQuery(queryParams);

  useEffect(() => {
    if (!data) return;
    setCurrentGrade(data.items[0]);
    setSkillMap(new Map(data.items.map((item) => [item.id, item])));
  }, [data, setCurrentGrade]);

  const onScroll: React.UIEventHandler<HTMLDivElement> = ({ currentTarget }) => {
    const { scrollTop, scrollHeight, clientHeight } = currentTarget;
    const isEnd = !Boolean(Math.round(scrollHeight - clientHeight - scrollTop));
    if (isEnd && queryParams?.size && data && queryParams.size < data.totalItems)
      setQueryParams((prev) => ({ ...prev, size: (prev?.size ?? 0) + increment }));
  };

  if (!skillMap) return null;

  return (
    <InputWrapper
      titleColor="secondary"
      flexDirection="column"
      title="Навык"
      element={
        <CustomSelect
          items={[...skillMap.values()]}
          onChange={({ target }) => {
            const skill = skillMap.get(target.value);
            if (skill) setCurrentGrade(skill);
          }}
          value={currentGrade?.id ?? [...skillMap.values()][0]?.id}
          onScroll={onScroll}
        />
      }
    />
  );
};

export default GradeSelect;
