import React, { FC, SetStateAction, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";

import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./FeedTextEditor.css"; //For customazing library components we have to use css styles

import editorIcons from "../../../assets/icons/textEditor";

interface TextEditorProps {
  readOnly: boolean;
  editorState: EditorState;
  setEditorState: SetStateAction<any>;
}

export const FeedTextEditor: FC<TextEditorProps> = ({
  readOnly,
  editorState,
  setEditorState,
}) => {

  const editorStateChange = (state: EditorState) => {
    setEditorState(state);
  };

  const hasListItems = (editorState: EditorState) => {
    return editorState
      .getCurrentContent()
      .getBlockMap()
      .some(
        (block) =>
          block?.getType() === "ordered-list-item" ||
          block?.getType() === "unordered-list-item"
      );
  };

  const toolbarSettings = {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "list",
      "textAlign",
      "colorPicker",
      "link",
      "image",
      "history",
    ],
    inline: {
      options: ["bold", "italic", "underline", "strikethrough"],
      bold: { icon: editorIcons.bold },
      italic: { icon: editorIcons.italic },
      underline: { icon: editorIcons.underline },
      strikethrough: { icon: editorIcons.strikethrough },
    },
    blockType: {
      inDropdown: true,
      options: [
        "Normal",
        "H1",
        "H2",
        "H3",
        "H4",
        "H5",
        "H6",
        "Blockquote",
        "Code",
      ],
    },
    fontSize: {
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    },
    list: {
      options: ["unordered", "ordered", "indent", "outdent"],
      unordered: { icon: editorIcons.unordered },
      ordered: { icon: editorIcons.ordered },
      indent: { icon: editorIcons.indent },
      outdent: { icon: editorIcons.outdent },
    },
    textAlign: {
      options: ["left", "center", "right", "justify"],
      left: { icon: editorIcons.left },
      center: { icon: editorIcons.center },
      right: { icon: editorIcons.right },
      justify: { icon: editorIcons.justify },
    },
    colorPicker: {
      icon: editorIcons.palette,
      colors: [
        "rgb(97,189,109)",
        "rgb(26,188,156)",
        "rgb(84,172,210)",
        "rgb(44,130,201)",
        "rgb(147,101,184)",
        "rgb(71,85,119)",
        "rgb(204,204,204)",
        "rgb(65,168,95)",
        "rgb(0,168,133)",
        "rgb(61,142,185)",
        "rgb(41,105,176)",
        "rgb(85,57,130)",
        "rgb(40,50,78)",
        "rgb(0,0,0)",
        "rgb(247,218,100)",
        "rgb(251,160,38)",
        "rgb(235,107,86)",
        "rgb(226,80,65)",
        "rgb(163,143,132)",
        "rgb(239,239,239)",
        "rgb(255,255,255)",
        "rgb(250,197,28)",
        "rgb(243,121,52)",
        "rgb(209,72,65)",
        "rgb(184,49,47)",
        "rgb(124,112,107)",
        "rgb(209,213,216)",
        "inherit", //return w/o background
      ],
    },
    link: {
      showOpenOptionOnHover: false,
      defaultTargetOption: "_self",
      options: ["link", "unlink"],
      link: { icon: editorIcons.link },
      unlink: { icon: editorIcons.unlink },
    },
    history: {
      options: ["undo", "redo"],
      undo: { icon: editorIcons.history },
      redo: { icon: editorIcons.update },
    },
  };

  const toolbarStyle = {
    backgroundColor: "rgba(185, 215, 255, 0.08)",
    borderColor: "rgba(241, 247, 255, 0.12)",
  };

  const editorStyle = !readOnly
    ? {
        paddingLeft: "10px",
        paddingRight: "10px",
        minHeight: "250px",
        overflow: "hidden",
      }
    : undefined;

  return (
    <div className="App">
      <Editor 
        editorStyle={editorStyle}
        toolbarStyle={toolbarStyle}
        toolbar={toolbarSettings}
        toolbarHidden={readOnly}
        readOnly={readOnly}
        placeholder={
          hasListItems(editorState) || readOnly
            ? undefined
            : "Начните вводить текст"
        }
        stripPastedStyles
        editorState={editorState} 
        onEditorStateChange={editorStateChange}
      />
    </div>
  )
};

export default FeedTextEditor;
