import React, { FC, ReactElement, useContext, useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import Stack from '@mui/material/Stack';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import Media from './Media/Media';
import { ERole, PersonView } from '../../models/profile.models';
import { profilesInitial } from '../../actions/profiles';
import { personController } from '../../services/person.controller';
import { AppContext } from '../shared/AppContextProvider';
import { LoadProgress } from '../common/LoadProgress/LoadProgress';
import CustomButton from '../common/Buttons/CustomButton/CustomButton';
import { companyInitialState } from '../Company/CompanyContext/CompanyContextProvider';
import { BackButton } from '../common/Buttons/BackButton/BackButton';
import CustomToggleButtons from '../common/Buttons/CustomToggleButtons/CustomToggleButtons';
import MainInfo from './MainInfo/MainInfo'
import Career from './Career/Career';
import About from './About/About';
import Button from '@mui/material/Button';

const SectionTitles = {
  main: 'Личные данные',
  career: 'Карьера',
  about: 'Обо мне'
} as const;
type TSectionTitles = typeof SectionTitles[keyof typeof SectionTitles];
type TSectionsObject = {
  [key in TSectionTitles]: ReactElement
}

export const Profile: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const path = useLocation().pathname;
  const pathParts = path.split("/");
  const userId = pathParts[pathParts.indexOf("profile") + 1];
  const [sectionStatus, setSectionStatus] = useState<TSectionTitles>('Личные данные')
  const userData: PersonView = useAppSelector(
      (state) => state.profileReducer.editProfile
  );

  const currentUserInfo = JSON.parse(
    localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
  );
  const isCurrentUser = userId === currentUserInfo.id;
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const { logout, isMobile } = useContext(AppContext);

  useEffect(() => {
    localStorage.setItem("userId", userId);
    personController.person(userId).then((res) => {
      dispatch(profilesInitial.editProfile(res.data));
      setShowLoader(false);
    });
    /* eslint-disable */
  }, [userId]);

  const aboutProps = {
    tags: userData.tags,
    communities: userData.communities,
    description: userData.description
  }

  const sections: TSectionsObject = {
    [SectionTitles.main]: <MainInfo userInfo={userData} />,
    [SectionTitles.career]: <Career userInfo={userData} />,
    [SectionTitles.about]: <About {...aboutProps} />
  }

  const handleExitButton = () => {
    logout();
  };

  const handleSectionStatus = (value: TSectionTitles) => {
    setSectionStatus(value)
  }

  const handleCancel = () => {
    if (companyInitialState.isForUserEditing) {
      companyInitialState.isPreserved = true;
      companyInitialState.shouldGetData = false;
    }
    navigate(-1);
  };

  useEffect(() => () => {
    companyInitialState.isForUserEditing = false;
  }, []);


  return (
    <>
      {showLoader && <LoadProgress />}
      {!showLoader && (
        <>
          <BackButton
            handleNavigateBack={handleCancel}
            isTopRight={true}
          />
          <Media userInfo={userData} />
          <Stack spacing={4} mx={isMobile ? 2 : 0} mt={4}>
            <Stack direction="row" justifyContent="space-between">
              <CustomToggleButtons
                items={Object.keys(sections)}
                onChange={handleSectionStatus}
                currentValue={sectionStatus}
              />
              {(currentUserInfo?.role !== ERole.USER || currentUserInfo?.id === userData?.id) &&
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#2686FF",
                    color: "white",
                    borderRadius: "20px",
                    textTransform: "none",
                    fontSize: '14px',
                    "&:hover": {
                      backgroundColor: "#1c6cd5",
                    },
                  }}
                  onClick={() => navigate('/profile/edit')}
                >
                  Редактировать профиль
                </Button>
              }
            </Stack>
            {sections[sectionStatus]}
            {isCurrentUser && (
              <CustomButton
                color="secondary"
                fullWidth
                startIcon={<ExitToAppIcon/>}
                onClick={handleExitButton}
                text="Выйти из профиля"
              />
            )}
          </Stack>
        </>
      )}
    </>
  );
};

export default Profile;
