import {styled} from "@mui/material/styles";
import Rating from "@mui/material/Rating";

export const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
        color: theme.palette.secondary.contrastText,
    },
    width: '100%',
}))

export const styleBox = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
}

export const styleRating = {
    display: 'flex',
    gap: '12px',
}