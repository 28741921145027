import React, {FC, memo} from 'react';
import {Box, Fab} from '@mui/material';
import {headerButtons} from './styles/headerButtons';
import type {PersonView} from '../../../../models/profile.models';
import {useAppSelector} from '../../../../hooks/useAppSelector';

type TButtonConfig = {
    key: 'gifts' | 'active' | 'managementActivity' | 'managementGifts';
    label: string;
}
const buttonLabels: TButtonConfig[] = [
    {key: 'gifts', label: 'Подарки за бонусы'},
    {key: 'active', label: 'Накопить бонусы легко'},
    {key: 'managementActivity', label: 'Управление активностями'},
    {key: 'managementGifts', label: 'Управление подарками'},
];

interface IHeaderButtonsProps {
    onButtonClick: (key: TButtonConfig['key']) => void;
    activeButton: TButtonConfig['key'];
}

export const HeaderButtons: FC<IHeaderButtonsProps> = ({onButtonClick, activeButton}) => {
    const profile: PersonView = useAppSelector(
        (state) => state.profileReducer.profile
    );
    const isManagementActivity = !!profile?.managedPermissions?.find(item => item.type === 'PERSON_ACTIVITY');
    const isManagementGifts = !!profile?.managedPermissions?.find(item => item.type === 'PERSON_GIFT');
    const visibleButtons = buttonLabels.filter(({ key }) => {
        if (key === 'managementActivity') return isManagementActivity;
        if (key === 'managementGifts') return isManagementGifts;
        return true;
    });

    return (
        <Box sx={headerButtons.container}>
            {visibleButtons.map(({label, key}) => (
                <Fab
                    key={key}
                    variant="extended"
                    size="medium"
                    sx={{
                        ...headerButtons.button,
                        ...(activeButton === key && {
                            backgroundColor: 'rgba(203, 225, 255, 0.17)'
                        })
                    }}
                    onClick={() => onButtonClick(key)}
                >
                    {label}
                </Fab>
            ))}
        </Box>
    );
};

export default memo(HeaderButtons);
