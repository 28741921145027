import {TStylesType} from '../../../../../types/Style.types';

export const tableGifts: TStylesType = {
    scrollBox: {
        marginTop: 2,
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
            display: "none",
        },
    },
}