import React, { FC, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { FeedRecordView } from "../../../models/feed.model";
import { secondaryButton } from "../../../constants/buttonStyle";
import { determineDate } from "../../../operation/determineDate";
import { AttachmentView } from "../../../models/attachment.model";
import { feedController } from "../../../services/feed.controller";
import { roles } from "../../../constants/role";
import { dataInitial } from "../../../actions/data";

import {
  Box,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import UserAvatar from "../../common/User/UserAvatar/UserAvatar";
import PreviewFiles from "../../common/Attachments/PreviewFiles/PreviewFiles";
import PreviewImages from "../../common/Attachments/PreviewImages/PreviewImages";
import Reactions from "../../common/Reactions/Reactions";
import { buttonInfo } from "../../common/Modals/EditPopover/EditPopover";
import EditPopover from "../../common/Modals/EditPopover/EditPopover";
import PreviewAudios from '../../common/Attachments/PreviewAudios/PreviewAudios';
import PreviewVideos from '../../common/Attachments/PreviewVideos/PreviewVideos';
import { feedInitialState } from "../Feed";
import FeedModal from "../FeedModal/FeedModal";
import FeedTextEditor from "../FeedTextEditor/FeedTextEditor";
import { convertFromRaw, EditorState } from "draft-js";
import { convertContent } from "../../KnowledgeBase/ArticlePage/ArticlePage";

interface FeedMessageProp {
  feedItem: FeedRecordView;
  withButton?: boolean;
  withEllipsis?: boolean;
  handleError: () => void;
  updateFeedItem?: () => void;
}

const audioExtensions = [".mp3"];
const videoExtensions = [".mov", ".mp4"];

const isImageItem = (item: AttachmentView) => item.attachmentType === "IMAGE";

const isAudioItem = (item: AttachmentView) => (
  item.attachmentType === "MEDIA"
  && audioExtensions.includes(item.extension.toLowerCase())
);

const isVideoItem = (item: AttachmentView) => (
  item.attachmentType === "MEDIA"
  && videoExtensions.includes(item.extension.toLowerCase())
);

export const FeedMessage: FC<FeedMessageProp> = ({
  feedItem,
  withButton,
  withEllipsis,
  handleError,
  updateFeedItem,
}) => {  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const [record, setRecord] = useState<FeedRecordView>(feedItem)

  const images =
    record?.attachments?.filter(
      (item: AttachmentView) => item.attachmentType === "IMAGE"
    ) ?? [];
  const audios =
    record?.attachments?.filter(isAudioItem) ?? [];
  const videos =
    record?.attachments?.filter(isVideoItem) ?? [];
  const files =
    record?.attachments?.filter(
      (item: AttachmentView) => !isImageItem(item) && !isAudioItem(item) && !isVideoItem(item)
    ) ?? [];

  const currentUser = JSON.parse(
    localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
  );

  const [openFeedModal, setOpenFeedModal] = useState<boolean>(false);
  const handleFeedModal = () => {
    setOpenFeedModal((prev) => !prev);
  };

  const deleteFeed = () => {
    feedController
      .deleteFeedItem(record.id)
      .then(() => {
        feedController
          .feed(0, feedInitialState.tabIndex === 'important', (feedInitialState.currentPage + 1) * feedInitialState.countsRecords)
          .then((res) => {   
            dispatch(dataInitial.feed(res.data.items));
            feedInitialState.totalRecords = res.data.totalItems;
            feedInitialState.isProvided = true;
            navigate("..", { relative: "path" });
          })
          .catch(() => {
            handleError()
          });
      })
      .catch(() => {
        handleError()
      });
  };

  useEffect(() => {
    setRecord(feedItem)
  }, [feedItem]);

  const editButton: buttonInfo = {
    name: "Редактировать",
    action: () => handleFeedModal(),
  };

  const deleteButton: buttonInfo = {
    name: "Удалить",
    action: () => deleteFeed(),
  };

  const cardActionsStyle = {
    padding: {
      mobile: "8px 16px 16px",
      laptop: "8px 24px 24px",
    },
    display: "flex",
    flexWrap: "wrap",
  };

  const ellipsisStyle = {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "175px",
  };

  const userNameStyle = {
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  };  

  return (
    <>
      {record && 
        <Card id={record.id} sx={{ borderRadius: "12px", width: "100%" }}>
          <CardContent
            sx={{ padding: isMobile ? "16px 16px 8px" : "24px 24px 8px" }}
          >
            <Stack spacing={2}>
              <Box display="flex" justifyContent="space-between">
                <Box display="flex" gap="12px">
                  <UserAvatar userInfo={record?.author} />
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <Typography
                      variant="subtitle2"
                      sx={withEllipsis ? ellipsisStyle : userNameStyle}
                    >
                      {record?.author?.name +
                        " " +
                        record?.author?.surname}
                    </Typography>
                    <Typography
                      variant="body1"
                      color={theme?.palette?.text?.secondary}
                    >
                      {determineDate(record.date)}
                    </Typography>
                  </Box>
                </Box>
                {(currentUser.id === record?.author.id && (
                  <EditPopover buttonsData={[editButton, deleteButton]} />
                )) || (currentUser.role === roles.admin && (
                  <EditPopover buttonsData={[deleteButton]} />
                ))}
              </Box>

              <FeedTextEditor
                readOnly={true}
                setEditorState
                editorState={  
                  EditorState.createWithContent(
                    convertFromRaw(JSON.parse(convertContent(record.content)))
                  )
                }
              />

              {images?.length > 0 && <PreviewImages images={images} />}
              {audios?.length > 0 && <PreviewAudios audios={audios} />}
              {videos?.length > 0 && <PreviewVideos videos={videos} />}
              {files?.length > 0 && (
                <PreviewFiles files={files} type="download" />
              )}
            </Stack>
          </CardContent>
          <CardActions sx={cardActionsStyle}>
            {record.reaction.id && <Reactions
              reactionId={record.reaction.id}
              handleError={handleError}
            />}
            {withButton && (
              <Button
                fullWidth
                sx={{
                  borderRadius: "60px",
                  padding: "12px 0",
                  mt: "16px",
                  ...secondaryButton,
                }}
                component={NavLink}
                to={`/feed/${record.id}`}
                onClick={() => localStorage.setItem("feedId", record.id)}
              >
                {record?.commentCount > 0
                  ? `Комментарии (${record?.commentCount})`
                  : "Оставить комментарий"}
              </Button>
            )}
          </CardActions>
        </Card>
      }

      {openFeedModal && 
        <FeedModal
          open={openFeedModal}
          type={"edit"}
          feedId={record.id}
          important={feedInitialState.tabIndex === "important"}
          handleModal={handleFeedModal}
          handleCompleted={updateFeedItem}
        />
      }

    </>
  );
};
export default FeedMessage;
