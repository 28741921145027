import React, {useContext, useEffect, useState} from 'react'
import { Box, Typography } from '@mui/material'
import CreateReviewModal from '../ReviewModal/CreateReviewModal'
import { ReviewContext } from '../RewievContextProvider/ReviewInProfileContextProvider'
import RatingModal from '../GradeModal/RatingModal'
import { useAppSelector } from '../../../hooks/useAppSelector'
import {
  isReviewInitializedSelect,
  reviewListLoadMoreSelect,
  reviewListSelect,
  reviewListSizeSelect,
} from '../../../reducer/selectors/reviewSelectors'
import ReviewCardWrapper from '../ReviewCard/ReviewCardWrapper'
import { LoadProgress } from '../../common/LoadProgress/LoadProgress'
import { CreateReview } from '../ReviewCard/CreateReview'
import { reviewThunks } from '../../../reducer/review'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useLocation } from 'react-router'
import { getReviewId } from '../utils/getReviewId'
import ApprovedModal from '../GradeModal/ApprovedModal'
import { reviewPageSize } from '../../../constants/review'
import EditReviewModal from "../EditReviewModal/EditReviewModal";
import {useSelectedReviewers} from "../../../hooks/useSelectedReviewers";
import moment from "moment/moment";
import {decodeBase64} from "../utils/decodeId";
import {updateReviewDate} from "../utils/updateReviewDate";
import {styleBoxTitleWrapper, styleCardBox} from "./styles/reviewInProfileStyles";

const ReviewInProfile = () => {
  const {
    openReviewModal,
    openRatingModal,
    openEditModal,
    handleReviewModal,
    handleRatingModal,
    handleEditModal,
    userId,
    setReviewIdFromNotification,
    listPage,
    reviewId
  } = useContext(ReviewContext)

  const showLoader = useAppSelector(isReviewInitializedSelect)
  const reviewList = useAppSelector(reviewListSelect)
  const reviewListSize = useAppSelector(reviewListSizeSelect)
  const isAddLoadList = useAppSelector(reviewListLoadMoreSelect)

  const location = useLocation()
  const dispatch = useAppDispatch()

  const { selectedReviewers, votedReviewers, setSelectedReviewers } = useSelectedReviewers(reviewList, reviewId)

  const [selectedReviewDate, setSelectedReviewDate] = useState({
    selectedStartReviewDate:'',
    selectedEndReviewDate:'',
  })

  const currentReview = reviewList.find((el) => el.id === reviewId)

  useEffect(() => {
    currentReview && setSelectedReviewDate(prevState => ({
      ...prevState,
      selectedStartReviewDate: moment(currentReview.startReview).format('YYYY-MM-DD'),
      selectedEndReviewDate: moment(currentReview.endReview).format('YYYY-MM-DD')
    }));
  }, [currentReview])

  useEffect(() => {
    dispatch(reviewThunks.getFetchedReviewList({ userId, page: 0, size: (listPage + 1) * reviewPageSize }))
      .unwrap()
      .catch((e) => console.log(e))
    dispatch(reviewThunks.getFetchedSkills())
  }, [userId, listPage])

  useEffect(() => {
    setReviewIdFromNotification('')
    setReviewIdFromNotification(getReviewId(location.pathname))
  }, [location])

  const handleModal = () => {
    handleReviewModal()
  }

  const handleDateChange = (date: string, type: 'start' | 'end') => {
    updateReviewDate(setSelectedReviewDate, date, type);
  };

  const handleSend = () => {
    if (selectedReviewers) {
      const selectedReviewersId = selectedReviewers.map((el) => decodeBase64(el.id))
      const dateEnd = moment(selectedReviewDate.selectedEndReviewDate).format('YYYY-MM-DD')
      const dateStart = moment(selectedReviewDate.selectedStartReviewDate).format('YYYY-MM-DD')
      const body = {
        reviewersId: selectedReviewersId,
        endReview: dateEnd,
        startReview: dateStart
      }
      dispatch(reviewThunks.editReview({ reviewId, body }))
      handleEditModal()
    }
  }

  return (
    <>
      <Box>
        <Box sx={styleBoxTitleWrapper}>
          <Typography variant='h4' mb={2}>
            Ревью
          </Typography>
          {reviewList.length !== 0 && <CreateReview />}
        </Box>
        {showLoader && <LoadProgress />}
        {(!showLoader || isAddLoadList) && (
          <Box mt={2} sx={styleCardBox}>
            <ReviewCardWrapper reviews={reviewList} totalItems={reviewListSize} isAddLoadList={isAddLoadList} />
            {isAddLoadList && <LoadProgress />}
          </Box>
        )}
      </Box>
      <CreateReviewModal openModal={openReviewModal} handleModal={handleModal} />
      <RatingModal handleModal={handleRatingModal} openModal={openRatingModal} />
      <ApprovedModal />
      <EditReviewModal openEditModal={openEditModal}
                       handleEditModal={handleEditModal}
                       handleSend={handleSend}
                       selectedReviewDate={selectedReviewDate}
                       handleDateChange={handleDateChange}
                       selectedReviewers={selectedReviewers}
                       setSelectedReviewers={setSelectedReviewers}
                       votedReviewers={votedReviewers}
      />
    </>
  )
}

export default ReviewInProfile



