import {TStylesType} from '../../../../types/Style.types';

export const heroSection: TStylesType = {
    title: {
        fontSize: 32,
        fontWeight: 700,
        lineHeight: '40px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
        color: 'rgba(230, 237, 245, 1)'
    },
    balances: {
        display: 'flex',
        alignItems: 'center',
    },
    balance: {
        fontSize: 24,
        fontWeight: 700,
        lineHeight: '28px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
        color: 'rgba(229, 235, 242, 0.65)'
    },
    counters: {
        fontSize: 48,
        fontWeight: 700,
        lineHeight: '56px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none'
    },

    button__icon: {
        width: 20,
        height: 20,
        color: 'rgba(230, 237, 245, 1)',
    },
    text: {
        fontSize: '14px'
    },
    image: {
        marginLeft:'15% !important'
    }
}
