import React, {useEffect} from 'react';
import {DataGrid, GridRenderCellParams} from "@mui/x-data-grid";
import {reviewController} from "../../../services/review.controller";
import {columnsDefs} from "./colDefs";
import {Box} from "@mui/material";
import CustomToggleButtons from "../../common/Buttons/CustomToggleButtons/CustomToggleButtons";
import {customToggleButtonsStyle, styleHeader, styleTable} from "./styles/styles";
import NoticeModal from "../NoticeModal/NoticeModal";
import EditReviewModal from "../EditReviewModal/EditReviewModal";
import Search from "../../common/Search/Search";
import {sanitizeAndTrimStart} from "../../../operation/sanitizeAndTrimStart";
import moment from "moment";
import {useSelectedReviewers} from "../../../hooks/useSelectedReviewers";
import {decodeBase64} from "../utils/decodeId";
import {updateReviewDate} from "../utils/updateReviewDate";
import {ReviewEditStatus, ReviewNotifications} from "../../../constants/review";
import {useReviewPage} from "../RewievContextProvider/ReviewPageContextProvider";
import {textSnackbars} from "../../../constants/snackBarNotifications";
import {useDebounce} from "../../../hooks/useDebounce";
import './reviewPage.css'

const statusItems: string[] = ["Активные", "Архив"];

const displayLabelCount = ({from, to, count}: { from: number; to: number; count: number }) => {
    return `${from}–${to} из ${count !== -1 ? count : `больше, чем ${to}`}`;
};

export const ReviewPage = () => {
    const {
        setSortDirection,
        sortDirection,
        reviewsState,
        paginationState,
        searchState,
        modalState,
        setSnackBar,
        row,
        reviewDateState
    } = useReviewPage()

    const { reviews, setReviews, loading, setLoading, status, setStatus, totalRecords, setTotalRecords} = reviewsState
    const { activePage, archivePage, setArchivePage, setActivePage } = paginationState
    const { searchValue, setSearchValue } = searchState
    const { openEditModal, setOpenEditConfirmModal, setModalEditStatus, openNotificationModal, setOpenNotificationModal, setModalStatus, modalStatus, modalEditStatus} = modalState
    const { rowId, setRowId } = row
    const { selectedReviewDate, setSelectedReviewDate } = reviewDateState

    const { selectedReviewers, setSelectedReviewers, votedReviewers } = useSelectedReviewers(reviews, modalEditStatus?.reviewId)

    const searchDebouncedValue = useDebounce(searchValue)

    useEffect(() => {
        void fetchReviews({selectedStatus: status, search: searchDebouncedValue as string});
    }, [activePage, archivePage, sortDirection, searchDebouncedValue, status]);

    const fetchReviews = async ({selectedStatus, search}: {selectedStatus: string, search: string}) => {
        setLoading(true);
        try {
            let response;
            if (selectedStatus === "Активные") {
                response = await reviewController.getAllReview({page: activePage, sort: `owner.name,${sortDirection}`, search});
            } else if (selectedStatus === "Архив") {
                response = await reviewController.getAllArchiveReview({page: archivePage, sort: `owner.name,${sortDirection}`, search});
            }
            const loadedReviews = response.data.items || [];
            setTotalRecords(response.data.totalItems)
            setReviews(loadedReviews);
        } catch (error) {
            setSnackBar({ message: textSnackbars.default, open: true, type: 'error' })
        } finally {
            setRowId(null)
            setLoading(false);
        }
    };

    const invalidateData = async () => {
        await fetchReviews({selectedStatus: status, search: searchValue});
    };

    const handleChangeStatus = (value: string) => {
        setStatus(value);
    };

    const handleNoticeModal = () => {
        setOpenNotificationModal(!openNotificationModal);
    };

    const handleEditModal = () => {
        setOpenEditConfirmModal(!openEditModal)
        setModalEditStatus(null)
    }

    const handleOpenEditModalWithStatus = (status: ReviewEditStatus, params: GridRenderCellParams) => {
        setModalEditStatus({status, reviewId: params.row.id})
        setSelectedReviewDate({
            selectedStartReviewDate: params.row.startReview,
            selectedEndReviewDate: params.row.endReview
        });
        setOpenEditConfirmModal(true)
    }

    const handleOpenModalWithNotificationStatus = (status: ReviewNotifications, reviewId: string) => {
        setModalStatus({status, reviewId});
        setOpenNotificationModal(true);
    };

    const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const sanitizedValue = sanitizeAndTrimStart(value);
        setSearchValue(sanitizedValue);
    };

    const pageChange = (page: number) => {
        if (status === "Активные") {
            setActivePage(page);
        } else if (status === "Архив") {
            setArchivePage(page);
        }
    };

    const handleDateChange = (date: string, type: 'start' | 'end') => {
        updateReviewDate(setSelectedReviewDate, date, type);
    };

    const handleEditReviewSend = async () => {
        if (selectedReviewers) {
            const selectedReviewersId = selectedReviewers.map((el) => decodeBase64(el.id))
            const dateEnd = moment(selectedReviewDate.selectedEndReviewDate).format('YYYY-MM-DD')
            const dateStart = moment(selectedReviewDate.selectedStartReviewDate).format('YYYY-MM-DD')

            const body = {
                reviewersId: selectedReviewersId,
                startReview: dateStart,
                endReview: dateEnd
            }

            try {
                if (modalEditStatus?.status === ReviewEditStatus.edit) {
                    handleEditModal()
                    setRowId(modalEditStatus.reviewId)
                    await reviewController.editReview(modalEditStatus.reviewId, body)
                } else if (modalEditStatus?.status === ReviewEditStatus.unArchive) {
                    handleEditModal()
                    setRowId(modalEditStatus.reviewId)
                    await reviewController.unArchiveReview(modalEditStatus.reviewId, body)
                }

                await invalidateData()

                if (modalEditStatus?.status === ReviewEditStatus.edit) {
                    setSnackBar({ message: textSnackbars.review.edit, open: true, type: 'success' });
                } else if (modalEditStatus?.status === ReviewEditStatus.unArchive) {
                    setSnackBar({ message: textSnackbars.review.unArchive, open: true, type: 'success' });
                }
            } catch (error) {
                setRowId(null)
                setSnackBar({message: textSnackbars.default, open: true, type: 'error'})
            }
        }
    }

    const handleSortModelChange = (sortModel: { field: string, sort: 'asc' | 'desc' | null | undefined }[]) => {
        const {field, sort} = sortModel[0] || {};
        if (field && sort) {
            setSortDirection(sort);
        }
    };

    const deleteReview = async (params: GridRenderCellParams) => {
        setRowId(params.row.id);
        try {
            const res = await reviewController.deleteReview(params.row.id)
            await invalidateData()
            if (res.data.status === 'success') {
                setSnackBar({message: textSnackbars.review.delete, open: true, type: 'success'})
            }
        } catch (e){
            setRowId(null)
            setSnackBar({message: textSnackbars.default, open: true, type: 'error'})
        }
    }

    const archiveReview = async (params: GridRenderCellParams) => {
        setRowId(params.row.id);
        try {
            const res = await reviewController.archiveReview(params.row.id);
            await invalidateData();
            if (res.status === 200) {
                setSnackBar({ message: textSnackbars.review.archive, open: true, type: 'success' });
            }
        } catch (e) {
            setRowId(null);
            setSnackBar({ message: textSnackbars.default, open: true, type: 'error' });
        }
    };

    return (
        <>
            <Search
                value={searchValue}
                styles={{height: "100%", mb: '10px'}}
                placeholder="Поиск по имени и фамилии"
                handleChange={handleChangeSearch}
            />
            <Box sx={customToggleButtonsStyle}>
                <CustomToggleButtons
                    items={statusItems}
                    onChange={handleChangeStatus}
                    currentValue={status}
                    disabled={loading}
                />
            </Box>
            <Box sx={{width: "100%", ...styleHeader}}>
                <DataGrid
                    rows={reviews}
                    columns={columnsDefs(handleOpenModalWithNotificationStatus, handleOpenEditModalWithStatus, deleteReview, archiveReview)}
                    disableColumnMenu
                    rowsPerPageOptions={[10]}
                    pageSize={10}
                    rowCount={totalRecords}
                    paginationMode="server"
                    page={status === "Активные" ? activePage : archivePage}
                    onPageChange={pageChange}
                    loading={loading}
                    autoHeight
                    sortingOrder={['asc', 'desc']}
                    onSortModelChange={handleSortModelChange}
                    sx={styleTable}
                    getRowClassName={(params) => (params.id === rowId ? "row-action" : "")}
                    componentsProps={{
                        pagination: {
                            labelDisplayedRows: displayLabelCount,
                        },
                    }}
                    localeText={{
                        noResultsOverlayLabel: "Нет данных",
                        noRowsLabel: "Нет данных",
                        columnHeaderSortIconLabel: "Сортировка",
                    }}
                />
            </Box>
            <NoticeModal modalData={modalStatus} handleReviewModal={handleNoticeModal}
                         openModal={openNotificationModal} setSnackBar={setSnackBar}/>
            <EditReviewModal openEditModal={openEditModal}
                             handleEditModal={handleEditModal}
                             handleSend={handleEditReviewSend}
                             selectedReviewDate={selectedReviewDate}
                             handleDateChange={handleDateChange}
                             selectedReviewers={selectedReviewers}
                             setSelectedReviewers={setSelectedReviewers}
                             votedReviewers={votedReviewers}/>
        </>
    );
}
