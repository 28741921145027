import React, {useContext} from 'react';
import EditPopover, {buttonInfo} from "../../common/Modals/EditPopover/EditPopover";
import {ReviewContext} from "../RewievContextProvider/ReviewInProfileContextProvider";

interface IProps {
    actionButtons: buttonInfo[]
}

const CardActions = ({actionButtons}:IProps) => {
    const {editableAccess} = useContext(ReviewContext);

    return (
        <>
            {editableAccess && <EditPopover buttonsData={actionButtons}/>}
        </>
    );
};

export default CardActions;