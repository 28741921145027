import React from "react";
import moment from "moment";

export const updateReviewDate = (
    setReviewDate: React.Dispatch<React.SetStateAction<any>>,
    date: string,
    type: 'start' | 'end',
) => {
    const momentDate = moment(date);

    if (!momentDate.isValid()) {
        setReviewDate((prevState:any) => ({
            ...prevState,
            [type === 'start' ? 'selectedStartReviewDate' : 'selectedEndReviewDate']: 'Invalid date',
        }));
        return;
    }

    setReviewDate((prevState:any) => ({
        ...prevState,
        [type === 'start' ? 'selectedStartReviewDate' : 'selectedEndReviewDate']: date,
    }));

    if (type === 'end' && !momentDate.isAfter(moment())) {
        setReviewDate((prevState:any) => ({
            ...prevState,
            selectedEndReviewDate: 'Invalid date',
        }));
    }
};