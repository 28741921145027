import { SystemStyleObject, Theme } from "@mui/system";

const keys = ["buttonsStyle", "container", "input"] as const;

type StylesType = Record<(typeof keys)[number], SystemStyleObject<Theme>>;

export const styles: StylesType = {
  input: {
    "&:before, &:hover:not(.Mui-disabled):before, &:after, &:focus-within:before, &:after, &.Mui-error:after":
      {
        borderBottom: "none",
      },
  },
  container: {
    display: "flex",
    gap: "2px",
    alignItems: "center",
    pl: "0px",
    ".MuiFormControl-root": {
      minWidth: "130px",
      width: "initial",
      pt: "1px",
      ".MuiInputBase-root:not(.MuiInputBase-adornedEnd):before": {
        border: "none",
      },
    },
  },
  buttonsStyle: { margin: "8px", marginLeft: "-24px" },
};
