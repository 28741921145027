export const cardStyle = {
    width: "307px",
    height: "316px",
    overflowY: "auto",
};

export const styleDatePicker = {
    width: '100%',
    mr: '15px',
    pr: '8px',
    '.MuiButtonBase-root.MuiIconButton-root': {
        p: { mobile: '4px', tablet: '8px' },
    },
}