import React, { FC, useState } from "react";
import { secondaryButton } from "../../../constants/buttonStyle";

import {
  Box,
  Button,
} from "@mui/material";

import FeedModal from "../FeedModal/FeedModal";

interface ICreateFeedProps {
  important: boolean;
  isLoad: boolean;
}

export const CreateFeed: FC<ICreateFeedProps> = ({
  important,
  isLoad,
}) => {
  const [openFeedModal, setOpenFeedModal] = useState<boolean>(false)

  const handleFeedModal = () => {
    setOpenFeedModal((prev) => !prev);
  };

  return (
    <>
      {!isLoad && 
        <Box display={"flex"} justifyContent={"center"}>
          <Button
            sx={{
              width: "auto",
              borderRadius: "60px",
              padding: "12px 20px",
              ...secondaryButton,
            }}
            onClick={handleFeedModal}
          >
            Создать +
          </Button>
        </Box>
      }

      {openFeedModal && 
        <FeedModal 
          open={openFeedModal}
          type={"create"}
          important={important}
          handleModal={handleFeedModal}
        />
      }
    </>
  );
};

export default CreateFeed;
