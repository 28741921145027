import React, { FC } from "react";

import AchievContextProvider from "./AchievContextProvider/AchievContextProvider";
import AchievePage from "./AchievPage/AchievPage";

export const Achievements: FC = () => {
  return (
    <AchievContextProvider>
      <AchievePage />
    </AchievContextProvider>
  );
};

export default Achievements;
