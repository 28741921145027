import { initialProfiles } from "./initialProfile";

export const initialData = {
  feed: [],
  feedItem: {
    attachments: [],
    author: initialProfiles.profile,
    commentCount: 0,
    content: "",
    date: "",
    id: "",
    reaction: [], //wait data
    isSetComment: false,
    sequenceNumber: 0,
    contact: null
  },
  feedComments: [],
  storyGroups: [],
  groupStories: [],
  articleSections: [],
  article: {
    id: "",
    name: "",
    content: "",
    attachments: [],
    sectionId: "",
    isGroupArticle: false,
    parentArticleId: null,
    sequenceNumber: 0
  },
  ideas: [],
  idea: {
    attachments: [],
    author: initialProfiles.profile,
    content: "",
    date: "",
    header: "",
    id: "",
    reaction: { id: "" },
    response: null,
    status: "",
    supervisor: null,
    likesCount: 0,
    likeIsPressed: false,
    dislikesCount: 0,
    dislikeIsPressed: false,
    favorite: false,
  },
  ideaComments: [],
  smiles: [],
  notifications: [],
  liveNotifications: [],
  seenNotifications: [],
};
