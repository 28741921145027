import { SystemStyleObject, Theme } from "@mui/system";

export const styles = {
  button: (theme: Theme): SystemStyleObject<Theme> => ({
    borderRadius: "1.5rem",
    color: theme.palette.text.secondary,
    backgroundColor: "rgba(203, 225, 255, 0.17)",
    "&.MuiToggleButton-root:hover": {
      backgroundColor: "rgba(185, 215, 255, 0.08)",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "#1884FF",
    },
  }),
} as const;
