import {TStylesType} from '../../../../../../types/Style.types';


export const createGift: TStylesType = {
    title: {
        mb: 3,
        mr: 2
    },
    input_title: {
        p: 2, fontSize: 28,
        '&::placeholder': {
            fontSize: 28
        }
    },
    imgBox: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px dashed grey',
        minHeight: '200px',
        cursor: 'pointer',
        '&:hover': {
            background: 'rgba(203, 225, 255, 0.17)'
        }
    }
}