import React, { FC, useContext } from "react";

import { Typography, Box } from "@mui/material";
import { ReactComponent as Done } from "../../../../assets/icons/done.svg";
import CustomButton from "../../../common/Buttons/CustomButton/CustomButton";
import { achievementController } from "../../../../services/achievement.controller";
import { AchievContext } from "../../AchievContextProvider/AchievContextProvider";

export const DoneContent: FC = () => {
  const achievementId = sessionStorage.getItem("achievementId") || "";
  const userId = localStorage.getItem("userId") || "";
  const currentUser = JSON.parse(
    localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
  );

  const { handleSnackbar, updAchievemnts } = useContext(AchievContext);
  const isCurrentUser = userId === currentUser.id;

  const handleArchive = () => {
    achievementController
      .archive(achievementId)
      .then(() => updAchievemnts(userId, false))
      .catch(() => handleSnackbar("error"));
  };

  const styleBox = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 2,
    mt: 2,
  };

  return (
    <Box sx={styleBox}>
      <Done />
      <Typography
        variant="body2"
        align="center"
        color={(theme) => theme.palette.text.secondary}
      >
        {isCurrentUser && "Поздравляем, вы добились своего! Цель выполнена!"}
      </Typography>
      {isCurrentUser && (
        <CustomButton
          text="Перенести в архив"
          color="secondary"
          onClick={handleArchive}
        />
      )}
    </Box>
  );
};

export default DoneContent;
