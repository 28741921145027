import React, { FC, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import Popover from '@mui/material/Popover';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { Moment } from 'moment';

import { useAppSelector } from '../../../hooks/useAppSelector';
import type { CalendarEventType } from '../../../types/CalendarEvent.type';
import CalendarPopover from '../CalendarPopover/CalendarPopover';
import CalendarPopup from '../CalendarPopup/CalendarPopup';
import { styles } from './styles';

interface CalendarDayProps {
    pickersDayProps: PickersDayProps<Moment>;
    date: string;
}

export const getBGColor = (types: CalendarEventType[], eventTypeName: string) => {
    const eventType = types.find((item) => (
        item.calendarEventType === eventTypeName
    ));
    return eventType?.colorHex ?? "#000000";
};

export const generateStyleBackgrounds = (types: CalendarEventType[]) => (
    Object.fromEntries(
        types.map((type, index) => ([
            `&.calendar-event-${index}`,
            {
                backgroundColor: type.colorHex,
                color: "rgba(230, 237, 245, 1)",
            },
        ]))
    )
);

export const CalendarDay: FC<CalendarDayProps> = ({pickersDayProps, date}) => {
    const dayEvents = useAppSelector(
        (state) => state.calendarReducer.allEvents[date]
    );
    const eventTypes = useAppSelector(
        (state) => state.calendarReducer.eventTypes
    );
    const weekends = useAppSelector(
        (state) => state.calendarReducer.weekends
    );

    const [openedPopover, setOpenedPopover] = useState<string>("");
    const popoverAnchors = useRef<Map<string, HTMLElement>>(new Map());

    const refAnchor = (eventKey: string) => (node: HTMLElement) => {
        const anchorsMap = popoverAnchors.current;
        if (node) {
            anchorsMap.set(eventKey, node);
        } else {
            anchorsMap.delete(eventKey);
        }
    };

    const [openPopup, setOpenPopup] = useState<boolean>(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

    const handlePopup = (e?: React.MouseEvent) => {
        e?.stopPropagation();
        setOpenPopup((prev) => !prev);
    };

    const closePopup = () => {
        setOpenPopup(false);
    };

    const popoverEnter = (targetPopover: string) => {
        setOpenedPopover(targetPopover);
    };

    const popoverLeave = () => {
        setOpenedPopover("");
    };

    const handleWrongClickEvent = (e: React.MouseEvent | undefined) => {
        e?.stopPropagation();
    };

	const { key, ...pickersDayRest } = pickersDayProps;

	return (
		<Container
			sx={[
				styles.containerStyle,
				weekends.includes(String(key).substring(4, 15)) && styles.weekend
			]}
		>
			<PickersDay {...pickersDayRest}/>
			{dayEvents && (
				<Box>
					{dayEvents.map((ce , index) => {
						ce = structuredClone(ce);
						return index < 2 && (
							<div key={ce.id!}>
								<Box
									ref={refAnchor(ce.id!)}
									onMouseEnter={() => popoverEnter(ce.id!)}
									onMouseLeave={popoverLeave}
									aria-owns="mouse-over-popover"
									aria-haspopup="true"
									p={1}
									onClick={handlePopup}
									bgcolor={getBGColor(eventTypes, ce.calendarEventType)}
									sx={[
										styles.boxStyle,
										isMobile && styles.mobileWidth,
										styles.dayItem,
										styles.dayEvent
									  ]}
								>
									{ce.name}
								</Box>
								{!isMobile && <Popover
									id="mouse-over-popover"
									sx={styles.popover}
									open={openedPopover === ce.id!}
									anchorEl={popoverAnchors.current.get(ce.id!)}
									anchorOrigin={{
										vertical: "center",
										horizontal: "left",
									}}
									transformOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									onClose={popoverLeave}
									disableRestoreFocus
									PaperProps={{
										sx: { borderRadius: "12px", pointerEvents: "auto" },
										onMouseEnter: () => popoverEnter(ce.id!),
										onMouseLeave: popoverLeave,
									}}
									onClick={handleWrongClickEvent}
								>
									<CalendarPopover calendarEvent={ce} closePopover={popoverLeave} />
								</Popover>}
							</div>
						);
					})}
					{dayEvents.length > 2
						&& (
							<Box
								onClick={handlePopup}
								fontSize="13px"
								bgcolor="rgba(185, 215, 255, 0.08)"
								sx={[
									styles.boxStyle,
									isMobile && styles.mobileWidth,
									styles.dayItem
								  ]}
							>
								{`Ещё ${dayEvents.length - 2}`}
							</Box>
						)
					}
					<Dialog
						open={openPopup}
						onClose={() => handlePopup()}
						aria-labelledby="dialog"
						aria-describedby="dialog-open"
						fullScreen={isMobile}
					>
						<CalendarPopup calendarDayEvents={dayEvents} onClose={closePopup} />
					</Dialog>
				</Box>
			)}
		</Container>
	);
};

export default CalendarDay;
