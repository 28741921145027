import React, { FC } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { styles } from "../../styles";

interface FilterAccordionProps {
  desc: string;
  filters: { id: string; name: string, checked: boolean }[];
  onChangeItem: (id: string, checked: boolean) => void;
}

export const FilterAccordion: FC<FilterAccordionProps> = ({
  desc,
  filters,
  onChangeItem,
}) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography component="span">{desc}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={[styles.columnAlignedStyle, styles.accordion]}>
        {filters.map(({ id, name, checked }) => (
          <FormControlLabel
            key={id}
            sx={styles.accordionLabel}
            checked={checked}
            control={<Checkbox value={id} />}
            label={<Typography sx={styles.accordionLabelName}>{name}</Typography>}
            onChange={(event, checked) => onChangeItem(id, checked)}
            labelPlacement="start"
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
