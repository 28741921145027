export const reviewPageSize = 10;

export enum ReviewNotifications {
    noticeAll = 1,
    noticeShelvedPerson = 2,
    noticeReviewedPerson = 3
}

export enum ReviewEditStatus {
    unArchive = 1,
    edit = 2,
}