import React, { FC } from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export interface LoadProgressProps {
  testId?: string;
}

export const LoadProgress: FC<LoadProgressProps> = ({ testId }) => {
  return (
    <Box display="flex" justifyContent="center" my={2} width="100%" data-testid={testId}>
      <CircularProgress
        color="inherit"
        aria-describedby="loading"
        aria-busy={true}
      />
    </Box>
  );
};
