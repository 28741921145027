import React, { FC, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { kbController } from "../../../../services/kb.controller";
import { KbContext } from "../../KbContextProvider/KbContextProvider";
import { dataInitial } from "../../../../actions/data";
import { AppContext } from "../../../shared/AppContextProvider";
import { roles } from "../../../../constants/role";

import CustomButton from "../../../common/Buttons/CustomButton/CustomButton";
import SectionModal from "../../KbModal/SectionModal";

import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export const CreateKnowledgeButton: FC = () => {
  const dispatch = useDispatch();
  const { setTotalItems } = useContext(KbContext);

  const { isMobile, user } = useContext(AppContext);
  const notUserRole = user.role !== roles.user;

  const wrapperButtonStyle = {
    mt: 4,
    display: "flex",
    justifyContent: "center",
  };

  const [createSectionModal, setCreateSectionModal] = useState<boolean>(false);
  const handleCreateSectioneModal = () => {
    setCreateSectionModal((prev) => !prev);
  };

  const updSections = () => {
    kbController.sections().then((res) => {
      dispatch(dataInitial.articleSections(res.data.items));
      setTotalItems(res.data.totalItems);
    });
  }

  return (
    <>
      {notUserRole && (
        <Box sx={isMobile ? wrapperButtonStyle : undefined}>
          <CustomButton color="secondary" text="Создать раздел" startIcon={<AddIcon />} onClick={handleCreateSectioneModal} />
        </Box>
      )}
      {createSectionModal && (
        <SectionModal
          open={createSectionModal}
          type="create"
          handleModal={handleCreateSectioneModal}
          handleCompleted={updSections}
        />
      )}
    </>
  );
};

export default CreateKnowledgeButton;
