import React, { FC, useEffect, useState } from "react";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../../hooks/useAppSelector";
import { feedController } from "../../../services/feed.controller";
import { dataInitial } from "../../../actions/data";

import {
  Stack,
  Paper,
  useTheme,
  useMediaQuery,
  Typography,
  FormControlLabel,
  SelectChangeEvent,
  Box,
} from "@mui/material";

import { LoadProgress } from "../../common/LoadProgress/LoadProgress";
import DialogWrapper from "../../common/Modals/DialogWrapper/DialogWrapper";
import ModalButtons from "../../common/Buttons/ModalButtons/ModalButtons";
import FeedTextEditor from "../FeedTextEditor/FeedTextEditor";
import PreviewFiles from "../../common/Attachments/PreviewFiles/PreviewFiles";
import AttachmentButton from "../../common/Buttons/AttachmentButton/AttachmentButton";
import Snackbars from "../../common/Snackbars/Snackbars";
import CheckboxWrapper from "../../common/CheckboxWrapper/CheckboxWrapper";
import FeedPinnedRecord from "../material/FeedPinnedRecord";
import FeedTypePinnedRecord from "../material/FeedTypePinnedRecord";
import { feedInitialState } from "../Feed";

interface IFeedModalProps {
  open: boolean;
  type: "create" | "edit";
  important: boolean;
  feedId?: string | null;
  handleModal: () => void;
  handleCompleted?: () => void;
}

const convertContent = (articleContent: any) => {
  return articleContent?.includes("entityMap")
    ? articleContent
    : JSON.stringify({
        entityMap: {},
        blocks: [
          {
            key: "637gr",
            text: articleContent,
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
      });
};

export const FeedModal: FC<IFeedModalProps> = ({
  open,
  type,
  important,
  feedId = null,
  handleModal,
  handleCompleted = () => {},
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const deleteFiles = useAppSelector(
    (state) => state.manageDataReducer.deleteFiles
  );

  const [isLoad, setIsLoad] = useState<boolean>(false)
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [countPinnedRecords, setCountPinnedRecords] = useState<number>(0)

  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty())

  const [isSendNotification, setIsSendNotification] = useState<boolean>(false)
  const handleCheckboxNotification = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSendNotification (event.target.checked);
  };

  const [isSetComment, setIsSetComment] = useState<boolean>(false)
  const handleCheckboxComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSetComment (event.target.checked);
  };

  const [numberPinnedRecord, setNumberPinnedRecord] = useState<string>('null')
  const handlePinnedRecord = (e: SelectChangeEvent) => {
    setNumberPinnedRecord(e.target.value);
  };

  const [typeShift, setTypeShift] = useState<'replacement' | 'offset'>('replacement')
  const handleTypeShift = (e: SelectChangeEvent) => {
    setTypeShift(e.target.value as 'replacement' | 'offset')
  };

  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const handleAttachmentButton = (event: any) => {
    setSelectedFiles([...selectedFiles, ...event.target.files]);
    event.target.value = "";
  };

  const handleError = () => {
    setOpenSnackbar(true)
  }

  const getCountsPinnedRecords = () => {
    setIsLoad(true)
    feedController
      .countsPinnedRecords(important)
      .then((res) => {
        setCountPinnedRecords(res.data)
        if (type === 'edit' && feedId) {
          getInfoFeed()
        } else {
          setIsLoad(false)
        }
      })
      .catch(() => {
        handleError()
      });
  }

  const getInfoFeed = () => {
    feedController
      .feedItem(feedId)
      .then((res) => {
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(convertContent(res.data.content)))
          )
        )
        setIsSetComment(res.data.isSetComment)
        setNumberPinnedRecord(res.data.sequenceNumber)
        setSelectedFiles(res.data.attachments)
        setIsLoad(false)
      })
      .catch(() => {
        handleError()
      });
  }

  const createFeed = () => {
    setIsLoad(true);
    const formData = new FormData();
    formData.append(
      "request",
      new Blob(
        [
          JSON.stringify({
            content: convertEditorState(),
            important: important,
            isReplacement: typeShift === 'replacement',
            isSendNotification: isSendNotification,
            isSetComment: isSetComment,
            sequenceNumber: numberPinnedRecord || null,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );
    [...selectedFiles].forEach((image) => {
      formData.append("files", image);
    });    
    
    feedController
      .newFeed(formData)
      .then(() => {
        feedController
          .feed(0, feedInitialState.tabIndex === 'important', (feedInitialState.currentPage + 1) * feedInitialState.countsRecords)
          .then((res) => {   
            dispatch(dataInitial.feed(res.data.items));
          })
          .catch(() => {
            handleError()
          })
          .finally(() => {
            handleCompleted()
          });
        handleModal();   
      })
      .catch(() => {        
        handleError();
      })
      .finally(() => {
        setIsLoad(false);
      });
  }

  const editFeed = () => {
    setIsLoad(true);
    const formData = new FormData();
    formData.append(
      "request",
      new Blob(
        [
          JSON.stringify({
            content: convertEditorState(),
            important: important,
            isReplacement: typeShift === 'replacement',
            isSetComment: isSetComment,
            sequenceNumber: numberPinnedRecord || null,
            idAttachmentsToDelete: deleteFiles,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );
    
    const newFiles = selectedFiles?.filter(
      (item) => item.webkitRelativePath === "" //take only new files
    );    

    if (newFiles && newFiles.length > 0) {
      [...newFiles].forEach((file) => {
        formData.append("files", file);
      });
    }; 
    
    feedController
      .updateFeedItem(feedId, formData)
      .then(() => {
        feedController
          .feed(0, feedInitialState.tabIndex === 'important', (feedInitialState.currentPage + 1) * feedInitialState.countsRecords)
          .then((res) => {   
            dispatch(dataInitial.feed(res.data.items));
          })
          .catch(() => {
            handleError()
          })
          .finally(() => {
            handleCompleted()
          });
        handleModal(); 
      })
      .catch(() => {        
        handleError();
      })
      .finally(() => {
        setIsLoad(false);
      });
  }

  const sendInfoFeed = () => {
    if (type === 'edit' && feedId) {
      editFeed();
    } else {
      createFeed();
    }
  }

  const convertEditorState = () => {
    return JSON.stringify(convertToRaw(editorState.getCurrentContent()));
  };

  useEffect(() => {
    getCountsPinnedRecords()
  }, []);

  const createModalButtons = (
    <ModalButtons
      handleCancel={handleModal}
      handleSend={sendInfoFeed}
      fullWidth
      nameButtonSend={type === 'create' ? 'Опубликовать' : 'Редактировать'}
      justifyContent="flex-end"
      disableSend={false}
    />
  );
  
  return (
    <>
      <DialogWrapper
        width={isMobile ? '450px' : '750px'}
        openModal={open}
        handleModal={handleModal}
        buttons={createModalButtons}
        contentDividers
      >
        { isLoad ?
          <LoadProgress/> :
            
          <Stack gap={3}>
            <Paper
              sx={{
              boxShadow: "none",
              border: "1px solid rgba(241, 247, 255, 0.12)",
              }}
            >
              <FeedTextEditor
                readOnly={false}
                setEditorState={setEditorState}
                editorState={editorState}
              />
            </Paper>

            {selectedFiles?.length > 0 && (
              <PreviewFiles
                files={selectedFiles}
                setFiles={setSelectedFiles}
                type="upload"
                saveDeleteId={true}
              />
            )}

            <Stack display={'flex'} flexDirection={'row'} gap={3}>
              <AttachmentButton
                onChange={handleAttachmentButton}
                withBackground
              />

              {type === 'create' && 
                <FormControlLabel
                  control={
                    <CheckboxWrapper onChange={handleCheckboxNotification} checked={isSendNotification} />
                  }
                  label={
                    <Typography
                      component="span"
                      variant="body2"
                      color={(theme) => theme.palette.text.secondary}
                    >
                      Рассылать уведомления
                    </Typography>
                  }
                />
              }

              <FormControlLabel
                control={
                  <CheckboxWrapper onChange={handleCheckboxComment } checked={isSetComment} />
                }
                label={
                  <Typography
                    component="span"
                    variant="body2"
                    color={(theme) => theme.palette.text.secondary}
                  >
                    Открыть комментарии
                  </Typography>
                }
              />
            </Stack>

            <Typography sx={{whiteSpace: "nowrap"}}>Закрепить запись : </Typography>
            <Box display={'flex'} alignItems={'center'} gap={3} width={'450px'}>
              <FeedPinnedRecord 
                value={numberPinnedRecord}
                countsPinnedRecord={countPinnedRecords + 1}
                onChange={handlePinnedRecord}
              />
              {numberPinnedRecord && 
                <FeedTypePinnedRecord 
                  value={typeShift}
                  onChange={handleTypeShift}
                />
              }
            </Box>

          </Stack>
        }
      </DialogWrapper>

      <Snackbars
        open={openSnackbar}
        setOpen={handleError}
        type="error"
        position="center"
      />
    </>
  );
};

export default FeedModal;
