import React, {Dispatch, SetStateAction, useContext} from 'react'
import { Stack } from '@mui/material'
import ModalButtons from '../../common/Buttons/ModalButtons/ModalButtons'
import DialogWrapper from '../../common/Modals/DialogWrapper/DialogWrapper'
import { AppContext } from '../../shared/AppContextProvider'
import EditObservers from './EditObservers'
import DatePickerCustom from '../../common/Inputs/DatePickerCustom/DatePickerCustom'
import {PersonView} from "../../../models/profile.models";
import {VotedReviewers} from "../../../models/review.models";
import {styleDatePicker} from "./styles/styles";

interface IReviewSelectedDate {
    selectedStartReviewDate: string,
    selectedEndReviewDate:string,
}

interface IProps{
    handleEditModal: () => void
    openEditModal : boolean
    selectedReviewers: PersonView[]
    setSelectedReviewers: Dispatch<SetStateAction<PersonView[]>>
    votedReviewers: VotedReviewers[]
    handleSend: () => void
    selectedReviewDate: IReviewSelectedDate
    handleDateChange : (date: string, type: 'start' | 'end') => void
}

const EditReviewModal = ({ handleEditModal,
                           openEditModal,
                           handleSend,
                           selectedReviewDate,
                           handleDateChange,
                             selectedReviewers,
                             setSelectedReviewers,
                             votedReviewers
                         }: IProps) => {
  const { isMobile } = useContext(AppContext)

  const {selectedStartReviewDate, selectedEndReviewDate} = selectedReviewDate

  const handleEditReviewSend = () => {
    handleSend()
  }

  const handleModal = () => {
    handleEditModal()
  }

  return (
    <DialogWrapper
      openModal={openEditModal}
      handleModal={handleModal}
      width='432px'
      height={!isMobile ? '348px' : undefined}
      buttons={
        <ModalButtons
          justifyContent={'right'}
          handleSend={handleEditReviewSend}
          handleCancel={handleModal}
          disableSend={selectedEndReviewDate === 'Invalid date'}
          nameButtonSend={'Сохранить'}
        />
      }
    >
      <Stack gap={3}>
        <DatePickerCustom
            value={selectedStartReviewDate}
            label={'Выбрать дату начала ревью'}
            onChange={(date) => handleDateChange(date, 'start')}
            disableFuture={false}
            styles={styleDatePicker}
        />
        <DatePickerCustom
          value={selectedEndReviewDate}
          label={'Выбрать дату окончания ревью'}
          onChange={(date) => handleDateChange(date, 'end')}
          disableFuture={false}
          styles={styleDatePicker}
        />
        <EditObservers
          selectedReviewers={selectedReviewers}
          setSelectedReviewers={setSelectedReviewers}
          votedReviewers={votedReviewers}
        />
      </Stack>
    </DialogWrapper>
  )
}

export default EditReviewModal
