import React from "react";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {EmployerRenderCell} from "./EmployerRenderCell";
import {Box} from "@mui/material";
import EditPopover from "../../common/Modals/EditPopover/EditPopover";
import {ActiveStatusPopoverButtons, ArchivedStatusPopoverButtons} from "./ModalActions";
import {ReviewNotifications, ReviewEditStatus} from "../../../constants/review";



export const columnsDefs = (openModalWithStatus: (status: ReviewNotifications, reviewId: string) => void,
                            handleOpenEditModalWithStatus : (status: ReviewEditStatus, params: GridRenderCellParams) => void,
                            deleteReview: ( params: GridRenderCellParams) => void,
                            archiveReview: ( params: GridRenderCellParams) => void): GridColDef[]  => {
    return [
        {
            field: "startReview",
            headerName: "Дата начала",
            sortable: false,
            headerClassName: "table-header",
            flex: 1,
        },
        {
            field: "endReview",
            headerName: "Дата окончания",
            sortable: false,
            headerClassName: "table-header",
            flex: 1,
        },
        {
            field: "employee",
            headerName: "Сотрудник",
            headerClassName: "table-header",
            flex: 2,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Box onClick={(e) => { e.stopPropagation() }}>
                        <EmployerRenderCell {...params.row.owner} />
                    </Box>
                );
            },
        },
        {
            field: "skill",
            headerName: "Навык",
            sortable: false,
            headerClassName: "table-header",
            flex: 1,
            renderCell: (params: GridRenderCellParams) => {
                const skill = params.row.skill.name;
                return (
                    <Box>
                        {skill}
                    </Box>
                );
            },
        },
        {
            field: "score",
            headerName: "Сбор оценок",
            sortable: false,
            headerClassName: "table-header",
            flex: 1,
            renderCell: (params: GridRenderCellParams) => {
                const reviewers = Array.isArray(params.row.reviewers) ? params.row.reviewers : [];
                const votedScore = reviewers.filter((el: { voted: boolean }) => el.voted).length;
                const maxScore = reviewers.length;

                return (
                    <Box>
                        {votedScore}/{maxScore}
                    </Box>
                );
            },
        },
        {
            field: "",
            width: 50,
            sortable: false,
            headerClassName: "table-header",
            renderCell: (params: GridRenderCellParams) => {

                const popoverButtons = params.row.status === 'ARCHIVED' || params.row.status === 'DONE'
                    ? ArchivedStatusPopoverButtons(params,handleOpenEditModalWithStatus, deleteReview)
                    : ActiveStatusPopoverButtons(params, openModalWithStatus, handleOpenEditModalWithStatus, deleteReview, archiveReview)

                return (
                    <Box onClick={(e) => { e.stopPropagation() }}>
                        <EditPopover buttonsData={popoverButtons}/>
                    </Box>
                )
            },
        }
    ]
}
