import React, { FC } from "react";
import Box from "@mui/material/Box";
import { AttachmentView } from "../../../../models/attachment.model";

interface PreviewAudiosProp {
  audios: AttachmentView[];
}

export const PreviewAudios: FC<PreviewAudiosProp> = ({ audios }) => {
  return (
    <Box>
      {audios
        ?.filter((item: any, index: number) => index <= 13)
        .map((item: any, index: number) => (
          <audio
              key={index}
            src={`/loyalty-portal/api/attachments/${item.name}${item.extension}`}
            controls
          />
        ))}
    </Box>
  );
};

export default PreviewAudios;

