import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { CalendarEventType } from "../../../../types/CalendarEvent.type";

interface EventTypeItem extends CalendarEventType {
  checked: boolean;
}

export const useEvents = (tags: string[]) => {
  const [filters, setFilters] = useState<EventTypeItem[]>([]);
  const { eventTypes, allEventsQuery } = useAppSelector(
    (state) => state.calendarReducer
  );

  useEffect(() => {
    if (!allEventsQuery || "tags" in allEventsQuery) {
      setFilters(
        eventTypes
          .filter(({ tag }) => tags.includes(tag))
          .map((item) => ({ ...item, checked: false }))
      );
    }
  }, [eventTypes, allEventsQuery, tags]);

  return { filters, setFilters, allEventsQuery };
};
