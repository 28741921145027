import {IReviewContextValue} from "./ReviewInProfileContextProvider";

export let defaultValue: IReviewContextValue = {
    handleReviewModal: () => { },
    handleRatingModal: () => { },
    openReviewModal: false,
    openRatingModal: false,
    editableAccess: false,
    skillList: [],
    userId: '',
    currentUserId: '',
    setReviewId: () => { },
    reviewId: '',
    reviewIdFromNotification: '',
    setReviewIdFromNotification: () => { },
    openApprovedModal: false,
    handleApprovedModal: () => { },
    openEditModal: false,
    open: false,
    handleEditModal: () => { },
    setOpen: () => { },
    hasReviewer: false,
    listPage: 0,
    setListPage: () => {},
}