import React, {
  FC,
  useContext,
  Fragment,
  useState,
  useRef,
} from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import moment from "moment";

import { theme } from "../../../constants/theme";
import EditPopover from "../../common/Modals/EditPopover/EditPopover";
import DialogWrapper from "../../common/Modals/DialogWrapper/DialogWrapper";
import ModalButtons from "../../common/Buttons/ModalButtons/ModalButtons";

import { getBGColor } from "../CalendarDay/CalendarDay";
import {
  useUpdateEventMutation,
  useDeleteEventMutation,
} from "../../../services/calendar.controller";
import { CalendarContext } from "../CalendarContext/CalendarContextProvider";
import EventModalContent from "../EventModalContent/EventModalContent";
import ConfirmModal from "../../common/Modals/ConfirmModal/ConfirmModal";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { IEventsState, INewEvent } from "../../../types/CalendarEvent.type";
import { transformEventForPutRequest } from "../helpers";

import { styles } from "./styles";

interface CalendarPopupProps {
  calendarDayEvents: IEventsState[];
  onClose: () => void;
}
// TODO: Combine CalendarPopup with CalendarPopover, as they share a lot of the same logic.
export const CalendarPopup: FC<CalendarPopupProps> = ({
  calendarDayEvents,
  onClose,
}) => {
  const [openModal, setOpenModal] = useState<number>(-1);
  const [openConfirmModal, setOpenConfirmModal] = useState<number>(-1);
  const [eventState, setEventState] = useState<
    (INewEvent & Partial<IEventsState>) | null
  >(null);

  const [updateEvent, updateData] = useUpdateEventMutation();
  const [deleteEvent, deleteData] = useDeleteEventMutation();

  const forRepeatedEvent = useRef<boolean>();
  const confirmModalText = useRef<string>("");

  const isMobile = useMediaQuery(useTheme().breakpoints.down("laptop"));

  const { onCalendarError: handleError, editTagsFilter } =
    useContext(CalendarContext);

  const isInvalidEvent = !eventState;
  const isLoading = updateData.isLoading || deleteData.isLoading;
  const isInvalidTime = eventState && eventState?.finishTime <= eventState?.startTime;
  const isEmptyName = !eventState?.name;
  const isInvalidDate = !eventState?.date || eventState.date === "Invalid date"

  const disabled: boolean =
    isInvalidEvent || isLoading || isInvalidTime || isEmptyName || isInvalidDate;

  const eventTypes = useAppSelector(
    (state) => state.calendarReducer.eventTypes
  );

  const handleModal = (i: number) => {
    setOpenModal((prev) => (prev === -1 ? i : -1));
  };

  const handleCancel = () => {
    setOpenModal(-1);
    setEventState(null);
  };

  const handleConfirmModal = (i: number) => {
    setOpenConfirmModal((prev) => (prev === -1 ? i : -1));
  };

  const closeConfirmModal = () => {
    setOpenConfirmModal(-1);
  };

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteEvent({
        id,
        params: forRepeatedEvent.current
          ? { repeatable: forRepeatedEvent.current }
          : undefined,
      });
      if ("error" in res) throw new Error(res.error.message);
      closeConfirmModal();
      calendarDayEvents.length === 1 && onClose();
    } catch (error) {
      handleError();
    }
  };

  const modalButtonCreator = (event: IEventsState): React.ReactNode => {
    const handleSend = async () => {
      if (!eventState) return;
      try {
        const body = transformEventForPutRequest(eventState);
        const res = await updateEvent({ id: event.id, body });
        if ("error" in res) throw new Error(res.error.message);
        handleCancel();
      } catch (error) {
        handleError();
      }
    };
    return (
      <ModalButtons
        nameButtonSend="Сохранить"
        handleSend={handleSend}
        handleCancel={handleCancel}
        justifyContent="end"
        disableSend={disabled}
      />
    );
  };

  return (
    <Card sx={styles.styleCard} onClick={(e) => e?.stopPropagation()}>
      {isMobile && (
        <Box display="flex" justifyContent="end" margin="8px">
          <IconButton
            sx={{ width: "32px", height: "32px" }}
            color="secondary"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      <CardContent sx={styles.styleContent(isMobile)}>
        <Box sx={styles.styleMainBox}>
          {calendarDayEvents.map((calendarEvent, index: number) => {
            calendarEvent = structuredClone(calendarEvent);
            return (
              <Fragment key={index}>
                <Box display="flex" alignItems="center" mt="12px" width="100%">
                  <Box
                    sx={[styles.boxIconStyle]}
                    bgcolor={getBGColor(
                      eventTypes,
                      calendarEvent.calendarEventType
                    )}
                  />
                  <Box
                    display="flex"
                    alignItems="center"
                    mt="1px"
                    width="100%"
                    justifyContent="space-between"
                  >
                    <Typography variant="subtitle2" mr="42px">
                      {calendarEvent.name}
                    </Typography>
                    {editTagsFilter.includes(
                        String(
                          eventTypes.find(
                            ({ id }) => id === calendarEvent.eventTypeId
                          )?.tag
                        )
                      ) && (
                        <>
                          <EditPopover
                            buttonsData={[
                              {
                                name: "Редактировать",
                                action: () => {
                                  forRepeatedEvent.current = false;
                                  setEventState(calendarEvent);
                                  handleModal(index);
                                },
                              },
                              {
                                name: "Удалить",
                                action: () => {
                                  forRepeatedEvent.current = false;
                                  confirmModalText.current =
                                    "Данное событие будет удалено.";
                                  handleConfirmModal(index);
                                },
                              },
                              ...(calendarEvent.repeatId
                                ? [
                                    {
                                      name: "Редактировать цепочку событий",
                                      action: () => {
                                        forRepeatedEvent.current = true;
                                        setEventState(calendarEvent);
                                        handleModal(index);
                                      },
                                    },
                                    {
                                      name: "Удалить цепочку событий",
                                      action: () => {
                                        forRepeatedEvent.current = true;
                                        confirmModalText.current =
                                          "Данная цепочка событий будет удалена.";
                                        handleConfirmModal(index);
                                      },
                                    },
                                  ]
                                : []),
                            ]}
                          />
                          <DialogWrapper
                            openModal={openModal === index}
                            handleModal={() => handleModal(index)}
                            width="862px"
                            contentDividers
                            stylesContent={{
                              padding: "0px",
                              borderTop: "none",
                            }}
                            buttons={modalButtonCreator(calendarEvent)}
                          >
                            {eventState && (
                              <EventModalContent
                                forRepeatedEvent={forRepeatedEvent.current}
                                eventState={eventState}
                                setEventState={setEventState}
                              />
                            )}
                          </DialogWrapper>
                        </>
                      )}
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="20px"
                  marginLeft="2px"
                  paddingRight="44px"
                >
                  <Typography
                    variant="body1"
                    color={theme?.palette?.text?.secondary}
                    sx={{
                      "&:first-letter": { textTransform: "uppercase" },
                      whiteSpace: "nowrap",
                    }}
                  >
                    {moment(calendarEvent.date, "DD-MM-YYYY").format(
                      "dddd, Do MMMM "
                    )}
                    {calendarEvent.isWholeDay
                      ? "весь день"
                      : `${calendarEvent.startTime} - ${calendarEvent.finishTime}`}
                  </Typography>
                  <Typography
                    variant="body1"
                    color={theme?.palette?.text?.secondary}
                  >
                    {calendarEvent.description}
                  </Typography>
                </Box>
                <ConfirmModal
                  openConfirmModal={openConfirmModal === index}
                  handleModal={() => handleConfirmModal(index)}
                  nameConfirmButton="Удалить"
                  handleConfirm={() => handleDelete(calendarEvent.id)}
                  text={confirmModalText.current}
                />
                {index < calendarDayEvents.length - 1 && (
                  <hr style={styles.hrStyle(isMobile)} />
                )}
              </Fragment>
            );
          })}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CalendarPopup;
