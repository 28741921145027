import { ACHIEVEMENTS } from "../constants/actions";
import { AchievementView, TaskTagView, TaskView } from "../models/achievement.models";
import { CommentView } from "../models/comment.models";

export const achievementsInitial = {
  achievement: (payload: AchievementView) => {
    return { type: ACHIEVEMENTS.ACHIEVEMENT, payload };
  },
  achievementTask: (payload: TaskView) => {
    return { type: ACHIEVEMENTS.ACHIEVEMENT_TASK, payload };
  },
  achievementsList: (payload: AchievementView[]) => {
    return { type: ACHIEVEMENTS.ACHIEVEMENTS_LIST, payload };
  },
  achievementTags: (payload: TaskTagView[]) => {
    return { type: ACHIEVEMENTS.ACHIEVEMENT_TAGS, payload };
  },
  achievementComments: (payload: CommentView[]) => {
    return { type: ACHIEVEMENTS.ACHIEVEMENT_COMMENTS, payload };
  },
};
