import { useEffect, useState } from "react";
import { FilterAccordionItem } from "../types";
import { projectsApi } from '../../../../reducer/projects';
import { useAppSelector } from "../../../../hooks/useAppSelector";

export const useProjects = () => {
  const [projects, setProjects] = useState<FilterAccordionItem[]>([]);
  const { data } = projectsApi.useFetchAllProjectsQuery();
  const { allEventsQuery } = useAppSelector((state) => state.calendarReducer);

  useEffect(() => {
    if (data && !allEventsQuery)
      setProjects(data.map(({ id, name }) => ({ id, name, checked: false })));
  }, [data, allEventsQuery]);

  return { projects, setProjects };
};
