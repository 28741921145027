import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  Select,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  CircularProgress, Menu
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface Option {
  id: string;
  name: string;
}

interface ModalWithSelectProps {
  open: boolean;
  onClose: () => void;
  onDelete: (selectedId: string) => void;
  options: Option[];
  loading: boolean; // Передаем состояние загрузки из родителя
}

const ModalWithSelect: React.FC<ModalWithSelectProps> = ({
 open,
 onClose,
 onDelete,
 options,
 loading,
}) => {
  const theme = useTheme()

  const [selectedId, setSelectedId] = useState<string>('');

  const handleDelete = () => {
    if (selectedId) {
      onDelete(selectedId);
      setSelectedId(""); // Очистка выбора
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: theme.palette.background.default,
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Выберите тег для удаления
        </Typography>

        <FormControl fullWidth>
          <InputLabel>Выберите</InputLabel>
          <Select
            value={selectedId}
            onChange={(e) => setSelectedId(e.target.value)}
            disabled={loading} // Блокируем во время загрузки
            MenuProps={{
              PaperProps: {
                sx: {
                  backgroundColor: theme.palette.background.default, // Делаем фон списка тоже белым
                },
              },
            }}
          >
            {options.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button onClick={onClose} sx={{ mr: 1 }} variant="outlined" disabled={loading}>
            Отменить
          </Button>
          <Button
            onClick={handleDelete}
            variant="contained"
            color="error"
            disabled={!selectedId || loading}
            sx={{ color: "white" }}
          >
            {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Удалить"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalWithSelect;