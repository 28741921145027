import {useState} from "react";

export const usePaginationState = () => {
    const [activePage, setActivePage] = useState<number>(0);
    const [archivePage, setArchivePage] = useState<number>(0);

    return {
        activePage, setActivePage,
        archivePage, setArchivePage,
    };
};
