import {createApi} from '@reduxjs/toolkit/query/react';
import {customBaseQuery} from './customBaseQuery';
import {IGifts, TGiftView} from '../models/gifts.models';

export const giftsController = createApi({
    reducerPath: 'giftsController',
    baseQuery: customBaseQuery,
    tagTypes: ['Gifts'],
    endpoints: (builder) => ({
        getAllGifts: builder.query<IGifts, ''>({
            query: () => ({
                url: 'gift?size=1000'
            }),
            providesTags: ['Gifts'],
        }),
        getAllGiftsByUserId: builder.query<IGifts, string>({
            query: (id) => ({
                url: `gift/${id}`
            }),
            providesTags: ['Gifts'],
        }),
        getGiftByID: builder.query<TGiftView, string>({
            query: (id) => ({
                url: `gift/get/${id}`
            }),
            providesTags: ['Gifts'],
        }),
        createGift: builder.mutation<TGiftView, FormData>({

            query: (body) => ({
                url: 'gift',
                method: 'POST',
                body,
                headers: {}
            }),
            invalidatesTags: ['Gifts'],
        }),
        editGift: builder.mutation<TGiftView, { id: string; body: FormData }>({
            query: ({id, body}) => ({
                url: `gift/${id}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['Gifts'],
        }),
        deleteGift: builder.mutation<unknown, string>({
            query: (id) => ({
                url: `gift/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Gifts'],
        }),
    }),
});

export const {
    useGetAllGiftsQuery,
    useLazyGetAllGiftsByUserIdQuery,
    useGetGiftByIDQuery,
    useLazyGetGiftByIDQuery,
    useCreateGiftMutation,
    useEditGiftMutation,
    useDeleteGiftMutation,
    useGetAllGiftsByUserIdQuery
} = giftsController;

