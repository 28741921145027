import { IWeekendsResponse } from "../../../../types/CalendarEvent.type";

export const transformWeekendsForContext = (
  data: IWeekendsResponse,
  year: number
): string[] => {
  const newArr = [
    ...data.items.flatMap((item) =>
      item.holidays.map((holiday: number) => {
        const month = item.month[0] + item.month.substring(1, 3).toLowerCase();
        return `${month} ${holiday.toString().padStart(2, "0")} ${year}`;
      })
    ),
  ];
  return newArr;
};
