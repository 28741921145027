import { SystemStyleObject, Theme } from "@mui/system";

interface StylesType {
  styleMainBox: SystemStyleObject<Theme>;
  styleCard: SystemStyleObject<Theme>;
  styleContent: (isMobile: boolean) => SystemStyleObject<Theme>;
  boxIconStyle: SystemStyleObject<Theme>;
  hrStyle: (isMobile: boolean) => React.CSSProperties;
}

export const styles: StylesType = {
  styleMainBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginLeft: "10px",
    marginTop: "6px",
    gap: "6px",
  },
  styleCard: {
    margin: "4px 4px 8px 20px",
    minWidth: 280,
    maxWidth: 666,
    height: "auto",
    backgroundColor: "#212633",
    boxShadow: "none",
    overflowY: "scroll",
    scrollbarWidth: "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  styleContent: (isMobile) => ({
    marginTop: isMobile ? "-35px" : "-16px",
    paddingRight: isMobile ? "8px" : "12px",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
    "&:last-child": {
      paddingBottom: "16px",
    },
  }),
  boxIconStyle: {
    height: "16px",
    width: "16px",
    ml: "-26px",
    mr: "12px",
    borderRadius: "5px",
  },
  hrStyle: (isMobile) => ({
    width: isMobile ? "108%" : "110%",
    backgroundColor: "rgba(241, 247, 255, 0.12)",
    height: "1px",
    border: "none",
    margin: "16px -32px 0px",
  }),
};
