import React, { FC } from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import Navigation from "../Navigation/Navigation";
import CommunityList from "./CommunityList/CommunityList";
import Logo from "../../common/Logo/Logo";
import BugReport from "../../BugReport/BugReport";
import {SxProps} from "@mui/material";
import {Theme} from "@mui/system";

interface LeftSidebarProps {
  style?: SxProps<Theme>;
}

export const LeftSidebar: FC<LeftSidebarProps> = ({ style }) => {
  return (
    <Box sx={style}>
      <Logo withTitle asLink />
      <Navigation/>
      <Divider />
      <CommunityList />
      <BugReport type="desktop" />
    </Box>
  );
};

export default LeftSidebar;
