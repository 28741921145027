import React, { useEffect, useRef, useState } from 'react';
import InputWrapper from '../../../../../common/Inputs/InputWrapper/InputWrapper';
import Stack from '@mui/material/Stack';
import { UserInfo } from '../../../../../../types/UserInfo.types';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CustomButton from '../../../../../common/Buttons/CustomButton/CustomButton';
import AboutPaper from '../../common/AboutPaper/AboutPaper';
import Popover from '@mui/material/Popover';
import MultiSelect from '../../../../../common/MultiSelect/MultiSelect';
import { SelectChangeEvent } from '@mui/material';

interface IProps {
  data: UserInfo['communities'];
  onChange: (field: string, value: any) => void;
}

const Communities = ({ data, onChange }: IProps) => {
  const theme = useTheme()

  const $editCommunities = useRef<HTMLDivElement | null>(null)

  const [communities, setCommunities] = useState<UserInfo['communities']>(data || [])
  const [hiddenCommunities, setHiddenCommunities] = useState<string[]>([])

  const [editIsOpen, setEditIsOpen] = useState(false)

  const styleLink = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.25rem 0.75rem',
    color: theme?.palette?.primary.main,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "wrap",
    fontWeight: 600,
    fontSize: '1rem',
  }

  const actions: any[] = [
    // <CustomButton
    //   color="primary"
    //   text="Скрыть группы"
    //   onClick={(e) => setEditIsOpen(true)}
    //   ref={$editCommunities}
    //   key={'userEditingHideGroups'}
    // />
  ]

  const handleChange = (e:  SelectChangeEvent<string[]>) => {
    setHiddenCommunities(e.target.value as string[])
  }

  const handleSave = () => {
    if (!data) return
    const value: UserInfo['communities'] = data.map(community => ({ ...community, privateCommunity: hiddenCommunities.includes(community.id) }))
    onChange('communities', value)
    setEditIsOpen(false)
  }

  useEffect(() => {
    if (data) {
      setCommunities(data)
      setHiddenCommunities(data.filter(c => c.privateCommunity).map(c => c.id))
    }
  }, [data]);

  const visibleGroups = data?.filter(group => !group?.privateCommunity)

  return (
    <InputWrapper title="Список отображаемых групп">
      <AboutPaper actions={actions}>
        <Stack width="100%" direction="row" spacing={2} position="relative">
          <Typography variant="subtitle2" sx={styleLink}>{visibleGroups?.map(community => <span key={community?.id}>{community?.name}</span>)}</Typography>
          <Popover
            id="editPersonalTags"
            open={editIsOpen}
            anchorEl={$editCommunities.current}
            onClose={() => setEditIsOpen(false)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <MultiSelect
              open={editIsOpen}
              data={communities?.map(community => ({ name: community.name, value: community.id })) || []}
              selectedValues={hiddenCommunities}
              onChange={handleChange}
              onClose={handleSave}
              placeholder="Выберите сообщества"
              renderValue={(selected) => `Выбрано: ${selected.length}`}
              onlyMenu={true}
            />
          </Popover>
        </Stack>
      </AboutPaper>
    </InputWrapper>
  );
};

export default Communities;