import React, {FC} from 'react';
import RowTable from '../ManagementTable/RowTable/RowTable';
import ManagementTable from '../ManagementTable/ManagementTable';
import {useGetAllReviewGiftQuery} from '../../../../services/person_gifts.controller';
import {useAppSelector} from '../../../../hooks/useAppSelector';
import Typography from '@mui/material/Typography';


export const UserGiftsTable: FC = () => {
    const {createdAtFrom, createdAtTo, searchName} = useAppSelector((state) => state.searchFilters);

    const filters = {
        createdAtFrom,
        createdAtTo,
        ownerName: searchName,
    };
    const {data} = useGetAllReviewGiftQuery(filters)
    const giftsApplications = data?.items;

    const columns = [
        {
            id: 'createdAt',
            px: 2,
            label: 'Дата подачи заявки',
            divider: true
        },
        {
            id: 'ownerName',
            px: 5,
            label: 'Сотрудник',
            divider: true
        },
        {
            id: 'gift',
            px: 5,
            label: 'Подарки',
            divider: true
        },
        {
            id: 'status',
            px: 5,
            label: 'Статус',
            divider: false
        }
    ]

    return (
        <ManagementTable
            columns={columns}
            children={<>
                {giftsApplications && giftsApplications.length >= 1 ? giftsApplications?.map((
                    {
                        id,
                        createdAt,
                        gift,
                        owner,
                        status
                    }) =>
                    <RowTable
                        key={id}
                        date={createdAt}
                        name={owner.name}
                        typeName={gift.title}
                        status={status.description}
                        type={'managementGifts'}
                        id={id}
                    />
                ) : <Typography display={'flex'} justifyContent={'center'}>Список подарков пуст</Typography>}
            </>
            }
        />
    );
};

export default UserGiftsTable;