import React, { FC, createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Snackbars from "../../common/Snackbars/Snackbars";
import { useFetchWeekendsQuery } from "../../../services/calendar.controller";
import { addWeekends } from "../../../reducer/calendar";
import { Role } from "../../../constants/role";
import { AppContext } from "../../shared/AppContextProvider";

import { transformWeekendsForContext } from "./helpers";

interface CalendarContextProps {
	children: React.ReactNode;
}

interface CalendarContextValue {
	setYear: React.Dispatch<React.SetStateAction<number>>;
	onCalendarError: (message?: string) => void;
	editTagsFilter: readonly string[];
  }
  
  const tagFiltersByRole: Record<Role, readonly string[]> = {
	ADMIN: ["GENERAL", "CORPORATE"],
	EDITOR: ["GENERAL", "CORPORATE"],
	USER: ["GENERAL"],
  } as const;
  
  const defaultValue: CalendarContextValue = {
	setYear: () => {},
	onCalendarError: () => {},
	editTagsFilter: tagFiltersByRole.USER,
  };
  
export const CalendarContext = createContext<CalendarContextValue>(defaultValue);

export const CalendarContextProvider: FC<CalendarContextProps> = ({
	children,
}) => {
	const dispatch = useDispatch();
	const [errorMessage, setErrorMessage] = useState<string | undefined>();
	const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
	const onCalendarError: CalendarContextValue['onCalendarError'] = (message) => {
		setErrorMessage(message);
		setOpenSnackbar(true);
	}
	const { user } = useContext(AppContext);
	const editTagsFilter = tagFiltersByRole[user.role]

	const [year, setYear] = useState<number>(new Date().getFullYear());

	const { data, isError } = useFetchWeekendsQuery({year})
	if (isError) dispatch(addWeekends([]))

	useEffect(() => {
		if (data) {
			dispatch(addWeekends(transformWeekendsForContext(data, year)))
		}
	}, [data, year, dispatch]);

	return (
		<CalendarContext.Provider value={{
			setYear,
			onCalendarError,
			editTagsFilter
		}}>
			{children}
			<Snackbars
				open={openSnackbar}
				setOpen={setOpenSnackbar}
				type="error"
				position="center"
				message={errorMessage || "С Календарём что-то не так, свяжитесь с администратором"}
			/>
		</CalendarContext.Provider>
	);
};

export default CalendarContextProvider;
