import {AnyAction, combineReducers} from "redux";
import {configureStore, ThunkAction} from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import { profileReducer } from "../reducer/profiles";
import { dataReducer } from "../reducer/data";
import { socketsReducer } from "../reducer/sockets";
import { communitiesReducer } from "../reducer/communities";
import { manageDataReducer } from "../reducer/manageData";
import { achievementsReducer } from "../reducer/achievements";
import {reviewReducer} from "../reducer/review";
import { calendarApi } from "../services/calendar.controller";
import { departmentApi } from "../services/department.controller";
import { projectsApi } from "../reducer/projects";
import { personsApi } from "../services/person.controller";
import {activityController} from '../services/activity.controller';
import {personActivityController} from '../services/person_activity.controller';
import {giftsController} from '../services/gift.controller';
import {personGiftController} from '../services/person_gifts.controller';
import snackbarReducer from '../reducer/snackbarSlice'
import calendarReducer from '../reducer/calendar'
import bonusesModalReducer from '../reducer/bonusesModalSlice';
import searchFiltersReducer from '../reducer/searchFiltersSlice';
import { skillApi } from "../services/skill.controller";
import { setupListeners } from '@reduxjs/toolkit/query';

const rootReducer = combineReducers({
  profileReducer,
  dataReducer,
  socketsReducer,
  calendarReducer,
  communitiesReducer,
  manageDataReducer,
  achievementsReducer,
  reviewReducer,
  [calendarApi.reducerPath]: calendarApi.reducer,
  [departmentApi.reducerPath]: departmentApi.reducer,
  [projectsApi.reducerPath]: projectsApi.reducer,
  [activityController.reducerPath]: activityController.reducer,
  [personActivityController.reducerPath]: personActivityController.reducer,
  [giftsController.reducerPath]: giftsController.reducer,
  [personGiftController.reducerPath]: personGiftController.reducer,
  [personsApi.reducerPath]: personsApi.reducer,
  [skillApi.reducerPath]: skillApi.reducer,
  snackbarReducer,
  bonusesModal: bonusesModalReducer,
  searchFilters: searchFiltersReducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    calendarApi.middleware,
    skillApi.middleware,
    departmentApi.middleware,
    projectsApi.middleware,
    personsApi.middleware,
    activityController.middleware,
    personActivityController.middleware,
    giftsController.middleware,
    personGiftController.middleware,
    thunk
  ),
  devTools: process.env.NODE_ENV !== "production",
});

setupListeners(store.dispatch);

export type AppState = ReturnType<typeof rootReducer>;

export type ReturnActionsTypes<T> = T extends {
  [key: string]: (...args: any) => infer U;
}
  ? U
  : never;


export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, AnyAction>
