import React, { FC } from "react";

import IconButton from "@mui/material/IconButton";
import { ReactComponent as Attachment } from "../../../../assets/icons/attachment.svg";
import { Box, SystemStyleObject, Theme } from "@mui/system";

interface AttachmentButtonProps {
  onChange: (props?: any) => void;
  withBackground?: boolean;
  padding?: string;
  ariaDescribedby?: string;
  ariaLabel?: string;
  edge?: false | "start" | "end";
}

export const AttachmentButton: FC<AttachmentButtonProps> = ({
  onChange,
  withBackground,
  padding = "12px",
  edge,
}) => {
  const styleAttachButton: SystemStyleObject<Theme> = {
    width: "fit-content",
    p: padding,
    "& .MuiSvgIcon-root": {
      width: "24px",
      height: "24px",
    },
    "&:hover": {
      backgroundColor: "rgba(185, 215, 255, 0.08)",
    },
    backgroundColor: withBackground ? "rgba(203, 225, 255, 0.17)" : undefined,
  };

  const input: SystemStyleObject<Theme> = {
    width: 0,
    height: 0,
    overflow: "hidden"
  }

  return (
    <IconButton
      color="secondary"
      component="label"
      sx={styleAttachButton}
      onChange={onChange}
      disableRipple
      size="small"
      edge={edge}
    >
      <Box sx={input}>
        <input hidden accept="*" multiple type="file" />
      </Box>
      <Attachment />
    </IconButton>
  );
};

export default AttachmentButton;