import React, { FC, useContext } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EditIcon from "@mui/icons-material/Edit"
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { NavLink } from "react-router-dom";

import { PersonView } from "../../../../../../models/profile.models";
import UserAvatar from "../../../../../common/User/UserAvatar/UserAvatar";
import { theme } from "../../../../../../constants/theme";
import bg from "../../../../../../assets/images/backgroudDefault.png";
import { AppContext } from "../../../../../shared/AppContextProvider";

interface LoggedPopoverCardProps {
  userInfo: PersonView;
  closePopover: () => void;
}

interface IButton {
  title: string
  to: string
  handle: () => void
  icon: React.JSX.Element
}

export const LoggedPopoverCard: FC<LoggedPopoverCardProps> = ({
  userInfo,
  closePopover,
}) => {
  const loggedProfileId = JSON.parse(
    localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
  ).id;
  const { logout } = useContext(AppContext);

  const profileHandle = () => {
    localStorage.setItem("userId", loggedProfileId);
    closePopover();
  }
  const logoutHandle = () => {
    logout();
    closePopover();
  }

  const buttons: IButton[] = [
    {
      title: "Мой профиль",
      icon: <AccountCircleIcon />,
      to: `/profile/${loggedProfileId}`,
      handle: profileHandle
    },
    {
      title: "Редактирование",
      icon: <EditIcon />,
      to: "/profile/edit",
      handle: profileHandle
    },
    {
      title: "Выход",
      icon: <ExitToAppIcon />,
      to: '/login',
      handle: logoutHandle
    },
  ];

  const styleBox = (alignItems: string) => {
    return {
      display: "flex",
      flexDirection: "column",
      alignItems: alignItems,
    };
  };

  const styleItemButton = {
    width: "100%",
    gap: "12px",
    borderRadius: "48px",
    marginLeft: 0,
  };

  const styleCard = {
    minWidth: 280,
    height: "auto",
    paddingBottom: "16px",
    backgroundColor: "#212633",
  };

  const styleItemIcon = { color: "#1884FF", minWidth: "auto" };

  // const handleButton = (index: number) => {
  //   if (index === 0) {
  //     localStorage.setItem("userId", loggedProfileId);
  //   }
  //   if (index === 1) {
  //     logout();
  //   }
  //   return closePopover();
  // };

  const imgSrc = userInfo.background
    ? `/loyalty-portal/api/attachments/${userInfo.background.name}${userInfo.background.extension}`
    : bg;
  const bgStyle = {
    height: 76,
    backgroundImage: `url(${imgSrc})`,
  };

  return (
    <Card sx={styleCard}>
      <CardMedia component="div" sx={bgStyle} />
      <CardContent sx={{ marginTop: "-35px" }}>
        <Box sx={styleBox("center")}>
          <UserAvatar userInfo={userInfo} withBorder />
          <Typography variant="subtitle2" mt="12px">
            {userInfo?.name + " " + userInfo?.surname}
          </Typography>
          <Typography variant="body1" color={theme?.palette?.text?.secondary}>
            {userInfo?.job?.title?.name}
          </Typography>
        </Box>
        <Divider sx={{ marginTop: "24px" }} />
      </CardContent>
      <CardActions sx={styleBox("flex-start")}>
        {buttons.map((item, index) => (
          <ListItemButton
            style={styleItemButton}
            key={index}
            component={NavLink}
            to={item.to}
            onClick={() => item.handle()}
          >
            <ListItemIcon sx={styleItemIcon}>{item?.icon}</ListItemIcon>
            <ListItemText
              primary={item?.title}
              primaryTypographyProps={{
                variant: "body1",
              }}
            />
          </ListItemButton>
        ))}
      </CardActions>
    </Card>
  );
};

export default LoggedPopoverCard;
