import { apiUrlPortal } from "../constants/apiUrl";
import jwtInterceptor from "./jwtInterceptor";

export const storyController = {
  allStoryGroup: async (page?: number): Promise<any> => {
    return await jwtInterceptor.get(
      `${apiUrlPortal}/story_groups?page=${page ?? 0}`
    );
  },
  addStoryGroup: async (body?: any): Promise<any> => {
    return await jwtInterceptor.post(`${apiUrlPortal}/story_groups`, body);
  },
  storiesFromGroup: async (groupId?: string | null): Promise<any> => {
    return await jwtInterceptor.get(
      `${apiUrlPortal}/story_groups/${groupId}/stories`
    );
  },
  deleteStoryGroup: async (groupId?: string | null): Promise<any> => {
    return await jwtInterceptor.delete(
      `${apiUrlPortal}/story_groups/${groupId}`
    );
  },
  updStoryGroup: async (
    groupId?: string | null,
    pinned?: boolean
  ): Promise<any> => {
    return await jwtInterceptor.put(`${apiUrlPortal}/story_groups/${groupId}`, {
      pinned: pinned,
    });
  },

  updOneStory: async (
    groupId?: string | null,
    id?: string | null,
    body?: {
      content: string;
      header: string;
    }
  ): Promise<any> => {
    return await jwtInterceptor.put(
      `${apiUrlPortal}/story_groups/${groupId}/stories/${id}`,
      body
    );
  },
  deleteOneStory: async (
    groupId?: string | null,
    id?: string | null
  ): Promise<any> => {
    return await jwtInterceptor.delete(
      `${apiUrlPortal}/story_groups/${groupId}/stories/${id}`
    );
  },
  attachmentOneStory: async (
    groupId?: string | null,
    id?: string | null,
    body?: any
  ): Promise<any> => {
    return await jwtInterceptor.put(
      `${apiUrlPortal}/story_groups/${groupId}/stories/${id}/attachments`,
      body
    );
  },
};
