import moment from "moment";
import {
  IEventsState,
  IPutEventRequest,
} from "../../../types/CalendarEvent.type";

type TransformData = Pick<
  IEventsState,
  | "date"
  | "startTime"
  | "finishTime"
  | "description"
  | "name"
  | "eventTypeId"
> &
  Partial<Pick<IEventsState, "participantsEvent">>;

export const transformEventForPutRequest = ({
  date,
  startTime,
  finishTime,
  description,
  name,
  eventTypeId,
  participantsEvent,
}: TransformData): IPutEventRequest => {
  const data: IPutEventRequest = {
    dateStart: moment(date, "DD-MM-YYYY")
      .add(Number(startTime.slice(0, 2)), "hours")
      .add(Number(startTime.slice(3)), "minutes")
      .toISOString(),
    dateEnd: moment(date, "DD-MM-YYYY")
      .add(Number(finishTime.slice(0, 2)), "hours")
      .add(Number(finishTime.slice(3)), "minutes")
      .toISOString(),
    description,
    eventName: name,
    eventType: eventTypeId,
  };
  if (participantsEvent)
    data.participantsIdEvent = participantsEvent.map(({ id }) => id);
  return data;
};
