import React, {FC} from "react";
import Achievements from "../../Achievements/Achievements";
import Review from "../../Review/ReviewInProfile/Review";
import Stack from "@mui/material/Stack";
import {MainInfoProps} from "../Media/Media";
import PapersElement, {PapersDataObj} from "../Contacts/Contacts";
import moment from "moment";
import Projects from "../Projects/Projects";

export const Career: FC<MainInfoProps> = ({ userInfo: { hired, job, projects, remoteWorkStatus }}) => {
  const getRemoteWorkStatus = () => {
    switch (remoteWorkStatus.name) {
      case "REMOTE_WORK":
        return `Одобрено до ${ moment(remoteWorkStatus.remoteWorkTo).format("DD.MM.YYYY") }`
      case "REMOTE_WORK_PERMANENT":
        return "Одобрено"
      default:
        return 'Не одобрено'
    }
  }

  const careerInfo: PapersDataObj[] = [
    {
      title: 'Департамент',
      name: '',
      info: job.department.name,
    },
    {
      title: 'Должность',
      name: '',
      info: job.title.name,
    },
    {
      title: 'Дата найма',
      name: 'hired',
      info: moment(hired).format("DD.MM.YYYY"),
    },
    {
      title: 'Удаленный формат работы',
      name: 'name',
      info: getRemoteWorkStatus()
    }
  ]

  return (
      <Stack spacing={3}>
        <PapersElement data={careerInfo} />
        {projects.length > 0 && (<Projects projects={projects}/>)}
        <Review />
        <Achievements />
      </Stack>
  )
}

export default Career;
