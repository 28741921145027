import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SearchFiltersState {
    searchName: string;
    createdAtFrom: string | null;
    createdAtTo: string | null;
}

const initialState: SearchFiltersState = {
    searchName: "",
    createdAtFrom: null,
    createdAtTo: null,
};

const searchFiltersSlice = createSlice({
    name: "searchFilters",
    initialState,
    reducers: {
        setSearchName: (state, action: PayloadAction<string>) => {
            state.searchName = action.payload;
        },
        setDateRange: (
            state,
            action: PayloadAction<{ createdAtFrom: string | null; createdAtTo: string | null }>
        ) => {
            state.createdAtFrom = action.payload.createdAtFrom;
            state.createdAtTo = action.payload.createdAtTo;
        },
        resetFilters: (state) => {
            state.searchName = "";
            state.createdAtFrom = null;
            state.createdAtTo = null;
        },
    },
});

export const { setSearchName, setDateRange, resetFilters } = searchFiltersSlice.actions;
export default searchFiltersSlice.reducer;
