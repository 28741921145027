import qs from "qs";
import {BaseQueryFn, FetchArgs} from "@reduxjs/toolkit/query";
import {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";

import {apiUrlPortal} from "../constants/apiUrl";

import jwtInterceptor from "./jwtInterceptor";

export const customBaseQuery: BaseQueryFn<
    FetchArgs,
    AxiosResponse,
    { status?: number; message?: string; data: unknown }
> = async ({url, method, params, body}) => {
    try {
        const isFormData = body instanceof FormData;
        const config: AxiosRequestConfig = {
            method,
            paramsSerializer: (params) =>
                qs.stringify(params, {
                    encode: true,
                    arrayFormat: "repeat"
                }),
            headers: isFormData ? {} : {"Content-Type": "application/json"},
        };
        if (params) config.params = params;
        if (body) config.data = body;
        const res = await jwtInterceptor(`${apiUrlPortal}/${url}`, config);
        return res;
    } catch (error) {
        const {message, response} = error as AxiosError;
        return {
            error: {
                message,
                status: response?.status,
                data: response?.data,
            },
        };
    }
};
