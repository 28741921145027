import React, {Dispatch, useState} from 'react';
import ModalButtons from "../../common/Buttons/ModalButtons/ModalButtons";
import {Stack, Typography} from "@mui/material";
import DialogWrapper from "../../common/Modals/DialogWrapper/DialogWrapper";
import InputBase from "@mui/material/InputBase";
import {reviewController} from "../../../services/review.controller";
import {ISnackBar} from "../../common/Snackbars/Snackbars";
import {textSnackbars} from "../../../constants/snackBarNotifications";
import {ReviewNotifications} from '../../../constants/review';
import {styleInputBase} from "./styles/noticeModalStyles";

interface IProps {
    modalData: { status: ReviewNotifications, reviewId: string } | null
    handleReviewModal: () => void
    openModal: boolean
    setSnackBar: Dispatch<React.SetStateAction<ISnackBar>>
}

const NoticeModal = ({modalData, handleReviewModal, openModal, setSnackBar}: IProps) => {

    const [message, setMessage] = useState<string>("");

    const handleMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(event.target.value);
    };

    const handleModal = () => {
        handleReviewModal()
    }

    const handleSend = () => {
        if (!modalData?.status) return;
        const {reviewId, status} = modalData

        switch (status) {
            case ReviewNotifications.noticeAll:
                reviewController.sendNoticeAll(reviewId, {description: message}).then((res) => {
                        if (res.data.status === 'success') {
                            setMessage('')
                            setSnackBar({
                                message: textSnackbars.notifications.notifications_sent_successfully,
                                open: true,
                                type: 'success'
                            });
                        }
                    }
                )
                break;
            case ReviewNotifications.noticeShelvedPerson:
                reviewController.sendNoticeShelvedPerson(reviewId, {description: message}).then((res) => {
                        if (res.data.status === 'success') {
                            setMessage('')
                            setSnackBar({
                                message: textSnackbars.notifications.notifications_sent_successfully,
                                open: true,
                                type: 'success'
                            });
                        }
                    }
                )
                break;
            case ReviewNotifications.noticeReviewedPerson:
                reviewController.sendNoticeReviewedPerson(reviewId, {description: message}).then((res) => {
                        if (res.data.status === 'success') {
                            setMessage('')
                            setSnackBar({
                                message: textSnackbars.notifications.notifications_sent_successfully,
                                open: true,
                                type: 'success'
                            });
                        }
                    }
                )
                break;
            default:
                setSnackBar({message: textSnackbars.default, open: true, type: 'error'});
        }
        handleModal();
    }

    const handleCancel = () => {
        handleModal()
    }

    return (
        <DialogWrapper
            openModal={openModal}
            handleModal={handleModal}
            width='532px'
            contentDividers
            buttons={
                <ModalButtons
                    justifyContent={'right'}
                    handleSend={handleSend}
                    handleCancel={handleCancel}
                    nameButtonSend={'Отправить'}
                />
            }
        >
            <Stack gap={3} display={'flex'} flexDirection={'column'}>
                <Typography variant='h4'>{'Уведомление'}</Typography>
                <InputBase
                    fullWidth
                    multiline
                    placeholder="начните вводить сообщение"
                    value={message}
                    onChange={handleMessage}
                    sx={styleInputBase}
                    autoFocus={true}
                />
            </Stack>
        </DialogWrapper>
    );
};

export default NoticeModal;