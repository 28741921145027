import {useState} from "react";
import {ReviewList} from "../../../models/review.models";

export const useReviewsState = () => {
    const [reviews, setReviews] = useState<ReviewList[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [status, setStatus] = useState<string>("Активные");
    const [totalRecords, setTotalRecords] = useState<number>(0);

    return {
        reviews,
        setReviews,
        loading,
        setLoading,
        status,
        setStatus,
        totalRecords,
        setTotalRecords,
    };
};