import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  ToggleButton,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";

import { changeAllEventsQuery } from "../../../reducer/calendar";

import { FilterAccordion } from "./ui/FilterAccordion";
import { useDepartments, useEvents, useProjects } from "./hooks";

import { styles } from "./styles";

const tags: string[] = ["REQUEST"];

export const CalendarApplications = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { departments, setDepartments } = useDepartments();
  const { projects, setProjects } = useProjects();
  const { filters, setFilters, allEventsQuery } = useEvents(tags);

  return (
    <>
      <ToggleButton
        value={"myEvents"}
        selected={Boolean(allEventsQuery && !("tags" in allEventsQuery))}
        onChange={() => setShowModal(!showModal)}
      >
        Заявления
      </ToggleButton>
      <Modal open={showModal} onClose={() => setShowModal(!showModal)}>
        <Box sx={styles.modalContainerStyle}>
          <Typography variant="h4" align="center" mb={"1rem"}>
            Фильтр
          </Typography>
          <Box sx={styles.columnAlignedStyle}>
            <Typography sx={styles.dscription}>
              Выберите тип заявления:
            </Typography>
            {filters.map(({ id, checked, fullName, colorHex }) => {
              return (
                <FormControlLabel
                  key={id}
                  sx={[styles.eventStyle, { backgroundColor: colorHex }]}
                  control={
                    <Checkbox
                      checked={checked}
                      sx={styles.typesColor}
                      onChange={() => {
                        setFilters((state) => {
                          const newState = [...state];
                          const current = newState.find(
                            (item) => item.id === id
                          );
                          if (current) current.checked = !checked;
                          return newState;
                        });
                      }}
                    />
                  }
                  label={
                    <Typography sx={[styles.typesFont, styles.typesColor]}>
                      {fullName}
                    </Typography>
                  }
                  labelPlacement="start"
                />
              );
            })}
          </Box>
          <Box>
            <FilterAccordion
              desc="Отдел"
              filters={departments}
              onChangeItem={(id, checked) => {
                setDepartments((state) => {
                  const newState = [...state];
                  const current = newState.find((item) => item.id === id);
                  if (current) current.checked = checked;
                  return newState;
                });
              }}
            />
            <FilterAccordion
              desc="Проект"
              filters={projects}
              onChangeItem={(id, checked) => {
                setProjects((state) => {
                  const newState = [...state];
                  const current = newState.find((item) => item.id === id);
                  if (current) current.checked = checked;
                  return newState;
                });
              }}
            />
          </Box>
          <Box sx={styles.buttonsBlockStyle}>
            <Button
              sx={styles.buttonStyle}
              onClick={() => {
                setShowModal(false);
              }}
            >
              Отмена
            </Button>
            <Button
              sx={styles.buttonStyle}
              onClick={() => {
                const params = {
                  departmentsIds: departments
                    .filter(({ checked }) => checked)
                    .map(({ id }) => id),
                  projectsIds: projects
                    .filter(({ checked }) => checked)
                    .map(({ id }) => id),
                  typesIds: filters
                    .filter(({ checked }) => checked)
                    .map(({ id }) => id),
                };
                const isEmptyParams = !Boolean(
                  Object.values(params).map(String).join("")
                );
                dispatch(changeAllEventsQuery(isEmptyParams ? null : params));
                setShowModal(false);
              }}
            >
              Применить
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
