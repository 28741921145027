import { useEffect, useState } from "react";
import { useFetchAllDepartmentsQuery } from "../../../../services/department.controller";
import { FilterAccordionItem } from "../types";
import { useAppSelector } from "../../../../hooks/useAppSelector";

export const useDepartments = () => {
  const [departments, setDepartments] = useState<FilterAccordionItem[]>([]);
  const { data } = useFetchAllDepartmentsQuery();
  const { allEventsQuery } = useAppSelector((state) => state.calendarReducer);

  useEffect(() => {
    if (data && !allEventsQuery)
      setDepartments(
        data.items.map(({ id, name }) => ({ id, name, checked: false }))
      );
  }, [data, allEventsQuery]);

  return { departments, setDepartments };
};
