import { PersonView, RemoteWorkStatuses } from '../../../../models/profile.models';

export const joyUser: PersonView = {
  avatar: null,
  blocked: false,
  city: { id: "", name: "" },
  contact: {
    email: "",
    github: "",
    id: "",
    instagram: "",
    linkedin: "",
    phone: "",
    skype: "",
    telegram: "",
    vk: "",
  },
  description: "",
  dob: "",
  fired: "",
  gender: "",
  hired: "",
  id: "",
  job: {
    department: { id: "", name: "" },
    grade: { id: "", name: "" },
    id: "0",
    title: { id: "", name: "Помощник LoyaltyLabs" },
  },
  name: "Джой",
  patronymic: "",
  role: { role: "USER" },
  surname: "",
  balance: 0,
  managedPermissions: [],
  communities: [],
  remoteWorkStatus: {
    name: 'NOT_REMOTE',
    remoteWorkTo: null,
    remoteWorkFrom: null
  },
  projects: [],
  skills: [],
  status: { id: "", name: "" },
  tags: []
}
