import React, {Dispatch, FC, SetStateAction} from 'react';
import {TUserInfo} from '../../../types/UserInfo.types';
import CustomToggleButtons from "../Buttons/CustomToggleButtons/CustomToggleButtons";
import { translateGender } from "../../../translators/gender.translate";


interface GenderProps {
  personData?: TUserInfo;
  setPersonData: Dispatch<SetStateAction<TUserInfo>>;
  disabled?: boolean;
}
export const Gender: FC<GenderProps> = ({
  personData,
  setPersonData,
  disabled,
}) => {
  const genderItems: string[] = ["MALE", "FEMALE"];

  const handleChangeGender = (value: string) => {
    setPersonData((prevState) => ({
      ...prevState,
      gender: value,
    }));
  };

  return (
    <CustomToggleButtons
      items={genderItems}
      currentValue={personData?.gender}
      onChange={handleChangeGender}
      translator={translateGender}
      disabled={disabled}
    />
  );
};

export default Gender;
