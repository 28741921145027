import {useState} from "react";
import {ReviewEditStatus, ReviewNotifications} from "../../../constants/review";

export const useModalState = () => {
    const [modalStatus, setModalStatus] = useState<{ status: ReviewNotifications; reviewId: string } | null>(null);
    const [modalEditStatus, setModalEditStatus] = useState<{ status: ReviewEditStatus; reviewId: string } | null>(null);
    const [openEditModal, setOpenEditConfirmModal] = useState<boolean>(false);
    const [openNotificationModal, setOpenNotificationModal] = useState<boolean>(false);

    return {
        modalStatus, setModalStatus,
        modalEditStatus, setModalEditStatus,
        openEditModal, setOpenEditConfirmModal,
        openNotificationModal, setOpenNotificationModal,
    };
};