import React, { useContext, useEffect, useRef, useState } from 'react';
import Stack from '@mui/material/Stack';
import InputWrapper from '../../../../../common/Inputs/InputWrapper/InputWrapper';
import { ITagView, UserInfo } from '../../../../../../types/UserInfo.types';
import CustomButton from '../../../../../common/Buttons/CustomButton/CustomButton';
import Typography from '@mui/material/Typography';
import AboutPaper from '../../common/AboutPaper/AboutPaper';
import { useTheme } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import MultiSelect from '../../../../../common/MultiSelect/MultiSelect';
import { tagController } from '../../../../../../services/tag.controller';
import { SelectChangeEvent } from '@mui/material';
import NewTagForm from '../../common/NewTagForm/NewTagForm';
import { generateHashtag } from '../../../../../../helpers/generateHashtag';
import { AppContext } from '../../../../../shared/AppContextProvider';
import RemoveTagForm from '../../common/RemoveTagForm/RemoveTagForm';
import { on } from 'ws';
import AddIcon from '@mui/icons-material/Add';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import { ERole } from '../../../../../../models/profile.models';

interface IProps {
  data: UserInfo['tags'];
  onChange: (value: any) => void;
}

const Tags = ({ data, onChange }: IProps) => {
  const theme = useTheme()
  const { user } = useContext(AppContext)

  const $addTag = useRef<HTMLDivElement | null>(null)

  const [tags, setTags] = useState<ITagView[]>([])
  const [selectedTags, setSelectedTags] = useState<ITagView[]>([])

  const [editIsOpen, setEditIsOpen] = useState(false)
  const [createTag, setCreateTag] = useState<boolean>(false);
  const [removeTag, setRemoveTag] = useState<boolean>(false);
  const [removingProcess, setRemovingProcess] = useState<boolean>(false);

  const styleLink = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.25rem 0.75rem',
    color: theme?.palette?.primary.main,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "wrap",
    fontWeight: 600,
    fontSize: '1rem',
  }

  const actions = [
    <CustomButton
      color="primary"
      text="Тег"
      onClick={(e) => setEditIsOpen(true)}
      ref={$addTag}
      key={'userEditingEditTag'}
    />
  ]

  if (user.role !== ERole.ADMIN) {
    actions.pop()
  }

  const handleChange = (e: SelectChangeEvent<string[]>) => {
    const newValue = (e.target.value as string[]).map(tagId => (tags as ITagView[]).find(tag => tag.id === tagId) as ITagView)
    onChange(newValue)
    setSelectedTags(newValue)
  }

  const handleSave = (ids?: string[]) => {
    if (!tags) return
    const res = Array.isArray(ids) ? ids : selectedTags.map(tag => tag.id)
    const value = res?.map(tagId => tags.find(tag => tag.id === tagId) as ITagView)
    onChange(value)
    setEditIsOpen(false)
  }

  const handleCreateTag = async (value: string) => {
    try {
      const res = await tagController.create(generateHashtag(value))
      setTags(prevState => prevState ? [...prevState, res.data] : prevState)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeleteTag = async (id: string) => {
    try {
      setRemovingProcess(true)
      await tagController.remove(id)
      setTags(prevState => prevState ? prevState?.filter(tag => tag.id !== id) : prevState)
      const a = selectedTags?.filter(tag => tag).filter(tag => tag.id !== id).map(tag => tag?.id)
      handleSave(a)
      setRemovingProcess(false)
      setRemoveTag(false)
    } catch (error) {
      console.error(error)
    }
  }

  const fetchTags = async () => {
    try {
      const res = await tagController.fetchAll()
      setTags(res.data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    void fetchTags()
  }, []);

  useEffect(() => {
    if (data) {
      setSelectedTags(data)
    }
  }, [data]);


  const tagsActions = [
    <MenuItem value={''}>
      <AddIcon color="inherit" fontSize="small" />
      <ListItemText primary="Добавить новый" onClick={() => setCreateTag(true)} sx={{ textAlign: 'center' }}/>
    </MenuItem>,
    <MenuItem value={''}>
      <DeleteIcon color="inherit" fontSize="small" />
      <ListItemText primary="Удалить" onClick={() => setRemoveTag(true)} sx={{ textAlign: 'center' }}/>
    </MenuItem>
  ]

  if (user.role === ERole.USER) {
    tagsActions.pop()
  }

  return (
    <InputWrapper title="Ко мне можно обратиться за советом">
      <AboutPaper actions={actions}>
        <Stack spacing={2} width="100%" direction="row"  position="relative">
          <Typography sx={styleLink}>{data?.map(tag => <span key={tag?.id}>{tag?.name}</span>)}</Typography>
          <Popover
            id="editPersonalTags"
            open={editIsOpen}
            anchorEl={$addTag.current}
            onClose={() => setEditIsOpen(false)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <MultiSelect
              data={tags?.map(tag => ({ name: tag?.name, value: tag?.id })) || []}
              selectedValues={selectedTags?.map(tag => tag?.id) || []}
              onChange={handleChange}
              onClose={handleSave}
              placeholder="Выберите сообщества"
              renderValue={(selected) => `Выбрано: ${selected?.length}`}
              actions={tagsActions}
              open={editIsOpen}
              onlyMenu={true}
            />
          </Popover>
        </Stack>
      </AboutPaper>
      <NewTagForm open={createTag} onClose={() => setCreateTag(false)} onCreate={handleCreateTag} />
      <RemoveTagForm options={tags || []} loading={removingProcess} open={removeTag} onClose={() => setRemoveTag(false)} onDelete={handleDeleteTag}/>
    </InputWrapper>
  );
};

export default Tags;