import { ProfilesInfo } from "../profiles";
import { RemoteWorkStatuses } from '../../models/profile.models';

export const initialProfiles: ProfilesInfo = {
  profile: {
    managedPermissions: [],
    achievements: [],
    avatar: null,
    background: null,
    blocked: false,
    city: { id: "", name: "" },
    communities: [],
    remoteWorkStatus: {
      name: 'NOT_REMOTE',
      remoteWorkTo: null,
      remoteWorkFrom: null
    },
    projects: [],
    skills: [],
    status: { id: "", name: "" },
    tags: [],
    contact: {
      email: "",
      github: "",
      id: "",
      instagram: "",
      linkedin: "",
      phone: "",
      skype: "",
      telegram: "",
      vk: "",
    },
    description: "",
    dob: "",
    fired: "",
    gender: "",
    hired: "",
    id: "",
    job: {
      department: { id: "", name: "" },
      grade: { id: "", name: "" },
      id: "",
      title: { id: "", name: "" },
    },
    name: "",
    patronymic: "",
    role: { role: "USER" },
    surname: "",
    balance: 0
  },
  filteredProfiles: [],
  editProfile: {
    managedPermissions: [],
    achievements: [],
    avatar: null,
    background: null,
    blocked: false,
    city: { id: "", name: "" },
    communities: [],
    remoteWorkStatus: {
      name: 'NOT_REMOTE',
      remoteWorkTo: null,
      remoteWorkFrom: null
    },
    projects: [],
    skills: [],
    status: { id: "", name: "" },
    tags: [],
    contact: {
      email: "",
      github: "",
      id: "",
      instagram: "",
      linkedin: "",
      phone: "",
      skype: "",
      telegram: "",
      vk: "",
    },
    description: "",
    dob: "",
    fired: "",
    gender: "",
    hired: "",
    id: "",
    job: {
      department: { id: "", name: "" },
      grade: { id: "", name: "" },
      id: "",
      title: { id: "", name: "" },
    },
    name: "",
    patronymic: "",
    role: { role: "USER" },
    surname: "",
    balance: 0
  },
  allProfiles: [],
  totalCountProfiles: 0
};
