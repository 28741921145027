import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useAppDispatch} from '../../../../../../hooks/useAppDispatch';
import {useAppSelector} from '../../../../../../hooks/useAppSelector';
import {toggleModal} from '../../../../../../reducer/bonusesModalSlice';
import {
    useCreateGiftMutation,
    useEditGiftMutation,
    useLazyGetGiftByIDQuery
} from '../../../../../../services/gift.controller';
import {showSnackbar} from '../../../../../../reducer/snackbarSlice';

export const useGiftModal = () => {
    const dispatch = useAppDispatch();
    const [fetchGiftById, {data, isFetching}] = useLazyGetGiftByIDQuery();

    const inputRef = useRef<HTMLInputElement | null>(null);
    const [formData, setFormData] = useState({
        image: '' as File | string | '',
        name: '',
        comment: '',
        cost: ''
    });
    const {image, name, comment, cost} = formData;
    const [create] = useCreateGiftMutation();
    const {isOpenView, modalType, selectedEntityId} = useAppSelector((state) => state.bonusesModal);
    const isOpenCreate = isOpenView && modalType === 'createGift';
    const isOpenEdit = isOpenView && modalType === 'editeGift';
    const [edite] = useEditGiftMutation();

    useEffect(() => {
        if (isOpenEdit && selectedEntityId) {
            fetchGiftById(selectedEntityId)
                .unwrap()
                .then((response) => {
                    setFormData({
                        cost: `${response.cost}`,
                        name: response.title,
                        comment: response.description || '',
                        image: response.imageName ? `/loyalty-portal/api/attachments/${response.imageName}` : ''
                    });
                });
        }
    }, [isOpenEdit, selectedEntityId]);


    useEffect(() => {
        if (data && isOpenEdit) {
            setFormData({
                cost: `${data.cost}`,
                name: data.title,
                comment: data.description || '',
                image: data.imageName ? `/loyalty-portal/api/attachments/${data.imageName}` : ''
            });
        }
    }, [data, isOpenEdit]);

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setFormData((prev) => ({
                ...prev,
                image: file
            }));
        }
    };

    const handleClose = useCallback(() => {
        dispatch(toggleModal({type: null, isOpenView: false, id: ''}));
    }, [])


    const handleSend = async () => {
        const formDataFetch = new FormData();

        const requestData = {
            cost: cost ? Number(cost) : 0,
            description: comment || '',
            title: name || '',
        };

        formDataFetch.append(
            'request',
            new Blob([JSON.stringify(requestData)], {type: 'application/json'})
        );

        if (image instanceof File) {
            formDataFetch.append('file', image, image?.name);
        }

        try {
            isOpenCreate ? await create(formDataFetch).unwrap() : await edite({
                id: selectedEntityId,
                body: formDataFetch
            }).unwrap();
            handleClose()
        } catch (error) {
            dispatch(showSnackbar({
                message: 'Ошибка. Проверьте данные.',
                type: 'error',
                position: 'center',
            }));
        }
    };


    const imageSrc = typeof image === 'string' ? image : image instanceof File ? URL.createObjectURL(image) : undefined;

    return {
        name,
        setName: (value: string) => setFormData((prev) => ({...prev, name: value})),
        comment,
        setComment: (value: string) => setFormData((prev) => ({...prev, comment: value})),
        cost,
        setCost: (value: string) => setFormData((prev) => ({...prev, cost: value})),
        isFetching,
        isOpenCreate,
        isOpenEdit,
        handleClose,
        handleSend,
        imageSrc,
        handleImageUpload,
        inputRef,
        image,
        setImage: (value: File | string | '') => setFormData((prev) => ({...prev, image: value})),
        selectedEntityId,
        fetchGiftById,
        data,
    };
}