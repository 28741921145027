import { ISearchOptions } from "../types";

export const getSearchString = (
  text: string,
  currentUserId: string | undefined,
  { isShowBloked, isShowYourself, fields }: ISearchOptions
): string => {
  const additionalFilters: string = String(
    [
      !isShowBloked && `blocked:false`,
      !isShowYourself && currentUserId && `id!:${currentUserId}`,
      fields.length &&
        text &&
        "(" + fields.map((item) => item + ":" + text).join("+") + ")",
    ].filter(Boolean)
  );
  return additionalFilters;
};
