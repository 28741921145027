import React, { FC, ReactElement } from 'react';
import { TemplateView } from '../../../models/profile.models';
import Stack from '@mui/material/Stack';
import { PersonCommunityView } from '../../../models/community.models';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';

type AboutProps = {
  description: string,
  communities: PersonCommunityView[],
  tags: TemplateView[]
}

type AboutPaper = {
  title: string,
  children: ReactElement
}

export const About: FC<AboutProps> = ({ description, communities, tags }) => {
  const theme = useTheme();
  const styleLink = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexWrap: "wrap",
    fontWeight: 600,
    fontSize: '1rem',
    display: "flex",
    gap: '1rem',
  }
  const stylePaper = {
    py: 1,
    px: 8,
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
    minHeight: "100px"
  };


  const aboutInfo: AboutPaper[] = [
    {
      title: "О себе",
      children: <Typography variant="subtitle2" width="100%" sx={styleLink}>{description}</Typography>
    },
    {
      title: "Ко мне можно обратиться за советом",
      children: (
        <Stack width="100%" direction="row" spacing={2}>
          <Typography variant="subtitle2" sx={styleLink} color={theme?.palette?.primary.main}>{tags.map(tag => <span>{tag.name}</span>)}</Typography>
        </Stack>
      )
    },
    {
      title: "Мои группы",
      children: (
        <Stack width="100%" direction="row" spacing={2}>
          <Typography sx={styleLink} color={theme?.palette?.primary.main}>
            {communities?.map(community =>
              <Link variant="subtitle2" href={`/community/${community.id}`}>
              {community.name}
              </Link>)
            }
          </Typography>
        </Stack>
      )
    }
  ]

  const AboutPaper = ({ title, children }: AboutPaper) => {
    return (
        <Paper sx={stylePaper}>
          <Typography color={(theme) => theme?.palette?.primary.main} variant="subtitle1">
            {title}
          </Typography>
          {children}
        </Paper>
    )
  }

  return (
      <Stack spacing={2}>
        {aboutInfo.map(el => <AboutPaper title={el.title} children={el.children} />)}
      </Stack>
  )
}

export default About;
