import React, { FC } from "react";

import {
  FormControl,
  Select,
  MenuItem,
	SelectChangeEvent,
} from "@mui/material";

interface SectionModalProps {
	value: string;
	countsPinnedRecord: number;
  onChange: (e: SelectChangeEvent) => void;
}

export const FeedPinnedRecord: FC<SectionModalProps> = ({
  value = '',
  countsPinnedRecord = 0,
  onChange,
}) => {
  const createSelectionMenu = () => {
    let menuItems = [];
    for (let index = 0; index < (countsPinnedRecord); index++) {
      menuItems[index] = <MenuItem value={String(index + 1)} key={index}>{ index + 1 }</MenuItem>
    };    

    return (
      <FormControl size="small" sx={{ minWidth: 120 }}>
        <Select
          value={value}
          onChange={onChange}
          displayEmpty
          MenuProps={{
            sx: styleMenu
          }}
        >
          <MenuItem value={'null'}>Не закреплять</MenuItem>
          { menuItems.map((el) => el) }
        </Select>
      </FormControl>
    )
  }

  const styleMenu = {
    "& .MuiMenu-paper": {
      backgroundColor: "#212633",
    },
  };

  return (
		createSelectionMenu()
  );
};

export default FeedPinnedRecord;