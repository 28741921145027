import {ResponsiveStyleValue} from "@mui/system";
import {GridDirection} from "@mui/material/Grid/Grid";

export const layoutGridItemsStyle = {
    width: "100%",
    pb: 18,
    height: "calc(100vh - 48px)",
    overflowY: "scroll",
    scrollbarWidth: "none",
    "::-webkit-scrollbar": {
        display: "none",
    },
};

export const gridStyle: ResponsiveStyleValue<GridDirection> = {
    mobile: "column-reverse",
    tablet: "column-reverse",
    laptop: "row",
    desktop: "row",
}