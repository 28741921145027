import React, { FC, useContext, useState } from "react";

import { Stack } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import CustomButton from "../../../../../common/Buttons/CustomButton/CustomButton";
import Delete from "../../../../../../assets/icons/Delete";
import { AppContext } from "../../../../../shared/AppContextProvider";
import IconCustomButton from "../../../../../common/Buttons/IconCustomButton/IconCustomButton";
import CloseButton from "../../../../CloseButton/CloseButton";
import { achievementController } from "../../../../../../services/achievement.controller";
import { AchievContext } from "../../../../AchievContextProvider/AchievContextProvider";
import ConfirmModal from "../../../../../common/Modals/ConfirmModal/ConfirmModal";
import EditableModal from "../../EditableModal/EditableModal";
import { useAppSelector } from "../../../../../../hooks/useAppSelector";

interface TaskProps {
  taskId: string;
  handleClose: () => void;
}

export const ActionButtons: FC<TaskProps> = ({ taskId, handleClose }) => {
  const { isMobile } = useContext(AppContext);
  const { handleSnackbar, handleTasks } = useContext(AchievContext);
  const achievementId = sessionStorage.getItem("achievementId") || "";
  const { blocked } = useAppSelector((state) => state.profileReducer.editProfile);

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const handleConfirmModal = () => {
    setOpenConfirmModal(!openConfirmModal);
  };

  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const handleEditModal = () => {
    setOpenEditModal(!openEditModal);
  };

  const handleDelete = () => {
    achievementController
      .deleteTask(achievementId, taskId)
      .then(() => {
        achievementController
          .tasks(achievementId)
          .then((res) => {
            handleTasks(res.data.items);
            handleClose();
          })
          .catch(() => handleSnackbar("error"));
      })
      .catch(() => handleSnackbar("error"));
  };

  const editButton = isMobile ? (
    <IconCustomButton
      color="secondary"
      icon={<EditOutlinedIcon fontSize="small" />}
      onClick={handleEditModal}
      disabled={blocked}
    />
  ) : (
    <CustomButton
      text="Редактировать"
      color="secondary"
      startIcon={<EditOutlinedIcon />}
      onClick={handleEditModal}
      disabled={blocked}
    />
  );
  const deleteButton = isMobile ? (
    <IconCustomButton
      color="secondary"
      icon={<Delete color="primary" />}
      onClick={handleConfirmModal}
      disabled={blocked}
    />
  ) : (
    <CustomButton
      text="Удалить"
      color="secondary"
      startIcon={<Delete color="primary" />}
      onClick={handleConfirmModal}
      disabled={blocked}
    />
  );

  return (
    <>
      <Stack
        gap={1}
        mb={3}
        flexDirection="row"
        alignItems="center"
        position="relative"
      >
        {editButton}
        {deleteButton}
        {isMobile && (
          <CloseButton
            handleClose={handleClose}
            style={{ position: "absolute", right: 0 }}
          />
        )}
      </Stack>
      <ConfirmModal
        nameConfirmButton="Удалить"
        text="Задача будет удалена из цели."
        handleConfirm={handleDelete}
        openConfirmModal={openConfirmModal}
        handleModal={handleConfirmModal}
      />
      {openEditModal && (
        <EditableModal
          type="edit"
          open={openEditModal}
          handleOpen={handleEditModal}
        />
      )}
    </>
  );
};

export default ActionButtons;
