import React, { FC, useState, useMemo } from "react";

import moment from "moment";

import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Container, InputBase, SelectChangeEvent, Typography } from "@mui/material";

import DatePickerCustom from "../../common/Inputs/DatePickerCustom/DatePickerCustom";
import CustomSelect from "../../common/Inputs/CustomSelect/CustomSelect";

import CalendarTimer from "../CalendarTimer/CalendarTimer";
import EventModalButtons from "../CalendarEventCreator/EventModalButtons/EventModalButtons";
import CheckboxWrapper from "../../common/CheckboxWrapper/CheckboxWrapper";
import { calendarEventRecurrences } from "../../../constants/calendar";
import { useAppSelector } from "../../../hooks/useAppSelector";
import type { CalendarEvent, IEventsState, INewEvent } from "../../../types/CalendarEvent.type";
import { PersonView } from "../../../models/profile.models";
import { PersonsSelect } from "../../PersonsSelect";

import { styles } from "./styles";

type IEvent = INewEvent & Partial<IEventsState>

interface EventModalContentProps {
  eventState: IEvent;
  setEventState: React.Dispatch<React.SetStateAction<IEvent | null>>;
  forRepeatedEvent?: boolean;
}

const maxEventNameLength = 400;
const maxEventDescriptionLength = 400;

const chainEvent = (calendarEvent: IEvent, allEvents: {[key: string]: IEventsState[]}) => {
  if (!calendarEvent.recurrence) {
    const date = moment(calendarEvent.date, "DD-MM-YYYY");
    const periods: (moment.unitOfTime.DurationConstructor | undefined)[] = [undefined, "d", "w", "M", "y"];
    let events: CalendarEvent[];
    for (let i = 1; i < periods.length; i++) {
      events = allEvents[date.clone().add(i, periods[i]).format("DD-MM-YYYY")];
      if (events?.some((event) => event.repeatId === calendarEvent.repeatId)) {
        return calendarEvent.recurrence = i;
      }
    }
  }
};

const findEventRecurrence = (id: number): { id: string; name: string } => {
  const recurrence = calendarEventRecurrences.find((item) => Number(item.id) === id )
  return recurrence ?? calendarEventRecurrences[0]
};

export const EventModalContent: FC<EventModalContentProps> = ({
  eventState,
  setEventState,
  forRepeatedEvent,
}) => {
  const allEvents = useAppSelector(state => state.calendarReducer.allEvents);
  const eventTypes = useAppSelector(state => state.calendarReducer.eventTypes);
  const userProfile = useAppSelector(state => state.profileReducer.profile);
  forRepeatedEvent && chainEvent(eventState, allEvents);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));

  const [checkBoxValue, setCheckboxValue] = useState<boolean>(
    eventState.startTime === "00:00" && eventState.finishTime === "23:59"
  );

  const handleTextInputChange =
    (prop: keyof CalendarEvent) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setEventState({
        ...eventState,
        [prop]: event.target.value,
      });
      eventState[prop] = event.target.value as never;
    };

  const handleDateChange = (newValue: string) => {
    newValue = moment(newValue).format("DD-MM-YYYY");
    setEventState({
      ...eventState,
      date: newValue,
    });
  };

  const handleEventTypeChange = (eventTypeId: string) => {
    const newType = eventTypes.find(({id}) => id === eventTypeId);
    if (!newType) return;
    const newEventInfo: IEvent = {
      ...eventState,
      calendarEventType: newType.calendarEventType,
      eventTypeId: newType.id
    }
    if (newType.tag === "GENERAL") {
      newEventInfo.participantsEvent = [userProfile];
    } else {
      delete newEventInfo.participantsIdEvent;
    }
    setEventState(newEventInfo);
  };

  const handleStartTimeChange = (startTime: string) => {
    setEventState({
      ...eventState,
      startTime: startTime,
    });
  };

  const handleFinishTimeChange = (finishTime: string) => {
    setEventState({
      ...eventState,
      finishTime: finishTime,
    })
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setCheckboxValue(checked);
    setEventState({
      ...eventState,
      startTime: "00:00",
      finishTime: "23:59",
    })
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const newValue = Number(findEventRecurrence(Number(e.target.value)).id);
    setEventState({
      ...eventState,
      recurrence: newValue,
    })
  };

  const handleSelectPersons = (users: PersonView[]) => {
    setEventState({
      ...eventState,
      participantsEvent: [...users],
    });
  };

  const eventRecurrenceId = useMemo(() => (
    findEventRecurrence(eventState.recurrence)?.id
), [eventState.recurrence]);

  return (
    <Box display="flex" flexDirection="column" gap={3} px={{ mobile: "16px", laptop: "32px" }} py="32px">
      <InputBase
        placeholder="Заголовок события"
        sx={{ fontSize: "32px", fontWeight: 700, lineHeight: "40px" }}
        onChange={handleTextInputChange("name")}
        multiline
        maxRows={3}
        value={eventState.name}
        autoFocus={!eventState.name}
        inputProps={{
          maxLength: maxEventNameLength,
          sx: { paddingInlineEnd: "0.5rem" },
        }}
      />
      <EventModalButtons
        stylesProp={styles.buttonsStyle}
        currentValue={eventState.eventTypeId}
        onChange={handleEventTypeChange}
      />
      <Container sx={styles.container}>
        <DatePickerCustom
          value={moment(eventState.date, "DD-MM-YYYY").format()}
          onChange={handleDateChange}
          disableFuture={false}
          styles={{
            mr: "15px",
            pr: "8px",
            ".MuiButtonBase-root.MuiIconButton-root": {
              p: { mobile: "4px", tablet: "8px" },
            },
          }}
        />
        <CheckboxWrapper checked={checkBoxValue} onChange={handleCheckboxChange} />
        <Typography variant="body2" sx={{ minWidth: "fit-content", mr: "22px" }}>
          {"Весь день"}
        </Typography>
        {!isMobile && <CustomSelect
          items={calendarEventRecurrences}
          value={eventRecurrenceId}
          onChange={handleSelectChange}
        />}
      </Container>
      {isMobile && <Box m="-8px 0px 8px">
        <CustomSelect
          items={calendarEventRecurrences}
          value={eventRecurrenceId}
          onChange={handleSelectChange}
        />
      </Box>}
      <CalendarTimer
        handleStartTimeChange={handleStartTimeChange}
        handleFinishTimeChange={handleFinishTimeChange}
        disabled={checkBoxValue}
        startValue={eventState.startTime}
        finishValue={eventState.finishTime}
      />
      <InputBase
        placeholder="Начните вводить описание"
        value={eventState.description}
        multiline
        maxRows={5}
        inputProps={{
          maxLength: maxEventDescriptionLength,
          sx: { paddingInlineEnd: "0.5rem" },
        }}
        onChange={handleTextInputChange("description")}
        sx={styles.input}
      />
      {eventTypes.some(
        ({ id, tag }) => id === eventState.eventTypeId && tag === "GENERAL"
      ) && 
          <Box>
            <Typography variant="h4" minWidth={192} color={"secondary"}>
              Выберите участников
            </Typography>
            <PersonsSelect
              getUsers={handleSelectPersons}
              participants={eventState.participantsEvent ?? []}
            />
          </Box>
      }
    </Box>
  );
};

export default EventModalContent;
