import { useAppSelector } from "../../../hooks/useAppSelector";

export const useCheckSelected = () => {
  const { allEventsQuery } = useAppSelector((state) => state.calendarReducer);

  const isSelectedCheck = (tags: string[]) =>
    Boolean(
      allEventsQuery &&
        "tags" in allEventsQuery &&
        tags.every((item) => allEventsQuery.tags.includes(item))
    );

  return { isSelectedCheck };
};
