import { createApi } from "@reduxjs/toolkit/query/react";

import { apiUrlPortal } from "../constants/apiUrl";
import jwtInterceptor from "./jwtInterceptor";
import { customBaseQuery } from "./customBaseQuery";

interface Department {
  id: string;
  leader: {
    avatar: {
      attachmentType: string;
      extension: string;
      id: string;
      location: string;
      name: string;
      size: number;
    };
    background: {
      attachmentType: string;
      extension: string;
      id: string;
      location: string;
      name: string;
      size: number;
    };
    blocked: boolean;
    city: {
      id: string;
      name: string;
    };
    contact: {
      email: string;
      github: string;
      id: string;
      instagram: string;
      linkedin: string;
      phone: string;
      skype: string;
      telegram: string;
      vk: string;
    };
    description: string;
    dob: string;
    externalUserId: string;
    fired: string;
    gender: string;
    hired: string;
    id: string;
    name: string;
    patronymic: string;
    role: {
      description: string;
      id: string;
      mutable: boolean;
      name: string;
      role: string;
    };
    skills: [
      {
        grade: {
          id: string;
          name: string;
        };
        skill: {
          id: string;
          name: string;
        };
      }
    ];
    surname: string;
  };
  name: string;
}

export const departmentApi = createApi({
  reducerPath: "departmentApi",
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    fetchAllDepartments: builder.query<{ items: Department[] }, void>({
      query: () => ({url: "/departments"}),
    }),
  }),
});

export const { useFetchAllDepartmentsQuery } = departmentApi;

export const departmentController = {
  departments: async (page?: number): Promise<any> => {
    return await jwtInterceptor.get(
      `${apiUrlPortal}/departments?page=${page ?? ""}`
    );
  },
};
