import React, {
	FC,
	createContext,
	useState,
	useRef,
	useEffect,
	Dispatch,
	SetStateAction,
	MutableRefObject,
} from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/useAppSelector";

import useMediaQuery from "@mui/material/useMediaQuery";

import { dataInitial } from "../../../actions/data";
import { ideasController } from "../../../services/ideas.controller";
import { reactionController } from "../../../services/reaction.controller";
import { getReactionSymbolId } from "../../../operation/getReactionSymbolId";
import { filterIdeas } from "../../../operation/filterIdeas";
import Snackbars from "../../common/Snackbars/Snackbars";

import type { IdeaStatus } from "../../../types/IdeaStatus.type";
import type { Idea as IdeaView } from "../../../types/Idea.type";
import type { IdeaStatusView } from "../../../models/ideas.model";
import type { ReactionView } from "../../../models/reaction.model";

export const ideasInitialFetchingStatus: { value: "none" | "single" | "page" } = { value: "page" };

interface IdeasContextProps {
	children: React.ReactNode;
}

interface IdeasContextValue {
	pageSize: number;
	totalPages: MutableRefObject<number>;
	currentPage: number;
	setCurrentPage: Dispatch<SetStateAction<number>>;
	onIdeasError: () => void;
	toggleItems: IdeaStatus[];
	status: IdeaStatus | null;
	setStatus: Dispatch<SetStateAction<IdeaStatus | null>>;
	statuses: IdeaStatusView[];
	getIdeas: (currentPage: number, status: IdeaStatus | null, ideas: IdeaView[], singleIdea?: boolean) => void;
	ideasAreLoading: boolean;
	saveIdeasSessionData: ({ status, totalPages, currentPage }:
		{ status?: IdeaStatus, totalPages?: number, currentPage?: number }) => void;
	totalItems: MutableRefObject<number>;
	handleErrorMessage: (message: string) => void
}

const toggleItems: IdeaStatus[] = ["Актуальные", "Принятые", "Отстойник", "Избранное"];
const statuses: IdeaStatusView[] = ["NEW", "APPROVED", "DISMISSED", "FAVORITE"];

let defaultValue: IdeasContextValue = {
	pageSize: 9,
	totalPages: { current: 0 },
	currentPage: 0,
	setCurrentPage: () => {},
	onIdeasError: () => {},
	toggleItems,
	status: toggleItems[0],
	setStatus: () => {},
	statuses,
	getIdeas: () => {},
	ideasAreLoading: false,
	saveIdeasSessionData: () => {},
	totalItems: { current: 0 },
	handleErrorMessage: () => {},
};
export const IdeasContext = createContext(defaultValue);

export const IdeasContextProvider: FC<IdeasContextProps> = ({
	children,
}) => {
  const dispatch = useDispatch();
	const ideasInfo = JSON.parse(sessionStorage.getItem("ideas") ?? "{}");
	const totalPages = useRef<number>(ideasInfo.totalPages as number ?? 0);
	const totalItems = useRef<number>(ideasInfo.totalItems as number ?? 0);
	const evenCols = useMediaQuery("@media (max-width: 1700px) and (min-width: 1200px), (max-width: 768px)");
  const pageSize = evenCols ? 8 : 9;  // Max number of ideas on a page
	const defaultMessage = 'С Идеями что-то не так, свяжитесь с администратором';

	const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
	const onIdeasError = () => setOpenSnackbar(true);
	const [errorMessage, setErrorMessage] = useState<string>(defaultMessage);

	const [currentPage, setCurrentPage] = useState<number>(
		Number(ideasInitialFetchingStatus.value !== "page" && ideasInfo.currentPage)
	);
	const [status, setStatus] = useState<IdeaStatus | null>(
		toggleItems[statuses.indexOf(ideasInfo.status as IdeaStatusView)] ?? toggleItems[0]
	);

	const ideas: IdeaView[] = useAppSelector(
    (state) => state.dataReducer.ideas
  );
  const [ideasAreLoading, setIdeasAreLoading] = useState<boolean>(ideasInitialFetchingStatus.value !== "none");

	const saveIdeasSessionData = ({ status, totalPages, currentPage, totalItems }:
		{ status?: IdeaStatus, totalPages?: number, currentPage?: number, totalItems?: number }) => {
			const ideasInfo = JSON.parse(sessionStorage.getItem("ideas") ?? "{}");
			sessionStorage.setItem("ideas",
				JSON.stringify({
					status: status ? statuses[toggleItems.indexOf(status)] : ideasInfo.status ?? toggleItems[0],
					totalPages: totalPages ?? ideasInfo.totalPages ?? 0,
					currentPage: currentPage ?? ideasInfo.currentPage ?? 0,
					totalItems: totalItems ?? ideasInfo.totalItems ?? 0,
				})
			);
  };

	const handleErrorMessage = (message: string) => {
		setErrorMessage(message)
	}

	const getIdeas = (page: number, status: IdeaStatus | null, ideas: IdeaView[], singleIdea?: boolean) => {
    setIdeasAreLoading(true);
    ideasController
      .ideas(page, filterIdeas(status), singleIdea ? 1 : pageSize)
      .then((res) => {
        const reactionsPromises = Array(res.data.items.length);
        res.data.items.forEach((idea: IdeaView, index: number) => {
          reactionsPromises[index] = reactionController
            .reactions(idea.reaction.id)
            .then((res) => {
              res.data.items.forEach((reaction: ReactionView) => {
                if (reaction.smile.id === getReactionSymbolId("👍")) {
                  idea.likesCount = reaction.clickCount;
                  idea.likeIsPressed = reaction.pressed;
                }
                if (reaction.smile.id === getReactionSymbolId("👎")) {
                  idea.dislikesCount = reaction.clickCount;
                  idea.dislikeIsPressed = reaction.pressed;
                }
              });
              idea.likesCount ??= 0;
              idea.likeIsPressed ??= false;
              idea.dislikesCount ??= 0;
              idea.dislikeIsPressed ??= false;
            });
        });
        Promise.all(reactionsPromises)
          .then(() => {
            dispatch(dataInitial.ideas([...ideas, ...res.data.items]));
            totalPages.current = Math.floor((res.data.totalItems - 1) / pageSize) + 1;
						totalItems.current = res.data.totalItems;
						saveIdeasSessionData({ totalPages: totalPages.current, totalItems: totalItems.current });
          })
          .catch(() => {
            onIdeasError();
          })
          .finally(() => {
            setIdeasAreLoading(false);
          });
      })
      .catch(() => {
        setIdeasAreLoading(false);
        onIdeasError();
      });
  };

	useEffect(() => {
    switch (ideasInitialFetchingStatus.value) {
			case "page":
				getIdeas(0, status, []);
				saveIdeasSessionData({ currentPage: 0 });
				return;
			case "single":
				getIdeas(ideas.length, status, ideas, true);
				ideasInitialFetchingStatus.value = "page";
				return;
			default:
				ideasInitialFetchingStatus.value = "page";
				return;
		}
    /* eslint-disable */
  }, []);

	return (
		<IdeasContext.Provider value={{
			pageSize,
			totalPages,
			currentPage,
			setCurrentPage,
			onIdeasError,
			toggleItems,
			status,
			setStatus,
			statuses,
			getIdeas,
			ideasAreLoading,
			saveIdeasSessionData,
			totalItems,
			handleErrorMessage
		}}>
			{children}
			<Snackbars
				open={openSnackbar}
				setOpen={(value: boolean) => {setOpenSnackbar(value); handleErrorMessage(defaultMessage)}}
				type="error"
				position="center"
				message={errorMessage}
			/>
		</IdeasContext.Provider>
	);
};

export default IdeasContextProvider;