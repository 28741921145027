import React, { FC } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SystemStyleObject, Theme } from '@mui/system';

interface DialogWrapperProp {
  openModal: boolean;
  handleModal: () => void;
  children: React.ReactNode;
  buttons?: React.ReactNode;
  width?: string;
  height?: string;
  contentDividers?: boolean;
  pActions?: string;
  styles?: SystemStyleObject<Theme>;
  stylesContent?: SystemStyleObject<Theme>;
  styleActionButtons?: SystemStyleObject<Theme>;
  header?: React.ReactNode;
}

export const DialogWrapper: FC<DialogWrapperProp> = ({
  openModal,
  handleModal,
  children,
  buttons,
  width,
  height,
  contentDividers,
  pActions,
  styles,
  stylesContent,
  header,
  styleActionButtons,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('laptop'));

  const styleDialog: SystemStyleObject<Theme> = {
    '& .MuiDialog-paper': { width, height },
    ...styles,
  };

  const styleContent: SystemStyleObject<Theme> = {
    p: isMobile ? '32px 16px' : '36px 32px',
    ...stylesContent,
  };

  const styleActions: SystemStyleObject<Theme> = {
    p: pActions ? pActions : isMobile ? '16px' : '16px 32px',
    display: 'flex',
    flexDirection: 'row',
    "& *": { display: "flex", flexDirection: "row" },
    ...styleActionButtons,
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleModal}
      aria-labelledby="dialog"
      aria-describedby="dialog-open"
      fullScreen={isMobile}
      sx={styleDialog}
      closeAfterTransition={false}
    >
      {header && <DialogTitle>{header}</DialogTitle>}
      <DialogContent sx={styleContent} dividers={contentDividers}>
        {children}
      </DialogContent>
      {buttons && <DialogActions sx={styleActions}>{buttons}</DialogActions>}
    </Dialog>
  );
};

export default DialogWrapper;
