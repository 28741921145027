import React, { FC } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import StarIcon from '@mui/icons-material/Star';

import StoryGroupWrapper from "../StoryGroupWrapper/StoryGroupWrapper";

interface UserStoryGroupProps {
  isPinned: boolean;
  title: string;
  backgroundUrl: any;
  onClick?: () => void;
}
export const UserStoryGroup: FC<UserStoryGroupProps> = ({
  isPinned,
  title,
  backgroundUrl,
  onClick,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const styleContainer = {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    height: "100%",
    px: `${isMobile ? "12px" : "24px"}`,
    pb: "18px",
    backgroundImage: `linear-gradient(0deg, rgba(33, 38, 51, 0.2), rgba(33, 38, 51, 0.2)), linear-gradient(180deg, rgba(33, 38, 51, 0.15) 69.27%, #212633 100%), url("${backgroundUrl}")`,
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundClip: "border-box",
    borderRadius: "12px",
  };
  return (
    <StoryGroupWrapper colorBorder="primary" onClick={onClick}>
      {isPinned &&
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '4rem',
            top: 0, // Укажите положение (например, сверху)
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <StarIcon color="disabled"/>
        </Box>
      }
      <Box sx={styleContainer}>
        <Typography
          variant={isMobile ? "caption" : "subtitle1"}
          align="center"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {title}
        </Typography>
      </Box>
    </StoryGroupWrapper>
  );
};

export default UserStoryGroup;
