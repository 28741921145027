import React, { FC, useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { AxiosError } from 'axios'
import CircularProgress from '@mui/material/CircularProgress'
import { Password } from './Password/Password'
import { Email } from './Email/Email'
import { LoginError } from './LoginError/LoginError'
import LoginPageWrapper from '../common/LoginPageWrapper/LoginPageWrapper'
import { AppContext } from '../shared/AppContextProvider'

import CustomButton from '../common/Buttons/CustomButton/CustomButton'
import { textSnackbars } from '../../constants/snackBarNotifications'

export const LoginPage: FC = () => {
  const { passLogin, linkLogin } = useContext(AppContext)

  const navigate = useNavigate()
  const goToNew = () => {
    navigate('/login/new')
  }

  const [passwordValue, setPasswordValue] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [emailValue, setEmailValue] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleLoginError = (err: AxiosError<{errorMessage: string}>) => {
    const errorMessage = err?.response?.data?.errorMessage;
    if (!errorMessage || err?.response?.status === 500) {
      setError(textSnackbars.default)
    } else {
      setError(errorMessage)
    }
  }

  const handlePassLogin = async () => {
    setIsLoading(true)
    const response = await passLogin(emailValue, passwordValue);
    if (response) handleLoginError(response);
    setIsLoading(false);
  }

  const handleLinkLogin = (loginCode: string) => {
    linkLogin(loginCode)
      .then(() => setIsLoading(false))
      .catch((err) => {
        handleLoginError(err)
        window.history.replaceState(null, '', '/login')
      })
    setIsLoading(true)
  }
  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handlePassLogin();
  }

  const loginCode = useLocation().pathname.split('/login_link/')[1]
  useEffect(() => {
    loginCode && handleLinkLogin(loginCode)
  }, [loginCode]) // eslint-disable-line

  const buttonStyle = {
    typography: 'button',
    height: '48px',
    borderRadius: '60px',
    padding: '4px 24px',
  }

  return (
    <LoginPageWrapper handleSubmit={handleFormSubmit}>
      <Typography variant='h4' component='h4' textAlign='center'>
        Вход в корпоративный портал
      </Typography>
      {error && <LoginError label={error} />}
      <Email value={emailValue} setValue={setEmailValue} />
      <Password
        label='Пароль'
        value={passwordValue}
        setValue={setPasswordValue}
        showPassword={showPassword}
        setShowPassword={setShowPassword}
        withHelpText
      />
      {isLoading ? (
        <CircularProgress color='inherit' sx={{ alignSelf: 'center' }} size='48px' />
      ) : (
        <Button variant='contained' fullWidth sx={buttonStyle} onClick={handlePassLogin} type="submit">
          Вход
        </Button>
      )}
      <CustomButton onClick={goToNew} color='secondary' text='Новый пользователь' fullWidth styles={buttonStyle} />
    </LoginPageWrapper>
  )
}

export default LoginPage
