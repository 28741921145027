import React, { FC } from "react";
import { Box, BoxProps, ToggleButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";

import { changeTagsQuery } from "../../../reducer/calendar";
import { useCheckSelected } from "../hooks";

import { styles } from "./styles";

const tags: string[] = ["PRODUCTION"];

export const WorkCalendarToggle: FC<BoxProps> = ({ ...rest }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { isSelectedCheck } = useCheckSelected();

  return (
    <Box {...rest}>
      <ToggleButton
        sx={styles.button(theme)}
        value={["PRODUCTION"]}
        selected={isSelectedCheck(["PRODUCTION"])}
        onChange={() => dispatch(changeTagsQuery(tags))}
      >
        Производственный календарь
      </ToggleButton>
    </Box>
  );
};
