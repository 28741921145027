export const getCommentCountText = (commentCount: number): string => {
  if (!commentCount) return 'Оставить комментарий';
  const pluralRule = new Intl.PluralRules('ru');
  const rule = pluralRule.select(commentCount);
  if (rule === 'one') return `${commentCount} комментарий`;
  if (rule === 'few') return `${commentCount} комментария`;
  if (rule === 'many') return `${commentCount} комментариев`;
  if (rule === 'other') return `${commentCount} комментариев`;
  return `${commentCount} комментариев`;
};
