import React from 'react';
import {ReviewPageContextProvider} from "../RewievContextProvider/ReviewPageContextProvider";
import {ReviewPage} from "./ReviewPage";

export const ReviewPageWrapper = () => {
    return (
        <ReviewPageContextProvider>
            <ReviewPage/>
        </ReviewPageContextProvider>
    );
};