import { useState, useEffect } from "react";

import { departmentController } from "../services/department.controller";
import { TemplateView } from "../models/profile.models";
import { ListItem } from '../types/ListItem.js';

export const useDepartments = (page?: number) => {
  const [departments, setDepartments] = useState<ListItem[]>([]);
  const [size, setSize] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (loading) {
      return;
    }

    setLoading(true);

    departmentController.departments(page).then((res) => {
      const allDepartments = res.data.items.map(
        (item: TemplateView) => ({
          id: item.id,
          name: item.name,
        })
      );
      setDepartments([...departments, ...allDepartments]);
      setSize(res.data.totalItems);
    }).finally(() => {
      setLoading(false);
    });
    /* eslint-disable-next-line */
  }, [page]);

  return { departments, setDepartments, size, setSize, loading };
};
