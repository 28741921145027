import React, { FC, HTMLAttributes } from 'react';

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

interface InputWrapperProps extends HTMLAttributes<HTMLDivElement>{
  title?: string;
  element?: React.ReactElement;
  flexDirection?: "column" | "row";
  titleColor?: "primary" | "secondary";
  minWidth?: number;
  styles?: any;
  nameAlign?: 'left' | 'right'
}
export const InputWrapper: FC<InputWrapperProps> = ({
  title,
  element,
  flexDirection = "row",
  titleColor = "primary",
  minWidth = 192,
  styles,
  nameAlign = 'left',
  children
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const getGap = () => {
    if (isMobile) return '8px'
    if (nameAlign === 'right') return "48px"
    return '12px'
  }

  const styleFlex = {
    display: "flex",
    alignItems: "baseline",
    gap: getGap(),
    flexDirection: isMobile ? "column" : flexDirection,
    textAlign: nameAlign,
    ...styles,
  };

  return (
    <Box sx={styleFlex}>
      <Typography
        variant="body1"
        minWidth={minWidth}
        color={(theme) =>
          titleColor === "secondary"
            ? theme.palette.text.secondary
            : theme.palette.text.primary
        }
      >
        {title}
      </Typography>
      {children || element}
    </Box>
  );
};

export default InputWrapper;
