import {AchievementView} from "./achievement.models";
import {AttachmentView} from "./attachment.model";
import {PersonCommunityView} from "./community.models";

// Расширяемый тип на будущее
export type TemplateView = {
    id: string;
    name: string;
};

export type JobView = {
    department: TemplateView;
    grade: TemplateView;
    id: string;
    title: TemplateView;
};

export type ContactView = {
    email: string;
    github: string;
    id: string;
    instagram: string;
    linkedin: string;
    phone: string;
    skype: string;
    telegram: string;
    vk: string;
};

export enum ERole {
    ADMIN = 'ADMIN',
    EDITOR = 'EDITOR',
    USER = 'USER'
}

export type SecurityRoleView = { role: keyof typeof ERole};

export type PermissionView = {
  id: string,
  description: string,
  type: string
}

export type ProjectsView = {
    id: string;
    isExternal: boolean;
    name: string;
};

export type PersonSkillView = {
    grade: TemplateView;
    skill: TemplateView
}

export type RemoteWorkStatuses = 'REMOTE_WORK' | 'REMOTE_WORK_PERMANENT' | 'NOT_REMOTE'

export type RemoteWorkView = {
    name: RemoteWorkStatuses,
    remoteWorkTo: string | null,
    remoteWorkFrom: string | null
}

export type PersonView = {
    remoteWorkFrom?: string,
    achievements?: AchievementView[];
    avatar?: AttachmentView | null;
    background?: AttachmentView | null;
    blocked: boolean;
    communities: PersonCommunityView[],
    city: TemplateView;
    contact: ContactView;
    description: string;
    dob: string;
    fired: string | null;
    gender: string;
    hired: string;
    id: string;
    remoteWorkStatus: RemoteWorkView;
    job: JobView;
    projects: ProjectsView[];
    name: string;
    patronymic: string;
    role: SecurityRoleView;
    surname: string;
    skills: PersonSkillView[];
    status: TemplateView;
    tags: TemplateView[];
    managedPermissions: PermissionView[]
    balance: number
};


export type SelectedPerson = PersonView & {
    isChecked: boolean
}
