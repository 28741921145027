import jwtInterceptor from './jwtInterceptor'
import { apiUrlPortal } from '../constants/apiUrl'
import { CreateReviewRequest, EditReviewRequest, ReviewList } from '../models/review.models'
import { reviewPageSize } from '../constants/review'

type BaseResponse<T> = {
  data: T
  status: number
  statusText: string
}

type SType = {page: number, size?: number, search?: string, sort: string}

export const reviewController = {
  createReview: async (body: CreateReviewRequest): Promise<BaseResponse<ReviewList>> => {
    return await jwtInterceptor.post(`${apiUrlPortal}/review/person/review`, body)
  },
  getReviewByID: async (reviewId: string): Promise<any> => {
    return await jwtInterceptor.get(`${apiUrlPortal}/review/person/review/${reviewId}`)
  },
  editReview: async (reviewId: string, body: EditReviewRequest): Promise<any> => {
    return await jwtInterceptor.put(`${apiUrlPortal}/review/person/review/${reviewId}`, body)
  },
  deleteReview: async (reviewId: string): Promise<any> => {
    return await jwtInterceptor.delete(`${apiUrlPortal}/review/person/review/${reviewId}`)
  },
  setEstimation: async (reviewId: string, body: { rating: number }): Promise<any> => {
    return await jwtInterceptor.post(`${apiUrlPortal}/review/person/review/${reviewId}/rating`, body)
  },
  getAllOwnerReview: async (
    personId: string,
    page?: number,
    size?: number,
  ): Promise<any> => {
    return await jwtInterceptor.get(
      `${apiUrlPortal}/review/person/${personId}/owner/review?page=${(page ?? '')}&size=${(size ?? reviewPageSize)}`
    )
  },
  getAllRelativeReview: async (personId: string): Promise<any> => {
    return await jwtInterceptor.get(`${apiUrlPortal}/review/person/${personId}/reviewer/review`)
  },
  getAllEstimations: async (reviewId: string): Promise<any> => {
    return await jwtInterceptor.get(`${apiUrlPortal}/review/person/review/${reviewId}/rating`)
  },
  getAllReview: async ({page, size, search, sort = 'id,desc'}:SType): Promise<any> => {
    let url = `${apiUrlPortal}/review?page=${page}&size=${size ?? reviewPageSize}&sort=${sort}`;
    if (search) {
      url += `&search=${encodeURIComponent(`owner.name:${search}+owner.surname:${search}`)}`;
    }
    return await jwtInterceptor.get(url);
  },
  getAllArchiveReview: async ({page, size, search, sort = 'id,desc'}:SType): Promise<any> => {
    let url = `${apiUrlPortal}/review/archive?page=${page}&size=${(size ?? reviewPageSize)}&sort=${sort}`
    if (search) {
      url += `&search=${encodeURIComponent(`owner.name:${search}+owner.surname:${search}`)}`;
    }
    return await jwtInterceptor.get(url);
  },
  archiveReview: async (reviewId: string): Promise<any> => {
    return await jwtInterceptor.post(`${apiUrlPortal}/review/${reviewId}/archive`)
  },
  unArchiveReview: async (reviewId: string, body:any): Promise<any> => {
    return await jwtInterceptor.post(`${apiUrlPortal}/review/${reviewId}/unarchive`, body)
  },
  sendNoticeAll: async (reviewId: string, body:{description:string}): Promise<any> => {
    return await jwtInterceptor.post(`${apiUrlPortal}/review/person/review/${reviewId}/notification-all`, body)
  },
  sendNoticeShelvedPerson: async (reviewId: string, body:{description:string}): Promise<any> => {
    return await jwtInterceptor.post(`${apiUrlPortal}/review/person/review/${reviewId}/notification-all-without-vote`, body)
  },
  sendNoticeReviewedPerson: async (reviewId: string, body:{description:string}): Promise<any> => {
    return await jwtInterceptor.post(`${apiUrlPortal}/review/person/review/${reviewId}/notification-for-owner`, body)
  },
}
