import React, { FC, HTMLAttributes } from 'react';
import { StoryGroupView } from '../../../../models/story.model';
import CelebrationStoryGroup from '../CelebrationStoryGroup/CelebrationStoryGroup';
import UserStoryGroup from '../UserStoryGroup/UserStoryGroup';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  item: StoryGroupView
  handleClickStoryGroup: (item: StoryGroupView) => void
}

const GroupStory: FC<IProps> = ({ item, handleClickStoryGroup }) => {
  if (item.groupType === "BIRTHDAY") {
    return (
      <CelebrationStoryGroup
        type="birthday"
        storyGroup={item}
        onClick={() => {
          handleClickStoryGroup(item);
        }}
      />
    );
  }
  if (item.groupType === "WELCOME") {
    return (
      <CelebrationStoryGroup
        type="welcome"
        storyGroup={item}
        onClick={() => handleClickStoryGroup(item)}
      />
    );
  }

  return (
    <UserStoryGroup
      isPinned={item?.pinned}
      title={item.firstStories[0]?.header}
      backgroundUrl={`/loyalty-portal/api/attachments/${
        encodeURIComponent(item.firstStories[0]?.attachments[0]?.name) +
        item.firstStories[0]?.attachments[0]?.extension
      }`}
      onClick={() => handleClickStoryGroup(item)}
    />
  );
};

export default GroupStory;