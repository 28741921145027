export const styleBoxWrapper = {
    display: "flex", flexDirection: "column", gap: '8px',
}

export const styleBox = {
    borderRadius: "12px",
    border: "1px solid rgba(241, 247, 255, 0.12)",
    p: 4,
    display: "flex",
    flexDirection: "column",
    gap: 2.4,
    alignItems: "center",
}
