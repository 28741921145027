import React, {useContext, useEffect, useRef} from "react";
import {useLocation} from "react-router";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import LeftSidebar from "../LeftSidebar/LeftSidebar";
import RightSidebar from "../RightSidebar/RightSidebar";
import Header from "../mobile/Header/Header";
import Navigation from "../Navigation/Navigation";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import {AppContext} from "../../shared/AppContextProvider";
import useWebSocket from "../../../hooks/useWebSocket";
import {useDispatch} from "react-redux";
import {socketsInitial} from "../../../actions/sockets";
import Routes from "../../../routes/Routes";
import {gridStyle, layoutGridItemsStyle} from "./styles/pageLayoutStyles";


export const PageLayout = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));
    const location = useLocation();
    const {user} = useContext(AppContext);

    const passwordPage =
        location.pathname === "/login" ||
        location.pathname.includes("/reset_password");

    const data = useWebSocket();

    const scrollRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
        dispatch(socketsInitial.loggedUsers(data ?? ""));
        /* eslint-disable */
    }, [data]);

    return (
        <Box
            sx={{flexGrow: 1}}
            m={{mobile: 0, tablet: 0, laptop: 3, desktop: 3}}
        >
            <Grid
                container
                direction={gridStyle}
                columnSpacing={!isMobile ? {laptop: 12, desktop: 36} : undefined}
                rowSpacing={isMobile ? {mobile: 3, tablet: 3} : undefined}
                justifyContent={passwordPage ? "center" : undefined}
                wrap="nowrap"
            >
                {user && (
                    <Grid data-testid="leftSidebar" item laptop={3}>
                        {isMobile ? (
                            <Navigation isMobile/>
                        ) : (
                            <LeftSidebar style={layoutGridItemsStyle}/>
                        )}
                    </Grid>
                )}
                <Grid
                    item
                    laptop={6}
                    sx={{maxWidth: !isMobile ? "50% !important" : "none"}}
                >
                    <Box
                        sx={isMobile ? {pb: 18} : layoutGridItemsStyle}
                        ref={scrollRef}
                        id="main-scroll"
                    >
                        <ScrollToTop scrollRef={scrollRef}>
                          <Routes/>
                        </ScrollToTop>
                    </Box>
                </Grid>
                {user && (
                    <Grid item laptop={3}>
                        {isMobile ? (
                            <Header/>
                        ) : (
                            <RightSidebar style={layoutGridItemsStyle}/>
                        )}
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default PageLayout;
