import { Theme } from "@mui/material";
import { SystemStyleObject } from "@mui/system";

export const styleGroup = (theme: Theme): SystemStyleObject<Theme> => ({
  gap: "8px",
  "& .MuiToggleButtonGroup-grouped": {
    border: 0,
    "&.MuiToggleButton-root": {
      borderRadius: "60px",
      backgroundColor: "rgba(203, 225, 255, 0.17)",
      fontSize: "14px",
      lineHeight: "20px",
      color: theme.palette.text.secondary,
      padding: "10px 12px",
    },
    "&.MuiToggleButton-root:hover": {
      backgroundColor: "rgba(185, 215, 255, 0.08)",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      color: theme.palette.text.primary,
      backgroundColor: "#1884FF",
    },
    "&.MuiToggleButton-root.Mui-disabled": {
      border: 0,
    },
  },
  ".MuiTabs-indicator": {
    height: "0px",
  },
});
