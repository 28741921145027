import React, { FC } from "react";

import Badge from "@mui/material/Badge";

import { theme } from "../../../../../constants/theme";

interface ActiveAvatarProps {
  avatar: React.ReactNode;
}

export const ActiveAvatar: FC<ActiveAvatarProps> = ({ avatar }) => {
  const activeBadgeStyle = {
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      borderRadius: "100%",
      boxShadow: `0 0 0 2px ${theme?.palette?.background?.default}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "100%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  };

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      variant="dot"
      sx={activeBadgeStyle}
    >
      {avatar}
    </Badge>
  );
};

export default ActiveAvatar;
