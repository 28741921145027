import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import celebrate from '../../../../../../assets/images/celebrate.png';
import gifts from '../../../../../../assets/images/gifts.png';
import { PersonView } from '../../../../../../models/profile.models';
import { GroupStoriesType } from '../../../../../../models/story.model';
import Confetti from '../../../../../common/Confetti/Confetti';
import UserAvatar from '../../../../../common/User/UserAvatar/UserAvatar';
import { styles } from './styles';

interface StoryContentProps {
  author?: PersonView;
  header: string;
  content: string;
  groupType?: GroupStoriesType;
}

export const StoryContent: FC<StoryContentProps> = ({ author, header, content, groupType }) => {
  return (
    <>
      {author && <UserAvatar userInfo={author} styles={{ width: '70px', height: '70px' }} />}
      <Typography
        variant="h4"
        color={(theme) => theme?.palette?.text?.primary}
        align={author ? 'center' : undefined}
        sx={styles.text}
      >
        {header}
      </Typography>
      <Typography variant="body2" color={(theme) => theme?.palette?.text?.primary} sx={styles.text}>
        {content}
      </Typography>
      {groupType === 'BIRTHDAY' && <Box sx={styles.styleImgGifts(gifts)} />}
      {groupType === 'WELCOME' && (
        <>
          <Box sx={styles.styleImgCelebrate(celebrate)} />
          <Box sx={[styles.styleImgCelebrate(celebrate), styles.styleImgCelebrateAdd]} />
        </>
      )}
      {groupType !== 'ADMIN' && <Confetti />}
    </>
  );
};

export default StoryContent;
