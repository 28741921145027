import React, { FC } from 'react';

import InputBase from '@mui/material/InputBase';
import { useTheme } from '@mui/material/styles';

interface InputFilledProps extends React.InputHTMLAttributes<HTMLInputElement> {
    testid?: string;
    value?: any;
    placeholder?: string;
    multiline?: boolean;
    handleValue?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    margin?: string | number;
    minRows?: number;
    maxRows?: number;
}

//TODO: required field
export const InputFilled: FC<InputFilledProps> = ({
  testid,
  value,
  placeholder,
  multiline = false,
  handleValue,
  margin,
  minRows = 4,
  maxRows = 4,
  disabled
}) => {
  const theme = useTheme();
  const styleInput = {
    bgcolor: theme.palette.background.paper,
    borderRadius: '12px',
    p: '12px 16px',
    m: margin,
    fontSize: '16px',
    lineHeight: '24px',
  };
  return (
    <InputBase
      data-testid={testid}
      fullWidth
      multiline={multiline}
      placeholder={placeholder}
      onChange={handleValue}
      value={value}
      sx={styleInput}
      minRows={minRows}
      maxRows={maxRows}
      disabled={disabled}
    />
  );
};

export default InputFilled;
