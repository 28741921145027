import React, {FC, ReactNode} from 'react';
import Box from '@mui/material/Box';
import {managementTable} from './styles/managementTable';
import HeaderTable, {TColumn} from './HeaderTable/HeaderTable';
import {SearchFilter} from './SearchFilter/SearchFilter';

interface IManagementTableProps  {
    columns: TColumn[];
    children: ReactNode;
}
export const ManagementTable: FC<IManagementTableProps> = ({columns, children}) => {
    return (
        <Box my={2} sx={managementTable.container}>
            <HeaderTable columns={columns}/>
            <SearchFilter/>
            <Box my={4}>
                {children}
            </Box>
        </Box>
    );
};

export default ManagementTable;