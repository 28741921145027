import React, { FC, useState, useEffect } from "react";
import { KbArticleListView } from "../../../../../models/kb.models";
import { SublistArticles } from "./SublistArticles";
import { ItemArticles } from "./ItemArticles"
import { kbController } from "../../../../../services/kb.controller";

import { 
  List,
} from '@mui/material';
import Snackbars from "../../../../common/Snackbars/Snackbars";

interface ListArticlesProps {
  articles: KbArticleListView[] | undefined;
  nestingLevel?: string;
  updSubArticle?: () => void;
}

export const ListArticles: FC<ListArticlesProps> = ({
  articles,
  nestingLevel = undefined,
  updSubArticle = () => {},
}) => {  
  const [messageSnackbar, setMessageSnackbar] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [curArticles, setCurArticles] = useState<KbArticleListView[] | undefined>(articles)

  const curNestingLevel = (sequenceNumber: number) => {		
    return (nestingLevel ? 
      nestingLevel + sequenceNumber + '.'
    :
      String(sequenceNumber) + '.')
  }

  const updArticles = (article: KbArticleListView, message: string | null = null) => {    
    if (message) {
      setMessageSnackbar(message)
      setOpenSnackbar(true)
    };
    
    if (article.parentArticleId) {
      updSubArticle()
    } else {
      kbController
      .articles(article.sectionId, null)
      .then((res) => {
        setCurArticles(res.data.items)
      })
    }       
  }

  useEffect(() => {
    setCurArticles(articles)
  }, [articles]);

  return (
    <>
      <List>
        {curArticles?.map((article) => (
          article.isGroupArticle ? 
            <SublistArticles
              parent={article}
              key={article.id}
              parentNestingLevel={curNestingLevel(article.sequenceNumber)}
              updArticles={updArticles}
            />
          : 
            <ItemArticles 
              article={article}
              key={article.id}
              nestingLevel={curNestingLevel(article.sequenceNumber)}
              updArticles={updArticles}
            />
        ))}
      </List>

      <Snackbars
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        type="success"
        position="center"
        message={messageSnackbar}
      />
    </>
  );
};

export default ListArticles;