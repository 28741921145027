import React, { useState } from "react";
import { Modal, Box, Button, TextField, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';

interface IProps {
  open: boolean;
  onClose: () => void;
  onCreate: (value: string) => void;
}

const NewTagForm: React.FC<IProps> = ({ open, onClose, onCreate }) => {
  const theme = useTheme()
  const [inputValue, setInputValue] = useState<string>("");

  const handleCreate = () => {
    if (inputValue.trim()) {
      onCreate(inputValue);
      setInputValue(""); // Очистка инпута после создания
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: theme.palette.background.default,
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          opacity: 1
        }}
      >
        <Typography variant="h6" gutterBottom>
          Введите данные
        </Typography>
        <TextField
          fullWidth
          label="Название"
          variant="outlined"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button onClick={onClose} sx={{ mr: 1 }} variant="outlined">
            Отмена
          </Button>
          <Button onClick={handleCreate} variant="contained" disabled={!inputValue.trim()}>
            Создать
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default NewTagForm;
