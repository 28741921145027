export const generateHashtag = (text: string): string => {
  if (!text.trim()) return ""; // Если строка пустая, возвращаем ""

  // Удаляем все решетки из текста
  const cleanText = text.replace(/#/g, "").trim();

  if (!cleanText) return ""; // Если после очистки ничего не осталось, возвращаем ""

  return (
    "#" +
    cleanText
      .split(/\s+/) // Разбиваем по пробелам
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Делаем каждое слово с заглавной буквы
      .join("")
  );
};