export type Role = typeof roles[keyof typeof roles]

//NOTE: portal user roles
const admin = "ADMIN";
const editor = "EDITOR";
const user = "USER";

export const roles = { admin, editor, user } as const;

//NOTE: group member roles
const groupAdmin = "ADMIN";
const member = "MEMBER";
const owner = "OWNER";

export const memberGroupRoles = { groupAdmin, member, owner } as const;
