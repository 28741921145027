import {useCallback, useEffect, useState} from 'react';

import {useAppDispatch} from '../../../../../../hooks/useAppDispatch';
import {useAppSelector} from '../../../../../../hooks/useAppSelector';
import {
    useCreateActivityMutation,
    useEditActivityMutation,
    useLazyGetActivityByIDQuery
} from '../../../../../../services/activity.controller';
import {toggleModal} from '../../../../../../reducer/bonusesModalSlice';
import {showSnackbar} from '../../../../../../reducer/snackbarSlice';

export const useActivityModal = () => {
    const {selectedEntityId} = useAppSelector((state) => state.bonusesModal);
    const dispatch = useAppDispatch();
    const [fetchActivityByID, {data, isFetching}] = useLazyGetActivityByIDQuery();
    const [name, setName] = useState<string>('');
    const [comment, setComment] = useState<string>('');
    const [cost, setCost] = useState<string>('');
    const {isOpenView, modalType,} = useAppSelector((state) => state.bonusesModal);
    const isOpenCreate = isOpenView && modalType === 'createActivity';
    const isOpenEdit = isOpenView && modalType === 'editeActivity';
    const [create] = useCreateActivityMutation();
    const [edit] = useEditActivityMutation();

    const handleClose = useCallback(() => {
        dispatch(toggleModal({type: null, isOpenView: false, id: ''}));
    }, [dispatch]);

    useEffect(() => {
        if (isOpenEdit && selectedEntityId) {
            fetchActivityByID(selectedEntityId).unwrap()
                .then((response) => {
                    setCost(`${response.cost}`)
                    setName(response.title)
                    setComment(response.description)
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenEdit, selectedEntityId]);

    useEffect(() => {
        if (data && isOpenEdit) {
            setName(data.title);
            setCost(`${data.cost}`);
            setComment(data.description);
        }
    }, [data, isOpenEdit]);

    const handleSend = useCallback(async () => {
        const requestData = {
            cost: Number(cost) || 0,
            description: comment.trim(),
            title: name.trim(),
        };
        try {
            if (isOpenEdit) {
                await edit({id: selectedEntityId, body: requestData}).unwrap();
            } else {
                await create(requestData).unwrap();
            }
            handleClose();
        } catch (err) {
            dispatch(showSnackbar({
                message: 'Ошибка. Проверьте данные.',
                type: 'error',
                position: 'center',
            }));
        }

    }, [isOpenEdit, selectedEntityId, name, comment, cost, create, edit, handleClose]);

    return {
        name,
        setName,
        comment,
        setComment,
        cost,
        setCost,
        isFetching,
        isOpenCreate,
        isOpenEdit,
        handleClose,
        handleSend,
    };
}