import React, {FC, useCallback, useEffect, useState} from 'react';
import coins from '../../../assets/images/Coins.png';
import {heroSection} from './styles/heroSection';
import {Grid, Box, Stack, Typography} from '@mui/material';
import {HeroButton} from './HeroButton/HeroButton';
import FormCreateActivity from './FormCreateActivity/FormCreateActivity';
import FormChangeBonuses from './FormChangeBonuses/FormChangeBonuses';
import {useAppSelector} from '../../../hooks/useAppSelector';
import {getPointsText} from '../helpers/getPointsText';
import {useFileDownloader} from '../helpers/useFileDownloader';
import {useHeroButtons} from '../helpers/useHeroButtons';
import {LoadProgress} from '../../common/LoadProgress/LoadProgress';

export const HeroSection: FC = () => {
    const [isImageLoading, setIsImageLoading] = useState<boolean>(true);
    const checkImageLoaded = () => {
        const img = new Image();
        img.src = coins;
        img.onload = () => setIsImageLoading(false);
    };
    const [modals, setModals] = useState<{ [key: string]: boolean }>({
        actives: false,
        gift: false,
    });

    const toggleModal = useCallback((modal: 'actives' | 'gift', state: boolean) => () =>
        setModals((prev) => ({...prev, [modal]: state})), []);

    const downloadFile = useFileDownloader();
    const buttons = useHeroButtons(toggleModal, downloadFile);
    const currentUser = useAppSelector((state) => state.profileReducer.profile);

    useEffect(() => {
        checkImageLoaded();
    }, []);

    return (
        <Grid>
            <Typography sx={heroSection.title}>Бонусная программа</Typography>
            {isImageLoading ? (
                <LoadProgress/>
            ) : <Stack direction="row" spacing={2} sx={heroSection.balances}>
                <Box>
                    <Typography sx={heroSection.balance}>Баланс</Typography>
                    <Typography
                        sx={heroSection.counters}>{currentUser?.balance} {getPointsText(currentUser?.balance)}</Typography>
                </Box>
                <Box sx={heroSection.image}>
                    <img
                        key={coins}
                        src={coins}
                        alt="coins"
                        width={'100%'}
                    />
                </Box>
            </Stack>}
            <Grid container rowSpacing={1} columnSpacing={1} wrap={'wrap'}>
                {buttons.map(({title, desc, icon, width, onClick}) => (
                    <HeroButton
                        key={title}
                        title={title}
                        desc={desc}
                        icon={icon}
                        width={width}
                        onClick={onClick}
                    />
                ))}
            </Grid>
            <FormCreateActivity
                toggleModal={toggleModal}
                openModal={modals.actives}
            />
            <FormChangeBonuses
                toggleModal={toggleModal}
                openModal={modals.gift}
            />
        </Grid>
    );
};

export default HeroSection;
