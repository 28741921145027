import React, {FC, memo} from 'react';
import Box from '@mui/material/Box';
import DialogWrapper from '../../../../common/Modals/DialogWrapper/DialogWrapper';
import InputLine from '../../../../common/Inputs/InputLine/InputLine';
import Typography from '@mui/material/Typography';
import ModalButtons from '../../../../common/Buttons/ModalButtons/ModalButtons';
import InputFilled from '../../../../common/Inputs/InputFilled/InputFilled';
import {LoadProgress} from '../../../../common/LoadProgress/LoadProgress';
import {useActivityModal} from './helpers/useActivityModal';

export const CreateActivity: FC = () => {
    const {
        name,
        setName,
        comment,
        setComment,
        cost,
        setCost,
        isFetching,
        isOpenCreate,
        isOpenEdit,
        handleClose,
        handleSend,
    } = useActivityModal();

    const isDisabled = !name || !comment;
    const buttons = (
        <ModalButtons
            handleCancel={handleClose}
            handleSend={handleSend}
            disableSend={isDisabled}
            fullWidth
            nameButtonSend="Сохранить"
            justifyContent="flex-end"
        />
    );

    return (
        <DialogWrapper
            openModal={isOpenCreate || isOpenEdit}
            handleModal={handleClose}
            width="40%"
            header={
                isOpenEdit && isFetching ? null : (
                    <Box sx={{mb: 3, mr: 2}} display={'flex'} justifyContent={'center'} flexDirection={'column'}>
                        <InputLine
                            placeholder={'Введите название активности'}
                            size={'medium'}
                            inputProps={{sx: {p: 2, fontSize: 28, '&::placeholder': {fontSize: 28}}}}
                            value={name ?? ""}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Box>
                )
            }
            buttons={buttons}

        >
            {isOpenEdit && isFetching ? (
                <LoadProgress/>
            ) : <Box display="flex" justifyContent="space-between" sx={{my: 2}}>
                <Box sx={{flex: 1}}>
                    <Box sx={{mr: 2}} display={'flex'} justifyContent={'center'} flexDirection={'column'}
                         alignItems={'flex-start'}>
                        <Typography mb={2}>Введите описание активности</Typography>
                        <InputFilled
                            multiline
                            value={comment ?? ""}
                            handleValue={(e) => setComment(e.target.value)}
                        />
                    </Box>
                    <Box sx={{mt: 3, mr: 2}} display={'flex'} justifyContent={'center'} flexDirection={'column'}>
                        <InputLine
                            placeholder={'Баллы за активность'}
                            size={'medium'}
                            value={cost ?? ""}
                            onChange={(e) => setCost(e.target.value)}
                            isNumber
                        />

                    </Box>
                </Box>
            </Box>}
        </DialogWrapper>
    );
};

export default memo(CreateActivity);
