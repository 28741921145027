import React, { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';

import { UserInfo, UserInfoValidation } from '../../../../types/UserInfo.types';
import CustomSelect from '../../../common/Inputs/CustomSelect/CustomSelect';
import { useJobTitiles } from '../../../../hooks/useJobTitiles';
import { findItemById } from '../../../../operation/findItemById';

interface PersonalInfoProps {
  personData: UserInfo;
  setPersonData: Dispatch<SetStateAction<UserInfo>>;
  disabled: boolean;
  validation: UserInfoValidation;
}

const pageSize = 10;

export const Post: FC<PersonalInfoProps> = ({
  personData,
  setPersonData,
  disabled,
}) => {
  const [pageTitle, setPageTitle] = useState<number>(0);

  const titles = useJobTitiles(pageTitle);

  const findJobById = (id: string) => findItemById(titles.titles, id);

  const handleChangeJob = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPersonData({ ...personData, job: findJobById(event.target.value) });
  };

  // +1 - it's test, for some users, scrolling does not work because 1px is lost
  const isScrolledToBottom = (event: any) => {
    const target = event.target;
    return target.scrollTop + target.clientHeight + 1 >= target.scrollHeight;
  };

  const handleTitlesScroll = (event: any) => {
    if (
      isScrolledToBottom(event)
      && titles.titles.length < titles.size
      && !titles.loading
    ) {
      setPageTitle(titles.titles.length / pageSize);
    }
  };

  const jobTitleItems = useMemo(() => (
    (!personData?.job || findJobById(personData?.job?.id))
      ? titles.titles
      : [personData?.job!, ...titles.titles].filter((item) => !!item)
  ), [personData?.job, titles.titles]);

  return (
    <CustomSelect
      testid="jobTitle"
      items={jobTitleItems}
      value={personData?.job?.id ?? ""}
      onChange={handleChangeJob}
      disabled={disabled}
      onScroll={handleTitlesScroll}
      placeholder="Выберите должность"
      height="400px"
    />
  );
};

export default Post;
