import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from './customBaseQuery';
import {ReviewGiftDTO, IReviewPersonGift, IReviewsPersonGift} from '../models/gifts.models';
import {searchParams} from '../operation/searchParams';

export const personGiftController = createApi({
  reducerPath: 'personGiftController',
  baseQuery: customBaseQuery,
  tagTypes: ['PersonGift'],
  endpoints: (builder) => ({
    getAllReviewGift: builder.query<IReviewsPersonGift, Partial<{ createdAtFrom: string | null; createdAtTo: string | null; ownerName?: string }>>({
        query: ({ createdAtFrom, createdAtTo, ownerName }) => {
            const filters = {
                "createdAt>:": createdAtFrom,
                "createdAt<:": createdAtTo,
                "owner.name:": ownerName,
            };

            const params = {
                page: 0,
                size: 1000,
                sort: "id,desc",
                ...(searchParams(filters) && { search: searchParams(filters) })
            }
            return {
                url: `person_gift`,
                method: 'GET',
                params: params,
            };
        },
      providesTags: ['PersonGift'],
    }),
    getReviewGiftByID: builder.query<IReviewPersonGift, string>({
      query: (id) => ({
        url:`person_gift/${id}`
    }),
      providesTags: (result, error, id) => [{ type: 'PersonGift', id }],
    }),
    getAllReviewGiftByUserID: builder.query<IReviewsPersonGift, string>({
      query: (id) => ({
        url:`person_gift/${id}`
      }),
      providesTags: (result, error, id) => [{ type: 'PersonGift', id }],
    }),
    createReviewGift: builder.mutation<IReviewPersonGift, ReviewGiftDTO>({
      query: (body) => ({
        url: 'person_gift',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PersonGift'],
    }),
    deleteReviewGift: builder.mutation<any, string>({
      query: (id) => ({
        url: `person_gift/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PersonGift'],
    }),
    approveReviewGift: builder.mutation<any, string>({
      query: (id) => ({
        url: `person_gift/approve/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['PersonGift'],
    }),
    clearingReviewGift: builder.mutation<any, string>({
      query: (id) => ({
        url: `person_gift/clearing/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['PersonGift'],
    }),
    refuseReviewGift: builder.mutation<any, string>({
      query: (id) => ({
        url: `person_gift/refuse/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['PersonGift'],
    }),
  }),
});

export const {
  useGetAllReviewGiftQuery,
  useGetReviewGiftByIDQuery,
  useLazyGetReviewGiftByIDQuery,
  useCreateReviewGiftMutation,
  useDeleteReviewGiftMutation,
  useApproveReviewGiftMutation,
  useClearingReviewGiftMutation,
  useRefuseReviewGiftMutation,
} = personGiftController;

