import React, {FC} from 'react';
import {useGetAllReviewActivityQuery} from '../../../../services/person_activity.controller';
import RowTable from '../ManagementTable/RowTable/RowTable';
import ManagementTable from '../ManagementTable/ManagementTable';
import {useAppSelector} from '../../../../hooks/useAppSelector';
import Typography from '@mui/material/Typography';


export const UserActivityTable: FC = () => {
    const {createdAtFrom, createdAtTo, searchName} = useAppSelector((state) => state.searchFilters);

    const filters = {
        createdAtFrom,
        createdAtTo,
        ownerName: searchName,
    };
    const {data} = useGetAllReviewActivityQuery(filters)
    const activityApplications = data?.items;

    const columns = [
        {
            id: 'createdAt',
            px: 2,
            label: 'Дата подачи заявки',
            divider: true
        },
        {
            id: 'ownerName',
            px: 5,
            label: 'Сотрудник',
            divider: true
        },
        {
            id: 'activity',
            px: 5,
            label: 'Активность',
            divider: true
        },
        {
            id: 'status',
            px: 5,
            label: 'Статус',
            divider: false
        }
    ]

    return (
        <ManagementTable
            columns={columns}
            children={<>
                {activityApplications && activityApplications.length >= 1 ? activityApplications?.map((
                    {
                        id,
                        createdAt,
                        activity,
                        owner,
                        status
                    }) =>
                    <RowTable
                        key={id}
                        date={createdAt}
                        name={owner.name}
                        typeName={activity.title}
                        status={status.description}
                        type={'managementActivity'}
                        id={id}
                    />
                ) : <Typography display={'flex'} justifyContent={'center'}>Список активностей пуст</Typography>}
            </>
            }
        />
    );
};

export default UserActivityTable;