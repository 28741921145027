import React, { FC, SetStateAction } from "react";

import Box from "@mui/material/Box";

import {TUserInfo} from '../../../types/UserInfo.types';
import { roles } from "../../../constants/role";
import CustomToggleButtons from "../Buttons/CustomToggleButtons/CustomToggleButtons";
import { translateRoles } from "../../../translators/roles.translate";
import { UserFilter } from "../../../types/UserFilters.types";

interface RolesProps {
  personData?: TUserInfo | UserFilter;
  setPersonData: SetStateAction<any>;
  disabled: boolean;
}
export const Roles: FC<RolesProps> = ({
  personData,
  setPersonData,
  disabled,
}) => {
  const roleItems = Object.values(roles);

  const assignNewRole = (role: string) => {
    setPersonData({ ...personData, role: role });
  };

  const additionalStyle = {
    flexWrap: "wrap",
    rowGap: "8px",
  };

  return (
    <Box display="flex" flexWrap="wrap" rowGap="8px">
      <CustomToggleButtons
        items={roleItems}
        currentValue={personData?.role}
        translator={translateRoles}
        onChange={assignNewRole}
        styles={additionalStyle}
        disabled={disabled}
      />
    </Box>
  );
};

export default Roles;
