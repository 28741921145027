import React, { FC, useEffect, useState } from "react";
import {
  Button,
  Chip,
  MenuItem,
  MenuList,
  Stack,
  StackProps,
} from "@mui/material";

import { sanitizeAndTrimStart } from "../../operation/sanitizeAndTrimStart";
import { PersonView } from "../../models/profile.models";
import Search from "../common/Search/Search";
import {
  IFetchPersonsParams,
  useFetchPersonsQuery,
} from "../../services/person.controller";
import { LoadProgress } from "../common/LoadProgress/LoadProgress";
import MemberItem from "../layout/RightSidebar/Members/MemberItem/MemberItem";
import { useAppSelector } from "../../hooks/useAppSelector";
import UserAvatar from "../common/User/UserAvatar/UserAvatar";

import { getSearchString } from "./helpers";
import { ISearchOptions } from "./types";

import { styles } from "./styles";

interface PersonsSelectProps extends StackProps {
  getUsers: (users: PersonView[]) => void;
  participants: PersonView[];
  searchProps?: ISearchOptions;
}

const sizeIncrement: number = 10;

const defaultSearchProps: ISearchOptions = {
  isShowBloked: false,
  fields: ["name", "surname"],
  isShowYourself: false,
};

export const PersonsSelect: FC<PersonsSelectProps> = ({
  getUsers,
  participants,
  searchProps = defaultSearchProps,
  ...rest
}) => {
  const ownUserId = useAppSelector((state) => state.profileReducer.profile.id);
  const [usersState, setUsersState] = useState<PersonView[]>([]);
  const [chosensState, setChosensState] = useState<Map<string, PersonView>>(
    new Map(participants.map((item) => [item.id, item]))
  );
  const [params, setParams] = useState<IFetchPersonsParams>(() => {
    const newState: IFetchPersonsParams = { size: sizeIncrement };
    const search = getSearchString("", ownUserId, searchProps);
    if (search) newState.search = search;
    return newState;
  });
  const [search, setSearch] = useState<string>("");
  const { data, isFetching } = useFetchPersonsQuery(params);

  useEffect(() => {
    if (!data) return;
    setUsersState(data.items.map((user) => ({ ...user, isChecked: false })));
  }, [data]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
    const sanitizedValue = sanitizeAndTrimStart(value);
    setParams((prev) => {
      const newState = { ...prev };
      const search = getSearchString(sanitizedValue, ownUserId, searchProps);
      if (search) newState.search = search;
      return newState;
    });
  };

  const handleChose = (person: PersonView) => {
    const newState = new Map(chosensState);
    newState.set(person.id, person);
    getUsers([...newState.values()]);
    setChosensState(newState);
  };

  const handleUnchose = (id: string) => {
    const newState = new Map(chosensState);
    newState.delete(id);
    getUsers([...newState.values()]);
    setChosensState(newState);
  };

  return (
    <Stack {...rest} gap={2}>
      <Search
        placeholder={"Поиск"}
        value={search}
        handleChange={handleSearch}
      />
      {chosensState.size > 0 && (
        <Stack
          gap={2}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          flexWrap={"wrap"}
        >
          {[...chosensState.values()].map((person) => {
            if (person.id === ownUserId) return null;
            return (
              <Chip
                key={person.id}
                sx={(theme) => ({
                  width: "max-content",
                  backgroundColor: theme?.palette?.secondary?.contrastText,
                })}
                label={`${person.name} ${person.surname}`}
                avatar={
                  <UserAvatar
                    userInfo={person}
                    styles={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "6px",
                    }}
                  />
                }
                onDelete={() => handleUnchose(person.id)}
              />
            );
          })}
        </Stack>
      )}
      <MenuList>
        {usersState.map((item) => {
          if (item.id === ownUserId) return null;
          if (chosensState.has(item.id)) return null;
          return (
            <MenuItem key={item.id} onClick={() => handleChose(item)}>
              <MemberItem
                withPopover={true}
                userInfo={item}
                onClick={() => {}}
              />
            </MenuItem>
          );
        })}
        {isFetching ? (
          <LoadProgress />
        ) : (
          data &&
          data.totalItems > usersState.length && (
            <MenuItem sx={styles.itemButtonMore}>
              <Button
                sx={styles.buttonMore}
                onClick={() =>
                  setParams((prev) => ({
                    ...prev,
                    size: prev.size ? prev.size + sizeIncrement : sizeIncrement,
                  }))
                }
              >
                Показать еще...
              </Button>
            </MenuItem>
          )
        )}
      </MenuList>
    </Stack>
  );
};
