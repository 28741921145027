import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CalendarEventType, IEventsState } from "../types/CalendarEvent.type";

export type AllEventsQueryType =
  | {
      departmentsIds?: string[];
      projectsIds?: string[];
      typesIds?: string[];
    }
  | { tags: string[] };

type CalendarInfo = {
  eventTypes: CalendarEventType[];
  allEvents: { [key: string]: IEventsState[] };
  allEventsQuery: null | AllEventsQueryType;
  weekends: string[];
};

const initialState: CalendarInfo = {
  eventTypes: [],
  allEvents: {},
  allEventsQuery: null,
  weekends: [],
};

const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    eventTypes: (state, action: PayloadAction<CalendarEventType[]>) => {
      state.eventTypes = action.payload;
    },
    allEvents: (
      state,
      action: PayloadAction<{ [key: string]: IEventsState[] }>
    ) => {
      if (!state.allEventsQuery) {
        state.allEvents = {};
      } else if (
        "tags" in state.allEventsQuery &&
        !state.allEventsQuery.tags.length
      ) {
        state.allEvents = {};
      } else {
        state.allEvents = action.payload;
      }
    },
    changeAllEventsQuery: (
      state,
      action: PayloadAction<AllEventsQueryType | null>
    ) => {
      state.allEventsQuery = action.payload;
    },
    changeTagsQuery: (state, action: PayloadAction<string[]>) => {
      const payload = action.payload;
      const tags =
        state.allEventsQuery && "tags" in state.allEventsQuery
          ? state.allEventsQuery.tags
          : [];
      if (!state.allEventsQuery || !("tags" in state.allEventsQuery)) {
        state.allEventsQuery = { tags: payload };
      } else if (tags.length === 0) {
        state.allEventsQuery.tags = payload;
      } else if (
        tags.filter((tag) => payload.includes(tag)).length === payload.length
      ) {
        const newTags = tags.filter((tag) => !payload.includes(tag));
        state.allEventsQuery.tags = newTags;
      } else {
        const newTags = [...tags, ...payload];
        state.allEventsQuery.tags = newTags;
      }
    },
    addWeekends: (state, action: PayloadAction<string[]>) => {
      state.weekends = [...new Set(action.payload)];
    },
  },
});

export const {
  eventTypes,
  allEvents,
  changeAllEventsQuery,
  changeTagsQuery,
  addWeekends,
} = calendarSlice.actions;
export default calendarSlice.reducer;
