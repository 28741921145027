import React, { FC, useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../hooks/useAppSelector";
import { dataInitial } from "../../actions/data";
import { feedController } from "../../services/feed.controller";
import { FeedRecordView } from "../../models/feed.model";
import { secondaryButton } from "../../constants/buttonStyle";
import { reactionController } from "../../services/reaction.controller";

import {
  Box,
  ListItem,
  Tab,
  Tabs,
  List,
  Typography,
  Button
} from "@mui/material";

import Stories from "./Stories/Stories";
import FeedItem from "./FeedItem/FeedItem";
import { LoadProgress } from "../common/LoadProgress/LoadProgress";
import CreateFeed from "./CreateFeed/CreateFeed";

type IFeedTypeTab = "important" | "communication";

interface IFeedInitialState {
  isProvided: boolean,
  idToScrollTo: string,
  tabIndex: IFeedTypeTab,
  totalRecords: number,
  currentPage: number,
  countsRecords: number,
}

const countsRecords = 10;

export const feedInitialState: IFeedInitialState = {
  isProvided: false,
  idToScrollTo: "",
  tabIndex: "important",
  totalRecords: 0,
  currentPage: 0,
  countsRecords: countsRecords,
};

export const Feed: FC = () => { 
  const dispatch = useDispatch();

  const currentUser = JSON.parse(
    localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
  );

  const feedRecordsData: FeedRecordView[] = useAppSelector(
    (state) => state.dataReducer.feed
  );
  
  const [tabIndex, setTabIndex] = useState<IFeedTypeTab>(
    feedInitialState.isProvided ? feedInitialState.tabIndex : "important"
  );
  const [totalRecords, setTotalItems] = useState<number>(
    feedInitialState.isProvided ? feedInitialState.totalRecords : 0
  );
  const [currentPage, setCurrentPage] = useState<number>(
    feedInitialState.isProvided ? feedInitialState.currentPage : 0
  );
  
  const [isLoad, setIsLoad] = useState<boolean>(false)

  const handleTabChange = (event: any, newTabIndex: IFeedTypeTab) => {    
    dispatch(dataInitial.feed([]));
    setCurrentPage(0);
    setTabIndex(newTabIndex);
  }

  const handlePageChange = () => {
    setCurrentPage((prevState) => prevState + 1);
  }

  const getFeedRecords = () => {            
    if (feedRecordsData.length > 0 && feedInitialState.isProvided) {
      setTabIndex(feedInitialState.tabIndex);
      setTotalItems(feedInitialState.totalRecords);
      setCurrentPage(feedInitialState.currentPage);
      if (feedInitialState.idToScrollTo) {
        const elementToScrollTo = document.getElementById(feedInitialState.idToScrollTo);
        setTimeout(() => {
          elementToScrollTo?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        });
      }
      feedInitialState.isProvided = false;
    } else {
      setIsLoad(true)
      feedController
        .feed(currentPage, tabIndex === 'important', countsRecords)
        .then((res) => {   
          if(currentPage > 0) {          
            dispatch(dataInitial.feed([...feedRecordsData, ...res.data.items]));
          } else {          
            dispatch(dataInitial.feed(res.data.items));
          }
          setTotalItems(res.data.totalItems)
          feedInitialState.tabIndex = tabIndex;
          feedInitialState.currentPage = currentPage;
          feedInitialState.totalRecords = res.data.totalItems;
          feedInitialState.idToScrollTo = "";
        })
        .finally(() => {
          setIsLoad(false)
        });
    }
  };
  
  const getSmiles = () => {
    reactionController
      .smiles()
      .then((res) => {
        dispatch(dataInitial.smiles(res.data.items))        
      });
  }

  useEffect(() => {
    getSmiles()
  }, []);

  useEffect(() => {
    getFeedRecords()
  }, [tabIndex, currentPage]);

  return (
    <Box>

      <Box>
        <Stories />
      </Box>

      <Box mt="12px">
        <Tabs value={tabIndex} onChange={handleTabChange} variant="fullWidth">
          <Tab value={'important'} disabled={isLoad} label="Важное" />
          <Tab value={'communication'} disabled={isLoad} label="Общение" />
        </Tabs>
      </Box>

      {(tabIndex === "important" && currentUser.role === 'ADMIN' || tabIndex === "communication") && (
        <Box mt={2}>
          <CreateFeed important={tabIndex === "important"} isLoad={isLoad} />
        </Box>
      )}

      <List sx={{ padding: 0 }}>
        {feedRecordsData.map((record) => (
          <ListItem key={record.id} sx={{ padding: 0, paddingTop: 2 }}>
            <FeedItem 
              important={tabIndex === "important"}
              feedItem={record}
            />
          </ListItem>
        ))}
      </List>

      {(!isLoad && (feedRecordsData.length < totalRecords)) && 
        <Button
          fullWidth
          sx={{
            borderRadius: "60px",
            padding: "12px 0",
            mt: "16px",
            ...secondaryButton,
          }}
          onClick={handlePageChange}
        >
          <Typography>Показать ещё</Typography>
        </Button>
      }

      {isLoad && <LoadProgress testId={'mainLoadProgress'} />}
      
    </Box>
  );
};

export default Feed;
