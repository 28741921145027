import React, {FC, memo, useEffect} from 'react';
import DialogWrapper from '../../../common/Modals/DialogWrapper/DialogWrapper';
import {useLazyGetGiftByIDQuery} from '../../../../services/gift.controller';
import {useAppDispatch} from '../../../../hooks/useAppDispatch';
import {useAppSelector} from '../../../../hooks/useAppSelector';
import {toggleModal} from '../../../../reducer/bonusesModalSlice';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import {getPointsText} from '../../helpers/getPointsText';
import gifts from '../../../../assets/images/gifts.png'
import {LoadProgress} from '../../../common/LoadProgress/LoadProgress';

export const ViewGift: FC = () => {
    const dispatch = useAppDispatch();

    const {isOpenView, modalType, selectedEntityId} = useAppSelector((state) => state.bonusesModal);

    const [fetchGiftByID, {data, isFetching}] = useLazyGetGiftByIDQuery();

    const handleClose = () => {
        dispatch(toggleModal({type: null, isOpenView: false}));
    };
    useEffect(() => {
        if (isOpenView && modalType === 'gift') {
            fetchGiftByID(selectedEntityId ?? '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenView]);

    const imgSrc = `/loyalty-portal/api/attachments/${data?.imageName}`

    return (
        <DialogWrapper
            openModal={isOpenView && modalType === 'gift'}
            handleModal={handleClose}
            width="532px"
            contentDividers
            header={isFetching ? '' : `${data?.title}`}
        >
            {!isFetching ? <Box>
                <Box sx={{my: 2, mx: 2}} display={'flex'} justifyContent={'center'} flexDirection={'column'}>
                    <img
                        src={data?.imageName ? imgSrc : gifts}
                        alt={data?.title}
                        width={'100%'}
                        height={'100%'}
                        loading="lazy"
                        style={{objectFit: 'cover'}}
                    />

                </Box>
                <Divider variant="fullWidth"/>
                <Box>
                    <Typography>{data?.description}</Typography>
                </Box>
                <Box display={'flex'} justifyContent={'flex-end'}>
                    <Typography>{`${data?.cost} ${getPointsText(data?.cost!)}`}</Typography>
                </Box>
            </Box> : <LoadProgress/>
            }
        </DialogWrapper>
    );
};

export default memo(ViewGift);
