import { SystemStyleObject, Theme } from '@mui/system';

const keys = ['text', 'styleImgCelebrateAdd'] as const;

type StylesType = Record<(typeof keys)[number], SystemStyleObject<Theme>> & {
  styleImgGifts: (gifts: string) => SystemStyleObject<Theme>;
  styleImgCelebrate: (celebrate: string) => SystemStyleObject<Theme>;
};

export const styles: StylesType = {
  text: {
    overflowWrap: 'break-word',
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitLineClamp: 10,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
  },
  styleImgGifts: (gifts) => ({
    backgroundImage: `url(${gifts})`,
    width: '100%',
    height: '200px',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    bottom: 0,
  }),
  styleImgCelebrate: (celebrate) => ({
    backgroundImage: `url(${celebrate})`,
    width: '100%',
    height: '30vh',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    top: 0,
  }),
  styleImgCelebrateAdd: {
    transform: 'scale(-1, 1)',
    right: '-27px',
  },
};
