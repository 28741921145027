import React from 'react';
import InputWrapper from '../../../../../common/Inputs/InputWrapper/InputWrapper';
import Stack from '@mui/material/Stack';
import InputFilled from '../../../../../common/Inputs/InputFilled/InputFilled';

interface IProps {
  data: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const About = ({ data, onChange }: IProps) => {
  return (
    <InputWrapper title="О себе">
      <Stack width="100%" direction="row" spacing={2}>
        <InputFilled
          testid="description"
          multiline
          value={data}
          handleValue={onChange}
        />
      </Stack>
    </InputWrapper>
  );
};

export default About;