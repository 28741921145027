import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from './customBaseQuery';
import {TActivityPerson, TActivityPersonView} from '../models/activityPerson.models';
import {searchParams} from '../operation/searchParams';

export const personActivityController = createApi({
  reducerPath: 'personActivityController',
  baseQuery: customBaseQuery,
  tagTypes: ['PersonActivity'],
  endpoints: (builder) => ({
    getAllReviewActivity: builder.query<
        TActivityPerson,
        Partial<{ createdAtFrom: string | null; createdAtTo: string | null; ownerName?: string}>
    >({
      query: ({ createdAtFrom, createdAtTo, ownerName }) => {
        const filters = {
          "createdAt>:": createdAtFrom,
          "createdAt<:": createdAtTo,
          "owner.name:": ownerName,
        };

        const params = {
          page: 0,
          size: 1000,
          sort: "id,desc",
          ...(searchParams(filters) && { search: searchParams(filters) })
        }

        return {
          url: `person_activity`,
          method: 'GET',
          params: params,
        };
      },
      providesTags: ['PersonActivity'],
    }),
    getReviewActivityByID: builder.query<TActivityPersonView, string>({
      query: (id) => ({
        url:`person_activity/${id}`
    }),
      providesTags: (result, error, id) => [{ type: 'PersonActivity', id }],
    }),
    getAllReviewActivityByUserID: builder.query<TActivityPerson, string>({
      query: (id) => ({
        url:`person_activity/${id}`
      }),
      providesTags: (result, error, id) => [{ type: 'PersonActivity', id }],
    }),
    createReviewActivity: builder.mutation<any, any>({
      query: (body) => ({
        url: 'person_activity',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PersonActivity'],
    }),
    deleteReviewActivity: builder.mutation<any, string>({
      query: (id) => ({
        url: `person_activity/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PersonActivity'],
    }),
    approveReviewActivity: builder.mutation<any, string>({
      query: (id) => ({
        url: `person_activity/approve/${id}`,
        method: 'POST'
      }),
      invalidatesTags: ['PersonActivity'],
    }),
    clearingReviewActivity: builder.mutation<any, string>({
      query: (id) => ({
        url: `person_activity/clearing/${id}`,
        method: 'POST'
      }),
      invalidatesTags: ['PersonActivity'],
    }),
    refuseReviewActivity: builder.mutation<any, string>({
      query: (id) => ({
        url: `person_activity/refuse/${id}`,
        method: 'POST'
      }),
      invalidatesTags: ['PersonActivity'],
    }),
  }),
});

export const {
  useGetAllReviewActivityQuery,
  useGetReviewActivityByIDQuery,
  useLazyGetReviewActivityByIDQuery,
  useCreateReviewActivityMutation,
  useDeleteReviewActivityMutation,
  useApproveReviewActivityMutation,
  useClearingReviewActivityMutation,
  useRefuseReviewActivityMutation,
} = personActivityController;

