import React, {ReactElement} from 'react';
import {CardGiftcard, ExitToApp, InsertDriveFile} from '@mui/icons-material';
import { heroSection } from '../HeroSection/styles/heroSection';

interface Button  {
    title: string,
    desc: string,
    icon: ReactElement,
    width: string,
    onClick: () => void
}

export const useHeroButtons = (toggleModal: any, downloadFile: any): Button[] => [
    {
        title: 'Добавить новую активность',
        desc: 'Управление департаментами',
        icon: <ExitToApp sx={heroSection.button__icon} />,
        width: '50%',
        onClick: toggleModal('actives', true),
    },
    {
        title: 'Обменять балы на подарки',
        desc: 'Управление ролями',
        icon: <CardGiftcard sx={heroSection.button__icon} />,
        width: '50%',
        onClick: toggleModal('gift', true),
    },
    {
        title: 'Правила и условия бонусной программы',
        desc: 'Дополнительная информация',
        icon: <InsertDriveFile sx={heroSection.button__icon} />,
        width: '100%',
        onClick: () => downloadFile('1719927393_500183018_af997.pdf', 'Правила_и_условия_бонусной_программы.pdf'),
    }
];
