import React from "react";
import { Box } from "@mui/material";
import CustomButton from '../../../../common/Buttons/CustomButton/CustomButton';

export interface ButtonItem {
    text: string;
    type: "approve" | "cancel" | "clearing";
}

interface IApplicationButtonsProps {
    buttons: ButtonItem[];
    onClick: (type: "approve" | "cancel" | "clearing") => void;
    isVisible: boolean;
}

export const ApplicationButtons: React.FC<IApplicationButtonsProps> = ({ buttons, onClick, isVisible }) => {
    if (!isVisible) return null;
    return (
        <Box display="flex" flexDirection="column" flex="30%" gap={1} sx={{ marginLeft: 4 }}>
            {buttons.map((button, index) => (
                <CustomButton key={index} text={button.text} color="primary" onClick={() => onClick(button.type)} />
            ))}
        </Box>
    );
};
