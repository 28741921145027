import { AxiosResponse } from 'axios';
import { ITagView } from '../types/UserInfo.types';
import jwtInterceoptor from './jwtInterceptor';
import { apiUrlPortal } from '../constants/apiUrl';

export const tagController = {
  fetchAll: async (): Promise<AxiosResponse<ITagView[]>> => {
    return await jwtInterceoptor.get(`${apiUrlPortal}/tags/`);
  },
  create: async (name: string): Promise<AxiosResponse<{ name: string, id: string }>> => {
    return await jwtInterceoptor.post(`${apiUrlPortal}/tags/`, { name });
  },
  remove: async (id: string): Promise<AxiosResponse<{ name: string, id: string }>> => {
    return await jwtInterceoptor.delete(`${apiUrlPortal}/tags/${id}`, );
  },
  addToPerson: async (personId: string, tagId: string): Promise<AxiosResponse<{ name: string, id: string }>> => {
    return await jwtInterceoptor.patch(`${apiUrlPortal}/tags/add-to-person/${personId}/${tagId}`);
  },
  removeFromPerson: async (personId: string, tagId: string): Promise<AxiosResponse<{ name: string, id: string }>> => {
    return await jwtInterceoptor.patch(`${apiUrlPortal}/tags/remove-from-person/${personId}/${tagId}`);
  },
}