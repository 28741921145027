export const servicesStyle = {
    "&:before": {
        display: "none",
    },
    ":first-of-type": {
        borderRadius: "24px",
    },
    borderRadius: "24px",
    marginBottom: "24px",
};

export const iconStyle = {
    minWidth: "auto",
    marginRight: "20px",
};

export const iconProps = {
    sx: {
        width: "32px",
        height: "32px",
    },
    viewBox: "0 0 32 32",
};
