import React, { FC, useState, useContext, useEffect } from "react";
import { kbController } from "../../../services/kb.controller";
import { KbContext } from "../KbContextProvider/KbContextProvider";
import { AppContext } from "../../shared/AppContextProvider";
import { KbRoles } from "../../../models/kb.models";
import { LoadProgress } from "../../common/LoadProgress/LoadProgress";

import InputLine from "../../common/Inputs/InputLine/InputLine";
import DialogWrapper from "../../common/Modals/DialogWrapper/DialogWrapper";
import ModalButtons from "../../common/Buttons/ModalButtons/ModalButtons";
import InputWrapper from "../../common/Inputs/InputWrapper/InputWrapper";
import KbListSequence from "./material/KbListSequence";
import KbTypeSequence from "./material/KbTypeSequence";
import KbToggleButtonGroup from "./material/KbToggleButtonGroup";

import {
  Typography,
  Stack,
  Divider,
  SelectChangeEvent,
} from "@mui/material";

interface infoSection {
  name: string;
  description: string;
  visionRoles: KbRoles[] | ['ADMIN']
  sequenceNumber: string
}

interface SectionModalProps {
  open: boolean;
  type: "create" | "edit";
  sectionId?: string;
  handleModal: () => void;
  handleCompleted?: () => void;
}

export const SectionModal: FC<SectionModalProps> = ({
  open,
  type,
  sectionId,
  handleModal,
  handleCompleted = () => {},
}) => {
  const { isMobile } = useContext(AppContext);
  const { handleSnackbar } = useContext(KbContext);
  
  const isCreate = type === "create";

  const [isLoad, setIsLoad] = useState<boolean>(false)
  const [countsSequenceSection, setCountsSequenceSection] = useState<number>(0)
  
  const [typeShift, setTypeShift] = useState<'replacement' | 'offset'>('replacement')
  const handleTypeShift = (e: SelectChangeEvent) => {
    setTypeShift(e.target.value as 'replacement' | 'offset')
  };

  const [infoSection, setInfoSection] = useState<infoSection>({
    name: "",
    description: "",
    visionRoles: ['ADMIN'],
    sequenceNumber: "",
  });

  const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInfoSection({...infoSection, name: e.target.value});
  };

  const handleDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInfoSection({...infoSection, description: e.target.value});
  };

  const handleRoles = (e: React.MouseEvent<HTMLElement>, newRoles: KbRoles[]) => {
    setInfoSection({...infoSection, visionRoles: newRoles});
  };

  const handleActiveSequence = (e: SelectChangeEvent) => {
    setInfoSection({...infoSection, sequenceNumber: e.target.value});
  };

  const getCountsSectionSequence = () => {
    kbController
      .countsSectionSequence()
      .then((res) => {
        setCountsSequenceSection(Number(res.data))
      })
      .catch((error) => {
        console.error(error)
        handleSnackbar("error", "Не удалось получить информацию по разделу");
        handleModal();
      })
  }

  const getInfoSection = () => {
    getCountsSectionSequence()
    if (sectionId) {
      setIsLoad(true)
      kbController
        .oneSection(sectionId)
        .then((res) => {
          setInfoSection({
            name: res.data.name,
            description: res.data.description,
            visionRoles: res.data.visionRoles,
            sequenceNumber: !!res.data.sequenceNumber ? String(res.data.sequenceNumber) : ""
          });        
        })
        .catch((error) => {
          console.error(error)
          handleSnackbar("error", "Не удалось получить информацию по разделу");
          handleModal();
        })
        .finally(() => {
          setIsLoad(false)
        })
    }
  };

  const createSection = () => {
    setIsLoad(true)
    kbController
      .createSection({...infoSection, isReplacement: typeShift === 'replacement'})
      .then(() => {
        handleCompleted();
        handleModal();
      })
      .catch((error) => {
        manageError(error.response.data.errorCode);
      })
      .finally(() => {
        setIsLoad(false)
      });
  };

  const editSection = () => {
    if (sectionId) {
      setIsLoad(true)
      kbController
        .editSection(sectionId, {...infoSection, isReplacement: typeShift === 'replacement'})
        .then(() => {
          handleCompleted();
          handleModal();
        })
        .catch((error) => {
          manageError(error.response.data.errorCode);
        })
        .finally(() => {
          setIsLoad(false)
        });
    }
  };

  useEffect(() => {
    getInfoSection()
  }, []);

  const manageError = (errorCode: number) => {
    const sameSection = errorCode === 38;
    handleSnackbar(
      sameSection ? "info" : "error",
      sameSection ? "Раздел с таким названием уже существует" : undefined
    );
    handleModal();
  };

  const handleSaveButton = () => {
    if (isCreate) {
      createSection();
    } else {
      editSection();
    }
  };

  const createModalButtons = (
    <ModalButtons
      handleCancel={handleModal}
      handleSend={handleSaveButton}
      fullWidth
      nameButtonSend="Сохранить"
      justifyContent="flex-end"
      disableSend={!infoSection.name || !infoSection.sequenceNumber}
    />
  );

  return (
    <DialogWrapper
      openModal={open}
      handleModal={handleModal}
      buttons={createModalButtons}
      contentDividers
      width={isMobile ? '450px' : '650px'}
    >
      { isLoad 
        ? <LoadProgress/>
        : <Stack gap={4}>

            <Typography variant="h4">
              {isCreate ? "Создать раздел" : "Редактировать раздел"}
            </Typography>

            <InputWrapper
              flexDirection="column"
              title="Название раздела"
              element={
                <InputLine
                  value={infoSection.name}
                  onChange={handleName}
                  placeholder="Введите название"
                />
              }
            />

            <InputWrapper
              flexDirection="column"
              title="Описание раздела"
              element={
                <InputLine
                  value={infoSection.description}
                  onChange={handleDescription}
                  placeholder="Введите описание раздела"
                />
              }
            />

            <InputWrapper
              flexDirection={isMobile ? 'column' : 'row'}
              title="Видимость раздела"
              element={
                <KbToggleButtonGroup 
                  value={infoSection.visionRoles}
                  onChange={handleRoles}
                />
              }
            />
               
            <Divider/>

            <InputWrapper
              flexDirection={isMobile ? 'column' : 'row'}
              title="Порядковый номер раздела"
              element={
                <Stack flexDirection={'row'} gap={2}>
                  <KbListSequence 
                    value={infoSection.sequenceNumber}
                    countsSequence={isCreate ? (countsSequenceSection + 1) : countsSequenceSection}
                    onChange={handleActiveSequence}
                  />
                  <KbTypeSequence
                    value={typeShift}
                    onChange={handleTypeShift}
                  />
                </Stack>
              }
            /> 
          </Stack>
      }
    </DialogWrapper>
  );
};

export default SectionModal;
