import React, { FC, SetStateAction } from "react";

import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";

import CustomButton from "../../../../common/Buttons/CustomButton/CustomButton";
import StepsStory from "../StepsStory/StepsStory";
import {
  Story,
  defaultStory,
  EditedStoryIds,
} from "../../../../../types/Story.types";

interface CreateModalProps {
  currentStory: Story;
  steps: number;
  currentStep: number;
  setCurrentStep: SetStateAction<any>;
  setStories: SetStateAction<any>;
  isEdit?: boolean;
  setEditedIds?: SetStateAction<any>;
}

export const ModalHeader: FC<CreateModalProps> = ({
  currentStory,
  steps,
  currentStep,
  setCurrentStep,
  setStories,
  isEdit,
  setEditedIds,
}) => {
  const styleAddPhoto = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",
  };

  const handleAddStory = () => {
    setStories((prev: Story[]) => [...prev, defaultStory]);
    setCurrentStep((prev: number) => prev + 1);
  };

  const handleDeleteBg = () => {
    setStories((prev: any) =>
      prev.map((item: Story, index: number) =>
        index === currentStep
          ? { ...item, background: undefined, backgroundURL: undefined }
          : item
      )
    );
    if (isEdit) {
      setEditedIds((prev: EditedStoryIds) => ({
        ...prev,
        attachment: [...prev?.attachment, currentStory.id],
      }));
    }
  };

  const handleDeleteStory = () => {
    setStories((prev: any) =>
      prev.filter((item: Story, index: number) => index !== currentStep)
    );
    setCurrentStep((prev: number) => prev ? prev - 1 : 0);
    if (isEdit) {
      setEditedIds((prev: EditedStoryIds) => ({
        ...prev,
        delete: [...prev.delete, currentStory.id],
      }));
    }
  };

  return (
    <Box sx={styleAddPhoto}>
      <StepsStory
        steps={steps}
        onClick={(index: number) => setCurrentStep(index)}
      />
      {!isEdit && (
        <CustomButton
          color="secondary"
          fullWidth
          startIcon={<AddIcon />}
          text="Добавить слайд"
          onClick={handleAddStory}
        />
      )}
      {currentStory.background && (
        <CustomButton
          color="secondary"
          text="Удалить изображение"
          lightType
          onClick={handleDeleteBg}
        />
      )}
      {steps > 1 && (
        <CustomButton
          color="secondary"
          text="Удалить слайд"
          lightType
          onClick={handleDeleteStory}
        />
      )}
    </Box>
  );
};

export default ModalHeader;
