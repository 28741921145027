import React, { FC } from "react";

import { SxProps, Box } from "@mui/material";

import Logo from "../Logo/Logo";

interface LoginPageWrapperProp {
  handleSubmit: React.FormEventHandler;
  children: React.ReactNode;
}

export const LoginPageWrapper: FC<LoginPageWrapperProp> = ({
  handleSubmit,
  children,
}) => {
  const boxWrapperStyle: SxProps = {
    overflow: "auto",
    maxWidth: "428px",
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    justifyContent: "start",
    alignItems: "center",
    width: "100%",
    height: "100%",
    px: "16px",
    position: "fixed",
    top: "0px",
    paddingTop: "116px",
    "@media (max-height: 768px)": { paddingTop: "52px" },
    left: "50%",
    transform: "translateX(-50%)",
    scrollbarWidth: "none",
  };

  return (
    <Box sx={boxWrapperStyle} component="form" onSubmit={handleSubmit}>
      <Logo />
      {children}
    </Box>
  );
};

export default LoginPageWrapper;
