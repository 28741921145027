import React, { Dispatch, FC, SetStateAction } from 'react';

import Stack from '@mui/material/Stack';
import { UserInfo } from '../../../types/UserInfo.types';
import About from './components/ui/About/About';
import Tags from './components/ui/Tags/Tags';
import Communities from './components/ui/Communities/Communities';

interface PersonalInfoProps {
  personData: UserInfo;
  setPersonData: Dispatch<SetStateAction<UserInfo>>;
}

export const PersonalInfo: FC<PersonalInfoProps> = ({
  personData,
  setPersonData,
}) => {
  const handleChangeInputText =
    (prop: keyof UserInfo) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setPersonData({ ...personData, [prop]: event.target.value });
    };

  const handleChange = (field: string, value: any) => {
    setPersonData({
      ...personData,
      [field]: value
    })
  }

  return (
    <Stack spacing={4}>
      <About data={personData.description || ''} onChange={handleChangeInputText('description')}/>
      <Tags data={personData.tags} onChange={(value) => handleChange('tags', value)}/>
      <Communities data={personData.communities} onChange={handleChange}/>
    </Stack>
  );
};

export default PersonalInfo;
