import { SystemStyleObject, Theme } from '@mui/system';

interface IStyles {
  storyWrapper: SystemStyleObject<Theme>;
  styleWrapper: (adminStory: boolean) => SystemStyleObject<Theme>;
  bgImageStyle: ({
    urlImg,
    adminStory,
  }: {
    adminStory: boolean;
    urlImg: string;
  }) => SystemStyleObject<Theme>;
}

export const styles: IStyles = {
  storyWrapper: { width: '100%', height: '100%' },
  styleWrapper: (adminStory) => ({
    padding: '5rem 1rem 2rem',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    justifyContent: adminStory ? 'flex-end' : 'center',
    alignItems: adminStory ? 'initial' : 'center',
    background: 'rgba(33, 38, 51, 1)',
  }),
  bgImageStyle: ({ urlImg, adminStory }) => ({
    backgroundImage: adminStory
      ? `linear-gradient(0deg, rgba(33, 38, 51, 0.2), rgba(33, 38, 51, 0.2)), linear-gradient(180deg, rgba(33, 38, 51, 0.15) 57.52%, #212633 82.66%),${urlImg}`
      : urlImg,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundClip: 'border-box',
  }),
} as const;
