import React, { FC } from "react";

import {
  Stack,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";

import { translateRoles } from "../../../../translators/roles.translate";
import { KbRoles } from "../../../../models/kb.models";

interface SectionModalProps {
  value: KbRoles[] | ['ADMIN'];
  onChange: (e: React.MouseEvent<HTMLElement>, newRoles: KbRoles[]) => void;
}

export const KbToggleButtonGroup: FC<SectionModalProps> = ({
  value = ['ADMIN'],
  onChange,
}) => {
  const roles = ['USER', 'EDITOR', 'ADMIN']

  return (
    <ToggleButtonGroup
      value={value}
      onChange={onChange}
    >
      <Stack gap={1} flexDirection={'row'}>
        {roles.map((el, idx) => 
          <ToggleButton key={idx} color="primary" value={el} disabled={el === 'ADMIN'}>
            { translateRoles(el) }
          </ToggleButton>
        )}
      </Stack>
    </ToggleButtonGroup>
  );
};

export default KbToggleButtonGroup;
