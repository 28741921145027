import React, { FC } from "react";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router";

import { MenuListItems } from "../../../../types/MenuListItems.types";
import { Paper } from "@mui/material";

interface BottomNavigationsProps {
  items: MenuListItems;
}
export const BottomNavigations: FC<BottomNavigationsProps> = ({ items }) => {
  const currentLocation = useLocation().pathname;
  const currentIndex = items.findIndex((item) => item.href === currentLocation);

  const excludeLocations = ["/feed/.*", "/profile/edit", "/community/.*"];

  const isHidden = excludeLocations.some((pattern) => {
    const regex = new RegExp(`^${pattern}$`);
    return regex.test(currentLocation);
  });

  const backgroundColor = { backgroundColor: "rgba(33, 38, 51, 0.9)" };
  const paperStyle = {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: "1px solid rgba(241, 247, 255, 0.05)",
    ...backgroundColor,
    zIndex: 10,
  };

  return (
    <>
      {!isHidden && (
        <Paper sx={paperStyle}>
          <BottomNavigation
            showLabels
            value={currentIndex}
            sx={{ minHeight: "96px", ...backgroundColor }}
          >
            {items
              .filter((_item, index) => index <= 3 || index === items.length - 1) //only buttons for bottom navigations
              .map((item, index) => (
                <BottomNavigationAction
                  key={index}
                  label={item.name}
                  icon={currentIndex === index ? item.icon : item.inactiveIcon}
                  component={NavLink}
                  to={item.href}
                  sx={{
                    gap: "8px",
                    minWidth: "72px",
                    fontSize: "12px",
                    ".MuiBottomNavigationAction-label, &.MuiBottomNavigationAction-label":
                      { fontSize: "12px !important" },
                  }}
                />
              ))}
          </BottomNavigation>
        </Paper>
      )}
    </>
  );
};

export default BottomNavigations;
