import React from 'react';
import {ReviewInProfileContextProvider} from "../RewievContextProvider/ReviewInProfileContextProvider";
import ReviewInProfile from "./ReviewInProfile";

const Review = () => {

    return (
        <ReviewInProfileContextProvider>
            <ReviewInProfile/>
        </ReviewInProfileContextProvider>
    )
};

export default Review;