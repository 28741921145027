import { SystemStyleObject, Theme } from "@mui/system";

const keys = [
  "buttonsBlockStyle",
  "buttonStyle",
  "columnAlignedStyle",
  "eventStyle",
  "modalContainerStyle",
  "dscription",
  "typesColor",
  "typesFont",
  "accordion",
  "accordionLabel",
  "accordionLabelName",
] as const;

type StylesType = Record<(typeof keys)[number], SystemStyleObject<Theme>>;

export const styles: StylesType = {
  buttonsBlockStyle: {
    paddingTop: "1rem",
    display: "flex",
    justifyContent: "flex-end",
    "& > *:not(:last-child)": {
      marginRight: "1rem",
    },
  },
  buttonStyle: {
    borderRadius: "1.5rem",
  },
  columnAlignedStyle: {
    marginBottom: "1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  eventStyle: {
    marginLeft: 0,
    padding: "0 1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: "100%",
  },
  modalContainerStyle: {
    margin: "auto",
    transform: "translateY(5%)",
    width: "clamp(300px, 90%, 500px)",
    height: "90%",
    overflow: "auto",
    padding: "1rem",
    borderRadius: "1rem",
    backgroundColor: "#323947",
  },
  dscription: { margin: "0 0 0.5rem 0", alignSelf: "center" },
  typesColor: { color: "#131722" },
  typesFont: { marginLeft: 0, fontWeight: 600 },
  accordion: { padding: "0 1rem" },
  accordionLabel: { margin: 0 },
  accordionLabelName: { width: "100%" },
};
