import {PersonView} from "../../../models/profile.models";
import {PersonCommunityView} from "../../../models/community.models";
import UserInfo from "../../common/User/UserInfo/UserInfo";
import React from "react";

export const EmployerRenderCell = (userInfo: PersonView | PersonCommunityView) => {
    return (
        <>
            <UserInfo userInfo={userInfo} withEllipsis={true} withNavigation/>
        </>
    )
}