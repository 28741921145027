import React, {FC} from 'react';

import {useDispatch} from 'react-redux';

import HeroSection from './HeroSection/HeroSection';
import TableSection from './TableSection/TableSection';
import Snackbars from '../common/Snackbars/Snackbars';
import {useAppSelector} from '../../hooks/useAppSelector';
import {hideSnackbar} from '../../reducer/snackbarSlice';
import {ViewGift} from './TableSection/ViewGift/ViewGift';
import {ViewActivity} from './TableSection/ViewActivity/ViewActivity';
import {CreateGift} from './TableSection/TableGifts/CreateGift/CreateGift';
import {CreateActivity} from './TableSection/TableActivity/CreateActivity/CreateActivity';
import {ApplicationModal} from './TableSection/ApplicationModal/ApplicationModal';

export const Bonuses: FC = () => {
    const dispatch = useDispatch();
    const {isOpen, message, type, position} = useAppSelector((state) => state.snackbarReducer);
    const handleOpen = () => dispatch(hideSnackbar())
    return (
        <>
            <HeroSection/>
            <TableSection/>
            <Snackbars
                open={isOpen}
                setOpen={handleOpen}
                type={type}
                position={position}
                message={message}
            />
            <ViewGift/>
            <ViewActivity/>
            <CreateGift />
            <CreateActivity />
            <ApplicationModal/>
        </>
    );
};

export default Bonuses;
