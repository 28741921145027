import React from 'react';
import {Box, TextField, InputAdornment} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';
import {DatePicker} from '@mui/x-date-pickers';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '../../../../../hooks/useAppSelector';
import { setSearchName, setDateRange } from '../../../../../reducer/searchFiltersSlice';

export const SearchFilter = () => {
    const dispatch = useDispatch();
    const { searchName, createdAtFrom, createdAtTo } = useAppSelector((state) => state.searchFilters);

    const handleDateChange = (newValue: any, isFrom: boolean) => {
        const formattedDate = newValue ? dayjs(newValue).format("YYYY-MM-DD") : null;

        dispatch(
            setDateRange({
                createdAtFrom: isFrom ? formattedDate : createdAtFrom,
                createdAtTo: isFrom ? createdAtTo : formattedDate,
            })
        );
    };

    return (
        <Box my={2} sx={{display: 'flex', gap: 2}}>
            <TextField
                value={searchName}
                onChange={(e) => dispatch(setSearchName(e.target.value))}
                variant="outlined"
                size="small"
                sx={{flex: 1}}
                placeholder="Поиск по ФИО сотрудника"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon sx={{width: 20, height: 20}}/>
                        </InputAdornment>
                    ),
                }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="От"
                    value={createdAtFrom}
                    onChange={(newValue) => handleDateChange(newValue, true)}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" size="small" sx={{width: 150}}/>
                    )}
                    PopperProps={{
                        sx: { backgroundColor: "#131722" }
                    }}

                />

                <DatePicker
                    label="До"
                    value={createdAtTo}
                    onChange={(newValue) => handleDateChange(newValue, false)}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" size="small" sx={{width: 150}}/>
                    )}
                    closeOnSelect
                    PopperProps={{
                        sx: { backgroundColor: "#131722" }
                    }}
                />
            </LocalizationProvider>
        </Box>
    );
};
