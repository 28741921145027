import React, {FC, useContext, useState} from 'react';
import Stack from '@mui/material/Stack';
import {useDispatch} from "react-redux";
import { personController } from "../../../../services/person.controller";
import { profilesInitial } from "../../../../actions/profiles";
import Snackbars from "../../../common/Snackbars/Snackbars";
import { AppContext } from "../../../shared/AppContextProvider";
import { roles } from "../../../../constants/role";
import RemoveImageButton from "../../../common/Buttons/RemoveImageButton/RemoveImageButton";
import ConfirmModal from "../../../common/Modals/ConfirmModal/ConfirmModal";
import {Box} from "@mui/material";
import bg from "../../../../assets/images/backgroudDefault.png";
import CustomButton from "../../../common/Buttons/CustomButton/CustomButton";
import {PersonView} from "../../../../models/profile.models";
import Typography from "@mui/material/Typography";

interface EditAvatarProp {
  bgInfo: PersonView['background']
}

export const EditBackground: FC<EditAvatarProp> = ({ bgInfo }) => {
  const dispatch = useDispatch();

  const { user } = useContext(AppContext);
  const isNotUserRole = user.role !== roles.user;

  const userId = localStorage.getItem("userId") || "";
  const currentUserId = JSON.parse(
      localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
  ).id;
  const isCurrentUser = userId === currentUserId;

  const imgSrc = bgInfo
      ? `/loyalty-portal/api/attachments/${encodeURIComponent(
          bgInfo.name
      )}${bgInfo.extension}`
      : bg;

  const bgStyle = {
    backgroundImage: `linear-gradient(to bottom, rgba(19, 23, 34, 0.15) 0%, rgba(19, 23, 34, 0.75) 100%), url("${imgSrc}")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  const imageButtonsWrapperStyle = {
    width: "100px",
    height: "40px",
    display: "flex",
    ...bgStyle
  };

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const handleBackgroundButton = (event: any) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    personController
      .background(localStorage.getItem("userId"), formData)
      .then(() => {
        personController
          .person(localStorage.getItem("userId"))
          .then((result) => {
            dispatch(profilesInitial.editProfile(result.data));
          });
        event.target.value = "";
      })
      .catch(() => setOpenSnackbar(true));
  };

  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState<boolean>(false);
  const handleConfirmModal = () => {
    setIsConfirmModalOpened(prev => !prev);
  };
  const removeBackground = () => {
    personController
      .removeBackground(localStorage.getItem("userId"))
      .then(() => (
        personController
          .person(localStorage.getItem("userId"))
          .then((result) => {
            dispatch(profilesInitial.editProfile(result.data));
            handleConfirmModal();
          })
      ))
      .catch(() => {
        setOpenSnackbar(true);
      });
  };

  return (
      <Stack spacing='12px' direction='row' alignItems='center'>
        {/*<Typography*/}
        {/*    variant="body1"*/}
        {/*    color={(theme) => theme.palette.text.primary}*/}
        {/*>*/}
        {/*  Обои*/}
        {/*</Typography>*/}
        <Box sx={imageButtonsWrapperStyle} />
        {(isCurrentUser || isNotUserRole) && (
            <>
              <CustomButton
                  color='secondary'
                  attachButton
                  onChange={handleBackgroundButton}
                  acceptFile='image/*'
                  text='Заменить'
              />
              <RemoveImageButton
                  color="secondary"
                  onClick={handleConfirmModal}
                  disabled={!bgInfo}
              />
            </>
        )}
        <ConfirmModal
          openConfirmModal={isConfirmModalOpened}
          handleModal={handleConfirmModal}
          nameConfirmButton="Удалить"
          handleConfirm={removeBackground}
          text="Обои профиля будут удалены."
        />
        <Snackbars
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          type="error"
          position="center"
        />
      </Stack>
  );
};

export default EditBackground;
