import { apiUrlPortal } from "../constants/apiUrl";
import jwtInterceptor from "./jwtInterceptor";

export const titleController = {
  titles: async (page?: number): Promise<any> => {
    return await jwtInterceptor.get(
      `${apiUrlPortal}/titles?page=${page ?? ""}`
    );
  },
};
