import { apiUrlPortal } from "../constants/apiUrl";
import jwtInterceptor from "./jwtInterceptor";

export const downloadController = {
  download: async (fileName: string): Promise<any> => {
    return await jwtInterceptor.get(`${apiUrlPortal}/download/${fileName}`, {
      responseType: "blob",
    });
  },
};
