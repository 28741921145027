import {TStylesType} from '../../../../../types/Style.types';

export const rowCardTable: TStylesType = {
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '10px',
        padding: '12px',
        marginBottom: 1,
        width: '100%',
        boxSizing: 'border-box',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(203, 225, 255, 0.17)'
        }
    },
    row__content: {
        display: 'flex',
        alignItems: 'center',
    },
    row__icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    row__title: {
        fontSize: '13px',
        lineHeight: '12px',
    },
    row__cost: {
        fontSize: '11px',
        color: 'rgba(229, 235, 242, 0.4)',
    },
}
