import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import Stack from '@mui/material/Stack';
import InputMask from 'react-input-mask';

import InputWrapper from "../../common/Inputs/InputWrapper/InputWrapper";
import InputLine from "../../common/Inputs/InputLine/InputLine";
import { UserInfo, UserInfoValidation } from "../../../types/UserInfo.types";
import { checkEmail, checkPhoneNumber, checkTelegram, PHONE_PLACEHOLDER, } from '../validation';
import { isValidPhone, unformatPhone } from '../../../helpers/telephoneFormat';

interface ContactsProps {
  personData: UserInfo;
  setPersonData: Dispatch<SetStateAction<UserInfo>>;
  validation: UserInfoValidation;
}

export const Contacts: FC<ContactsProps> = ({
  personData,
  setPersonData,
  validation,
}) => {
  const [phoneHasBeenEdited, setPhoneHasBeenEdited] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);
  const [editing, setEditing] = useState(false);

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>, prop: keyof UserInfo) => {
    setPersonData({ ...personData, [prop]: event.target.value });
  };

  const handlePhone = (value?: string, isEditPhone?: boolean) => {
    if(!value || value?.length < 2) {
      setPhoneNumberError('Введите номер телефона')
      return false
    }
    if (value?.length < 12 && !isEditPhone) {
      setPersonData({ ...personData, phone: '' });
      setPhoneNumberError('Введите номер телефона')
      return
    }
    setPhoneHasBeenEdited(true)
    if (isValidPhone(value) || value?.length < 12) {
      setPersonData({ ...personData, ['phone']: value });
      setPhoneNumberError(`Введите номер телефона в виде ${PHONE_PLACEHOLDER}`);
      return
    }
    setPersonData({ ...personData, ['phone']: value });
    setPhoneNumberError(null)
  }

  useEffect(() => {
    if(!phoneHasBeenEdited) {
      handlePhone(personData?.phone);
    }
  }, [personData?.phone]);


  const data = [
    {
      name: "Контактный е-mail",
      element: (
        <InputLine
          testid="email"
          value={personData?.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeInput(e, "email")}
          placeholder="example@example.com"
          error={!validation.email}
          helperText={"Введите email в виде - ___@___.__"}
        />
      ),
    },
    {
      name: "Телефон",
      element: (
        <InputMask
          mask="+7\ (999) 999 99 99"
          value={personData?.phone}
          onChange={(e) => handlePhone(unformatPhone(e.target.value), true)}
        >
          {/*@ts-ignore*/}
          <InputLine
            testid="phone"
            placeholder={PHONE_PLACEHOLDER}
            error={Boolean(phoneNumberError)}
            helperText={phoneNumberError ?? ''}
          />
        </InputMask>
      ),
    },
    {
      name: "Telegram",
      element: (
        <InputLine
          testid="telegram"
          value={personData?.telegram}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeInput(e, "telegram")}
          placeholder="@name"
          error={!validation.telegram}
          helperText={"Введите имя, начиная с @"}
        />
      ),
    },
  ];
  return (
    <Stack spacing={4}>
      {data.map((item, index) => (
        <InputWrapper key={index} title={item.name} element={item.element} />
      ))}
    </Stack>
  );
};

export default Contacts;
