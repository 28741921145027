import { SystemStyleObject, Theme } from "@mui/system";

const keys = [
  "styleMainBox",
  "styleCard",
  "styleContent",
  "boxIconStyle",
] as const;

type StylesType = Record<(typeof keys)[number], SystemStyleObject<Theme>>;

export const styles: StylesType = {
  styleMainBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginLeft: "30px",
    marginTop: "6px",
    gap: "6px",
  },
  styleCard: {
    minWidth: 280,
    maxWidth: 666,
    height: "auto",
    backgroundColor: "#212633",
  },
  styleContent: {
    marginTop: "-12px",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
  boxIconStyle: {
    height: "16px",
    width: "16px",
    ml: "-26px",
    mr: "12px",
    borderRadius: "5px",
  },
};
