import React, { Dispatch, FC, SetStateAction, useState } from "react";

import { useCities } from "../../../../hooks/useCities";
import Combobox from "../../../common/Inputs/Combobox/Combobox";
import { ListItem } from "../../../../types/ListItem";
import { personController } from "../../../../services/person.controller";
import {TUserInfo} from '../../../../types/UserInfo.types';

interface CityComboboxProps {
  personData: TUserInfo;
  setPersonData: Dispatch<SetStateAction<TUserInfo>>;
  disabled?: boolean;
  width?: string;
  error?: string
}

export const CityCombobox: FC<CityComboboxProps> = ({
  personData,
  setPersonData,
  disabled,
  width,
  error = false
}) => {
  const [pageCity, setPageCity] = useState<number>(0);
  const cities = useCities(pageCity);

  const [searchCity, setSearchCity] = useState<string>("");
  const handleSearchCity = (
    e: React.SyntheticEvent,
    value: string,
    reason: string
  ) => {
    setSearchCity(value);
    if (reason !== "reset") {
      if (value) {
        personController.cities(undefined, `name:${value}`).then((res) => {
          cities.setCities(res.data.items);
          cities.setSize(res.data.totalItems);
        });
      } else {
        personController.cities(0).then((res) => {
          cities.setCities(res.data.items);
          cities.setSize(res.data.totalItems);
        });
      }
    }
  };

  const handleScroll = (event: any) => {
    const target = event.target;
    if (
      target.scrollTop + target.clientHeight >= target.scrollHeight &&
      cities.cities.length < cities.size
    ) {
      setPageCity((prev) => prev + 1);
    }
  };

  const handleChangeCombobox = (event: any, newValue: ListItem | null) => {
    setPersonData({ ...personData, city: newValue });
  };

  return (
    <Combobox
      options={cities.cities}
      value={personData?.city}
      onChange={handleChangeCombobox}
      placeholder="Начните вводить название"
      styles={{ width: width }}
      inputValue={searchCity}
      onInputChange={handleSearchCity}
      handleScrollBottom={handleScroll}
      disabled={disabled}
      error={error}
    />
  );
};

export default CityCombobox;
