import { useEffect, useRef } from "react";

export const useScrollShadow = <T extends HTMLElement>() => {
    const scrollBoxRef = useRef<T | null>(null);

    const applyShadowEffect = () => {
        const element = scrollBoxRef.current;

        if (!element) return;

        const isFullyVisible = element.scrollTop + element.clientHeight >= element.scrollHeight;

        if (isFullyVisible) {
            element.style.boxShadow = "none";
            element.style.borderBottom = "none";
        } else {
            element.style.boxShadow = "0px 4px 10px rgba(203, 225, 255, 0.17)";
            element.style.borderBottom = "1px solid rgba(203, 225, 255, 0.17)";
        }
    };

    useEffect(() => {
        const element = scrollBoxRef.current;

        if (element) {
            applyShadowEffect(); // Проверяем тень при монтировании

            element.addEventListener("scroll", applyShadowEffect);
            return () => {
                element.removeEventListener("scroll", applyShadowEffect);
            };
        }
    }, []);

    return { scrollBoxRef, applyShadowEffect };
};


