import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SnackbarState {
    isOpen: boolean;
    message: string;
    type: 'success' | 'error' | 'info';
    position: 'center' | 'left';
}

const initialState: SnackbarState = {
    isOpen: false,
    message: '',
    type: 'success',
    position: 'center',
};

const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        showSnackbar: (
            state,
            action: PayloadAction<{ message: string; type?: SnackbarState['type']; position?: SnackbarState['position'] }>
        ) => {
            state.isOpen = true;
            state.message = action.payload.message;
            state.type = action.payload.type || 'success';
            state.position = action.payload.position || 'center';
        },
        hideSnackbar: (state) => {
            state.isOpen = false;
            state.message = '';
        },
    },
});

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
