import React, {FC} from 'react';
import {Box, Typography} from '@mui/material';

import CustomButton from '../../../../common/Buttons/CustomButton/CustomButton';
import {useAppSelector} from '../../../../../hooks/useAppSelector';
import {toggleModal} from '../../../../../reducer/bonusesModalSlice';
import {useAppDispatch} from '../../../../../hooks/useAppDispatch';
import {useApplicationActions} from '../helpers/useApplicationActions';

interface ConfirmationContentProps {
    typeButton: 'approve' | 'cancel' | 'clearing';
    close: () => void;
    action: string;
}

const BUTTON_TEXTS: Record<string, string> = {
    approve: 'Подтвердить',
    cancel: 'Отклонить',
    clearing: 'Вернуть на уточнение',
};

const MESSAGE_TEXTS: Record<string, string> = {
    approve: 'подтвердить',
    cancel: 'отклонить',
    clearing: 'вернуть на уточнение',
};

export const ConfirmationContent: FC<ConfirmationContentProps> = ({typeButton, close, action}) => {
    const actionText = BUTTON_TEXTS[typeButton] || '';
    const messageText = MESSAGE_TEXTS[typeButton] || '';
    const {selectedEntityId} = useAppSelector((state) => state.bonusesModal);
    const {getAction} = useApplicationActions();
    const dispatch = useAppDispatch();
    const handleClose = () => {
        close();
    };

    const handleConfirmAction = async () => {
        if (!typeButton || !selectedEntityId) return;

        if (typeButton && selectedEntityId) {
            try {
                await getAction(typeButton, action as 'managementGifts' | 'managementActivity')(selectedEntityId);
                dispatch(toggleModal({type: null, isOpenView: false}));
                handleClose();
            } catch (error) {
                console.error('Ошибка при выполнении запроса:', error);
            }
        }
    };
    return (
        <>
            <Typography variant="h6">Подтвердите действие</Typography>
            <Typography>
                {`Вы уверены, что хотите ${messageText} заявку?`}
            </Typography>
            <Box display="flex" justifyContent="flex-end" mt={3} gap={2}>
                <CustomButton text="Отмена" color="secondary" onClick={close}/>
                <CustomButton text={actionText} color="primary" onClick={handleConfirmAction}/>
            </Box>
        </>

    );
};