import React, {useContext} from 'react';
import DialogWrapper from "../../common/Modals/DialogWrapper/DialogWrapper";
import CustomButton from "../../common/Buttons/CustomButton/CustomButton";
import Typography from "@mui/material/Typography";
import {ReviewContext} from "../RewievContextProvider/ReviewInProfileContextProvider";
import {styleButton, styleDialogContentPaddingBottom, styleDialogWrapper} from "./styles/approvedModalStyles";


const ApprovedModal = () => {
    const {openApprovedModal, handleApprovedModal} = useContext(ReviewContext)

    return (
        <DialogWrapper
            openModal={openApprovedModal}
            handleModal={handleApprovedModal}
            width="532px"
            stylesContent={{...styleDialogWrapper, ...styleDialogContentPaddingBottom}}
            pActions={'24px'}
            styleActionButtons={styleDialogWrapper}
            buttons={
                <CustomButton
                    color="primary"
                    onClick={handleApprovedModal}
                    styles={styleButton}
                    text={'Хорошо'}
                />
            }>
            <Typography variant={'subtitle1'}>Спасибо, ваш голос засчитан</Typography>
        </DialogWrapper>
    );
};

export default ApprovedModal;