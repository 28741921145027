
/**
 *
 * @description transform from +79999999999 to +7 (999) 999 99 99
 */
export function formatPhone(phone?: string) {
  if (!phone) return ''
  if (phone.length !== 12 || phone[0] !== '+') return phone;

  return (
    phone[0] + phone[1] + " (" +
    phone.slice(2, 5) + ") " +
    phone.slice(5, 8) + " " +
    phone.slice(8, 10) + " " +
    phone.slice(10, 12)
  );
}


/**
 *
 * @description transform from +7 (999) 999 99 99 to +79999999999
 */
export function unformatPhone(formatted?: string): string {
  if (!formatted) return ''
  if (!formatted?.includes('(')) {
    return formatted
  }
  let cleanPhone = "";

  for (let i = 0; i < formatted.length; i++) {
    let char = formatted[i];
    if (char >= '0' && char <= '9' || char === '+') {
      cleanPhone += char;
    }
  }

  return cleanPhone;
}

/**
 *
 * @description checks the phone for format compliance +7 (999) 999 99 99
 */
export function isValidPhone(formatted: string): boolean {
  if (formatted.length !== 16) return false;
  if (formatted[0] !== '+' || formatted[1] !== '7') return false;
  if (formatted[2] !== ' ' || formatted[3] !== '(' || formatted[7] !== ')') return false;
  if (formatted[8] !== ' ' || formatted[12] !== ' ' || formatted[15] !== ' ') return false;

  for (let i = 4; i < 7; i++) {
    if (formatted[i] < '0' || formatted[i] > '9') return false;
  }
  for (let i = 9; i < 11; i++) {
    if (formatted[i] < '0' || formatted[i] > '9') return false;
  }
  for (let i = 13; i < 15; i++) {
    if (formatted[i] < '0' || formatted[i] > '9') return false;
  }

  return true;
}


/**
 *
 * @description checks the phone for format compliance +79999999999
 */
export function isValidCleanPhone(phone?: string): boolean {
  if (!phone || typeof phone !== 'string') return false; // Проверка типа
  if (phone.length !== 12) return false; // Длина должна быть 12 символов
  if (phone[0] !== "+" || phone[1] !== "7") return false; // Должно начинаться с +7

  // Проверяем, что остальные 10 символов — цифры
  for (let i = 2; i < phone.length; i++) {
    if (phone[i] < "0" || phone[i] > "9") return false;
  }

  return true;
}