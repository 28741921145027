import React, { FC } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from '@mui/material/Stack';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

type InputWrapperArrayElement = {
  title: string;
  element: React.ReactElement;
}

interface InputWrapperArrayProps {
  items?: InputWrapperArrayElement[];
  flexDirection?: "column" | "row";
  titleColor?: "primary" | "secondary";
  styles?: any;
}
export const InputWrapperArray: FC<InputWrapperArrayProps> = ({
  items = [],
  flexDirection = "row",
  titleColor = "primary",
  styles,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const styleFlex = {
    display: isMobile ? "flex" : "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))",
    alignItems: "baseline",
    gap: isMobile ? "8px" : "12px",
    flexDirection: isMobile ? "column" : flexDirection,
  };

  return (
      <Box sx={styleFlex}>
        {items.map(({ title, element }, idx) => (
            <Stack width='100%' useFlexGap spacing={isMobile ? 1 : 3} key={idx} flexDirection={isMobile ? 'column' : 'row'}>
              <Typography
                  variant="body1"
                  minWidth={!idx ? 192 : 'fit-content'}
                  color={(theme) =>
                      titleColor === "secondary"
                          ? theme.palette.text.secondary
                          : theme.palette.text.primary
                  }
              >
                {title}
              </Typography>
              {element}
            </Stack>
          ))
        }
      </Box>
  );
};

export default InputWrapperArray;
