import React, { FC, ReactNode } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Checkbox, SelectChangeEvent } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';

interface IProps {
  data: {
    value: string
    name: string
  }[]
  selectedValues: string[]
  onChange: (e: SelectChangeEvent<string[]>) => void
  placeholder: string
  renderValue: (values: string[]) => string
  onClose?: () => void
  open?: boolean
  onlyMenu?: boolean
  actions?: ReactNode[]
}

const MultiSelect: FC<IProps> = ({ data, onlyMenu, open, selectedValues, onChange, placeholder, renderValue, actions, ...props }) => {
  const getPlaceholder = (selected: string[]) => {
    if (onlyMenu) {
      return ''
    }
    return selected.length === 0 ? placeholder : renderValue(selected)
  }

  return (
    <Select
      open={open}
      multiple
      value={selectedValues}
      onChange={onChange}
      displayEmpty
      renderValue={(selected) => getPlaceholder(selected)}
      sx={onlyMenu ? {
        backgroundColor: "rgba(0,0,0,0)",
        border: "none",
        boxShadow: "none",
        opacity: "0",
        visibility: 'hidden',
        width: "0",
        height: "0",
        "& .MuiOutlinedInput-notchedOutline": { border: "none" }, // Убираем границу
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": { border: "none" }, // Даже при фокусе
        "&:hover .MuiOutlinedInput-notchedOutline": { border: "none" }, // При наведении
        "& .MuiSelect-icon": { display: "none" }, // Убираем стрелку
      } : undefined}
      MenuProps={{
        PaperProps: {
          sx: {
            backgroundColor: "#212632",
            boxShadow: 3,
          },
        },
      }}
      {...props}
    >
      {data.map(item => (
        <MenuItem key={item.value} value={item.value}>
          <Checkbox checked={selectedValues.indexOf(item.value) > -1} />
          <ListItemText primary={item.name} />
        </MenuItem>
      ))}
      {actions?.length && actions}
      {!data?.length &&
        <MenuItem value={''} disabled={true}>
          <ListItemText primary="Список пуст"/>
        </MenuItem>
      }
    </Select>
  );
};

export default MultiSelect;