import React, { FC } from "react";

import { useTheme } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { TAchiveStatus } from "../../../../types/Achievements.types";
import { IdeaStatus } from "../../../../types/IdeaStatus.type";

interface CustomToggleButtonsProps<T> {
  items: T[];
  onChange?: (newValue: any) => void;
  currentValue?: string;
  translator?: (props?: any) => string;
  disabled?: boolean;
  styles?: any;
  icons?: (JSX.Element | undefined)[];
}

export const CustomToggleButtons: FC<
  CustomToggleButtonsProps<TAchiveStatus | IdeaStatus | string>
> = ({
  items,
  onChange,
  currentValue,
  translator,
  disabled,
  styles,
  icons,
}) => {
  const theme = useTheme();

  const handleToggleButtonGroup = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | TAchiveStatus | IdeaStatus
  ) => {
    //enforce that at least one button must be active
    if (newValue !== null) {
      onChange?.(newValue);
    }
  };

  const styleGroup = {
    flexWrap: "wrap",
    rowGap: "8px",
    "& .MuiToggleButtonGroup-grouped": {
      mr: "8px",
      border: 0,
      "&.MuiToggleButton-root": {
        borderRadius: "60px",
        backgroundColor: "rgba(203, 225, 255, 0.17)",
        fontSize: "14px",
        lineHeight: "20px",
        color: theme.palette.text.secondary,
        padding: "10px 12px",
        marginLeft: "0",
      },
      "&.MuiToggleButton-root:hover": {
        backgroundColor: "rgba(185, 215, 255, 0.08)",
      },
      "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: "rgba(24, 132, 255, 1)",
        color: theme.palette.text.primary,
      },
      "&.MuiToggleButton-root.Mui-disabled": {
        border: 0,
        pointerEvents: "none",
      },
    },
    ...styles,
  };

  const buttonStyle = {
    display: "inline-block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "200px",
  };

  return (
    <ToggleButtonGroup
      value={currentValue}
      exclusive
      onChange={handleToggleButtonGroup}
      aria-label="roles"
      sx={styleGroup}
      disabled={disabled}
    >
      {items.map((item, index) => (
        <ToggleButton
          value={item}
          aria-label={item}
          key={index}
          sx={buttonStyle}
          title={translator ? translator(item) : item}
        >
          {icons?.[index]}{translator ? translator(item) : item}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default CustomToggleButtons;
