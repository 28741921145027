import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type ModalType = 'gift' | 'activity' | 'createGift' | 'createActivity' | 'delete' | 'editeGift' | 'editeActivity' | 'managementGifts' | 'managementActivity' | null;

interface ModalState {
    isOpenView: boolean;
    modalType: ModalType;
    selectedEntityId: string;
}

const initialState: ModalState = {
    isOpenView: false,
    modalType: null,
    selectedEntityId: '',
};

const bonusesModalSlice = createSlice({
    name: 'bonusesModal',
    initialState,
    reducers: {
        toggleModal(
            state,
            action: PayloadAction<{type: ModalType; isOpenView: boolean; id?: string}>
        ) {
            state.isOpenView = action.payload.isOpenView;
            state.modalType = action.payload.isOpenView ? action.payload.type : null;
            state.selectedEntityId = action.payload.isOpenView ? action.payload.id ?? '' : '';
        },
    },
});

export const {toggleModal} = bonusesModalSlice.actions;
export default bonusesModalSlice.reducer;

