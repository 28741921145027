import React, { FC, useContext } from "react";
import { AppContext } from "../../shared/AppContextProvider";

import TopPanel from "../TopPanel/TopPanel";
import KnowledgeSections from "../KnowledgeSections/KnowledgeSections";
import CreateKnowledgeButton from "../TopPanel/CreateKnowledgeButton/CreateKnowledgeButton";

export const SectionsPage: FC = () => {
  const { isMobile } = useContext(AppContext);

  return (
    <>
      <TopPanel />
      <KnowledgeSections />
      {isMobile && <CreateKnowledgeButton />}
    </>
  );
};

export default SectionsPage;
