import {MenuListItems} from "../../../types/MenuListItems.types";
import SvgIcon from "@mui/material/SvgIcon";
import React from "react";
import {roles} from "../../../constants/role";

import { ReactComponent as LentaIcon } from "../../../assets/icons/lenta.svg";
import { ReactComponent as KnowledgeIcon } from "../../../assets/icons/knowledge.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar.svg";
import { ReactComponent as CompanyIcon } from "../../../assets/icons/company.svg";
import { ReactComponent as IdeasIcon } from "../../../assets/icons/ideas.svg";
import { ReactComponent as LentaInactiveIcon } from "../../../assets/icons/lentaInactive.svg";
import { ReactComponent as KnowledgeInactiveIcon } from "../../../assets/icons/knowledgeInactive.svg";
import { ReactComponent as CalendarInactiveIcon } from "../../../assets/icons/calendarInactive.svg";
import { ReactComponent as CompanyInactiveIcon } from "../../../assets/icons/companyInactive.svg";
import { ReactComponent as IdeasInactiveIcon } from "../../../assets/icons/ideasInactive.svg";
import { ReactComponent as MoreIcon } from "../../../assets/icons/more.svg";
import { ReactComponent as MoreInactiveIcon } from "../../../assets/icons/moreInactive.svg";
import { ReactComponent as ReviewInactiveIcon } from "../../../assets/icons/review_inactive.svg";
import { ReactComponent as ReviewSelectedIcon } from "../../../assets/icons/review_selected.svg";

import { ReactComponent as SettingsIcon } from "../../../assets/icons/settings.svg";
import { ReactComponent as SettingsInactiveIcon  } from "../../../assets/icons/settingsInactive.svg";
import {iconProps} from "./styles/styles";

/**
 * The last element must remain 'Еще'
 * Related filter logic in BottomNavigations.tsx
 */

const {admin,editor} = roles

export const navigationItems: MenuListItems = [
    {
        name: "Лента",
        icon: <SvgIcon component={LentaIcon} {...iconProps} />,
        inactiveIcon: <SvgIcon component={LentaInactiveIcon} {...iconProps} />,
        href: "/feed",
    },
    {
        name: "Календарь",
        icon: <SvgIcon component={CalendarIcon} {...iconProps} />,
        inactiveIcon: <SvgIcon component={CalendarInactiveIcon} {...iconProps} />,
        href: "/calendar",
    },
    {
        name: "Компания",
        icon: <SvgIcon component={CompanyIcon} {...iconProps} />,
        inactiveIcon: <SvgIcon component={CompanyInactiveIcon} {...iconProps} />,
        href: "/company",
    },
    {
        name: "Ревью",
        icon: <SvgIcon component={ReviewSelectedIcon} {...iconProps} />,
        inactiveIcon: <SvgIcon component={ReviewInactiveIcon} {...iconProps} />,
        href: "/review",
        requiredRoles:[admin,editor]
    },
    {
        name: "Идеи",
        icon: <SvgIcon component={IdeasIcon} {...iconProps} />,
        inactiveIcon: <SvgIcon component={IdeasInactiveIcon} {...iconProps} />,
        href: "/ideas",
    },
    {
        name: "База знаний",
        icon: <SvgIcon component={KnowledgeIcon} {...iconProps} />,
        inactiveIcon: (
            <SvgIcon component={KnowledgeInactiveIcon} {...iconProps} />
        ),
        href: "/knowledge",
    },
    {
        name: "Бонусы",
        icon: <SvgIcon component={SettingsIcon} {...iconProps} />,
        inactiveIcon: <SvgIcon component={SettingsInactiveIcon} {...iconProps} />,
        href: "/bonuses",
    },
    {
        name: "Ещё",
        icon: <SvgIcon component={MoreIcon} {...iconProps} />,
        inactiveIcon: <SvgIcon component={MoreInactiveIcon} {...iconProps} />,
        href: "/more",
    },
];