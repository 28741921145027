import React, { FC } from "react";

import {
  FormControl,
  Select,
  MenuItem,
	SelectChangeEvent,
} from "@mui/material";

interface SectionModalProps {
	value: string;
	countsSequence: number;
  onChange: (e: SelectChangeEvent) => void;
}

export const KbListSequence: FC<SectionModalProps> = ({
  value = "",
  countsSequence = 0,
  onChange,
}) => {
  const createSelectionMenu = () => {
    let menuItems = [];
    for (let index = 0; index < (countsSequence); index++) {
      menuItems[index] = <MenuItem key={index} value={String(index + 1)}>{ index + 1 }</MenuItem>
    };    

    return (
      <FormControl size="small" sx={{ minWidth: 120 }}>
        <Select
          value={value}
          onChange={onChange}
          displayEmpty
          MenuProps={{
            sx: styleMenu
          }}
        >
          <MenuItem value={""} sx={{display: 'none'}}>Не выбрано</MenuItem>
          { menuItems.map((el) => el) }
        </Select>
      </FormControl>
    )
  }

  const styleMenu = {
    "& .MuiMenu-paper": {
      backgroundColor: "#212633",
    },
  };

  return (
		createSelectionMenu()
  );
};

export default KbListSequence;
