import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrlPortal } from '../constants/apiUrl';
import { ProjectView } from '../models/projects.models';
import { addAuthTokenToRTKQHeaders } from '../helpers/addAuthTokenToRTKQHeaders';

type ProjectInteractionRequest = {
  isExternal: boolean;
  name?: string
}

interface Project {
  belongToUser: boolean;
  id: string;
  isExternal: boolean;
  name: string;
}

export const projectsApi = createApi({
  reducerPath: 'projectsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiUrlPortal}/projects`,
    mode: 'cors',
    prepareHeaders: addAuthTokenToRTKQHeaders,
  }),
  endpoints: (builder) => ({
    fetchAllProjects: builder.query<Project[], void>({
      query: () => ({
        url: ''
      }),
    }),
    getByUser: builder.query<ProjectView[], string>({
      query: (userId) => ({
        url: '',
        params: { userId }
      })
    }),
    create: builder.mutation<ProjectView, ProjectInteractionRequest>({
      query: (payload) => ({
        url: '',
        method: 'POST',
        body: payload
      }),
    }),
    update: builder.mutation<ProjectView, { id: string, payload: ProjectInteractionRequest }>({
      query: (data) => ({
        url: `/${data.id}`,
        method: 'PUT',
        body: data.payload
      }),
    }),
    delete: builder.query<ProjectView[], string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      })
    }),
    addForPerson: builder.query<ProjectView[], { personId: string, projectId: string }>({
      query: ({personId, projectId}) => ({
        url: `/add-to-person/${personId}/${projectId}`,
        method: 'PATCH',
      })
    }),
    removeFromPerson: builder.query<ProjectView[], { personId: string, projectId: string }>({
      query: ({personId, projectId}) => ({
        url: `/remove-from-person/${personId}/${projectId}`,
        method: 'PATCH',
      })
    }),
  })
})
