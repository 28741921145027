import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { profileController } from '../../../../services/profile.controller';
import { IProjectPersonView, UserInfo } from '../../../../types/UserInfo.types';
import { SelectChangeEvent } from '@mui/material';
import MultiSelect from '../../../common/MultiSelect/MultiSelect';
import { formatCount } from '../../../../helpers/formatCount';
import { projectsApi } from '../../../../reducer/projects';

interface IProps {
  personData: UserInfo;
  setPersonData: Dispatch<SetStateAction<UserInfo>>;
  disabled: boolean
}

const Projects: FC<IProps> = ({ personData, setPersonData, disabled }) => {
  // states
  const [self, setSelf] = useState<any>({});
  const { data: projects } = projectsApi.useGetByUserQuery(self?.id || '')
  const [selectedProjects, setSelectedProjects] = useState<IProjectPersonView[]>(personData.projects || [])

  // handlers
  const handleChange = (e: SelectChangeEvent<string[]>) => {
    const selected = (e.target.value as string[]).map(id => projects?.find(p => p.id === id)) as IProjectPersonView[];
    setSelectedProjects(selected)
  }

  const setChanges = () => {
    setPersonData({
      ...personData,
      projects: selectedProjects
    })
  }

  const setRenderValue = (selected: string[]) => {
    return selected.length > 1 ? formatCount(selected.length, 'проект', true) : projects?.find(p => p.id === selected[0])?.name as string
  }

  const getAllProjects = async () => {
    const person = await profileController.profile()
    setSelf(person?.data)
  }

  // observers
  useEffect(() => {
    getAllProjects()
  }, [])

  useEffect(() => {
    if (!selectedProjects.length && personData.projects) {
      setSelectedProjects(personData.projects)
    }
  }, [personData.projects])

  return (
    <MultiSelect
      data={projects?.map(p => ({ value: p.id, name: p.name })) || []}
      selectedValues={selectedProjects.map(p => p.id)}
      onChange={handleChange}
      placeholder={'Выберите проекты'}
      renderValue={(selected) => setRenderValue(selected)}
      onClose={setChanges}
    />
  );
};

export default Projects;