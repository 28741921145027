export const getPointsText = (count: number): string => {
    const integerPart = Math.floor(count);
    const isFractional = count % 1 !== 0;

    if (isFractional) {
        return 'балла';
    }

    const lastDigit = integerPart % 10;
    const lastTwoDigits = integerPart % 100;

    switch (true) {
        case lastTwoDigits >= 11 && lastTwoDigits <= 14:
            return 'баллов';

        case lastDigit === 1:
            return 'балл';

        case lastDigit >= 2 && lastDigit <= 4:
            return 'балла';

        default:
            return 'баллов';
    }
};
