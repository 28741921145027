import { useState, useEffect } from "react";

import { titleController } from "../services/title.controller";
import { TemplateView } from "../models/profile.models";

export const useJobTitiles = (page?: number) => {
  const [titles, setTitles] = useState<TemplateView[]>([]);
  const [size, setSize] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (loading) {
      return;
    }

    setLoading(true);

    titleController.titles(page).then((res) => {
      setTitles([...titles, ...res.data.items]);
      setSize(res.data.totalItems);
    }).finally(() => {
      setLoading(false);
    });
    /* eslint-disable-next-line */
  }, [page]);

  return { titles, setTitles, size, setSize, loading };
};
