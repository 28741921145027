import React, {FC, memo} from 'react';
import ModalButtons from '../../../common/Buttons/ModalButtons/ModalButtons';

export const ModalActionButtons: FC<{ onCancel: () => void; onSubmit: () => void }> = memo(({ onCancel, onSubmit }) => (
    <ModalButtons
        nameButtonSend="Отправить"
        nameButtonCancel="Отмена"
        handleCancel={onCancel}
        handleSend={onSubmit}
        justifyContent="end"
        fullWidth
    />
));
