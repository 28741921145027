import {SystemStyleObject, Theme} from '@mui/system';

export const headerTable: { [key: string]: SystemStyleObject<Theme> } = {
    container: {
        display: 'flex',
        height: 60,
        width: '100%'
    },
    column: {
        margin: 2,
        color: 'rgba(230, 237, 245, 0.9)'
    }

}