import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";

import { Box, Stack, Typography } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import ViewModal from "../../TaskModals/ViewModal/ViewModal";
import { TaskView } from "../../../../../models/achievement.models";
import SelectedTag from "../../Tags/SelectedTag/SelectedTag";
import { achievementsInitial } from "../../../../../actions/achievements";
import { useAppSelector } from "../../../../../hooks/useAppSelector";

interface TaskProps {
  task: TaskView;
  index: number;
}

export const Task: FC<TaskProps> = ({ task, index }) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { blocked } = useAppSelector((state) => state.profileReducer.editProfile);
  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const handleClickTask = () => {
    handleModal();
    dispatch(achievementsInitial.achievementTask(task));
  };

  const styleBox = (isDragging: boolean) => {
    return {
      marginBottom: "4px",
      padding: "12px",
      borderRadius: 2,
      backgroundColor: isDragging
        ? "rgba(203, 225, 255, 0.17)"
        : "rgba(185, 215, 255, 0.08)",
      backdropFilter: "blur(12.5px)",
      "&:hover": {
        backgroundColor: "rgba(203, 225, 255, 0.17)",
      },
    };
  };

  const styleTitle = {
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  };

  return (
    <>
      <Draggable draggableId={task.id} index={index} isDragDisabled={blocked}>
        {(provided, snapshot) => (
          <Box
            sx={styleBox(snapshot.isDragging)}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            onClick={handleClickTask}
          >
            <Stack gap={"4px"}>
              {task.tags.map((tag) => (
                <SelectedTag tag={tag} key={tag.id} />
              ))}
              <Typography variant="button" sx={styleTitle}>
                {task.header}
              </Typography>
            </Stack>
          </Box>
        )}
      </Draggable>
      <ViewModal openModal={openModal} handleOpen={handleModal} />
    </>
  );
};
