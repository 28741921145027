import {useDispatch} from 'react-redux';
import {downloadController} from '../../../services/download.controller';
import {showSnackbar} from '../../../reducer/snackbarSlice';

export const useFileDownloader = () => {
    const dispatch = useDispatch();

    return async (filename: string, downloadName: string) => {
        try {
            const response = await downloadController.download(filename);
            const url = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', downloadName);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch {
            dispatch(showSnackbar({
                message: 'Ошибка скачивания файла',
                type: 'error',
                position: 'center',
            }));
        }
    };
};
