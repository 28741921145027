import {MenuListItems} from "../../../../types/MenuListItems.types";

interface IUseMenuItemsParams {
    filteredRolesItems: MenuListItems;
}

interface IUseMenuItemsReturn {
    menuItems: MenuListItems;
    mobileMenuItems: MenuListItems;
    hiddenItems: MenuListItems;
}

export const useMenuItems = ({filteredRolesItems}: IUseMenuItemsParams): IUseMenuItemsReturn => {
    let menuItems: MenuListItems = [];
    let mobileMenuItems: MenuListItems;

    if (filteredRolesItems.length <= 5) {
        mobileMenuItems = filteredRolesItems.filter((item) => item.name !== "Ещё");
    } else {
        const moreItemIndex = filteredRolesItems.findIndex((item) => item.name === "Ещё");
        const moreItem = moreItemIndex !== -1 ? filteredRolesItems[moreItemIndex] : null;

        const firstFour = filteredRolesItems.filter((item) => item.name !== "Ещё").slice(0, 4);
        mobileMenuItems = moreItem ? [...firstFour, moreItem] : firstFour;
        menuItems = firstFour
    }

    let hiddenItems: MenuListItems = [];
    if (filteredRolesItems.length > 5) {
        hiddenItems = filteredRolesItems.filter((item) => item.name !== "Ещё").slice(4);
    }

    return {menuItems, mobileMenuItems, hiddenItems};
}