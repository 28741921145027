import React, { ReactElement } from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';

interface IProps {
  children: ReactElement
  actions?: ReactElement[]
}

const AboutPaper = ({ children, actions }: IProps) => {
  const theme = useTheme();

  const stylePaper = {
    py: 2,
    px: 2,
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
    minHeight: "100px",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  };

  return (
    <Paper sx={stylePaper}>
      {children}
      <Stack display="flex" direction="row" justifyContent="end" width="100%" gap="0.5rem">
        {actions}
      </Stack>
    </Paper>
  )
}

export default AboutPaper;