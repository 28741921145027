import React, { FC, useContext, useEffect, useState } from "react";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import { useDispatch } from "react-redux";

import { InputBase, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";

import DialogWrapper from "../../../../common/Modals/DialogWrapper/DialogWrapper";
import ModalButtons from "../../../../common/Buttons/ModalButtons/ModalButtons";
import StatusBar from "../StatusBar/StatusBar";
import { achievementController } from "../../../../../services/achievement.controller";
import { AchievContext } from "../../../AchievContextProvider/AchievContextProvider";
import { translateTaskStatus } from "../../../../../translators/taskStatus.translate";
import { TaskView } from "../../../../../models/achievement.models";
import { achievementsInitial } from "../../../../../actions/achievements";
import { AppContext } from "../../../../shared/AppContextProvider";

interface EditableModalProps {
  open: boolean;
  handleOpen: () => void;
  type: "create" | "edit";
}

const maxTaskNameLength = 500;
const maxTaskDescriptionLength = 10000;

export const EditableModal: FC<EditableModalProps> = ({
  open,
  handleOpen,
  type,
}) => {
  const dispatch = useDispatch();
  const isCreate = type === "create";
  const achievId = sessionStorage.getItem("achievementId") || "";
  const task: TaskView = useAppSelector(
    (state) => state.achievementsReducer.achievementTask
  );
  const { isMobile } = useContext(AppContext);

  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  useEffect(() => {
    setTitle(isCreate ? "" : task.header);
    setDescription(isCreate ? "" : task.content);
    /* eslint-disable-next-line */
  }, []);

  const {
    observerList,
    tagList,
    setTagList,
    handleSnackbar,
    status,
    handleTasks,
  } = useContext(AchievContext);

  const handleTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handleModal = () => {
    handleOpen();
    setTitle("");
    setDescription("");
    setTagList([]);
  };

  const updTasks = () => {
    achievementController
      .tasks(achievId)
      .then((res) => handleTasks(res.data.items))
      .catch(() => handleSnackbar("error"));
  };

  const serviceCreate = () => {
    const formData = new FormData();
    formData.append(
      "request",
      new Blob(
        [
          JSON.stringify({
            content: description,
            header: title,
            observerIds: observerList.map((user) => user.id),
            status,
            tagIds: tagList.map((item) => item.id),
          }),
        ],
        {
          type: "application/json",
        }
      )
    );

    achievementController
      .createTask(achievId, formData)
      .then(() => {
        updTasks();
        handleModal();
      })
      .catch(() => handleSnackbar("error"));
  };

  const serviceEdit = () => {
    const request = {
      content: description,
      header: title,
      observerIds: null,
    };

    achievementController
      .editTask(achievId, task.id, request)
      .then((result) => {
        dispatch(achievementsInitial.achievementTask(result.data));
        updTasks();
        handleModal();
      })
      .catch(() => handleSnackbar("error"));
  };

  const handleSaveButton = () => {
    if (isCreate) {
      serviceCreate();
    } else {
      serviceEdit();
    }
  };

  const buttons = (
    <ModalButtons
      handleCancel={handleModal}
      handleSend={handleSaveButton}
      disableSend={!title || !description}
      fullWidth
      nameButtonSend="Сохранить"
      justifyContent="flex-start"
    />
  );

  const content = (
    <>
      <InputBase
        placeholder="Начните вводить название"
        fullWidth
        multiline
        sx={{ fontSize: "32px", fontWeight: 700, lineHeight: "40px" }}
        value={title}
        inputProps={{ maxLength: maxTaskNameLength }}
        onChange={handleTitle}
      />
      <InputBase
        sx={{ fontSize: "16px", lineHeight: "24px", mt: 2 }}
        fullWidth
        placeholder="Начните вводить описание"
        multiline
        value={description}
        inputProps={{ maxLength: maxTaskDescriptionLength }}
        onChange={handleDescription}
      />
    </>
  );

  const statusBar = (
    <StatusBar type={type} task={!isCreate ? task : undefined} />
  );

  const mobileList = (
    <Stack gap={3}>
      {content}
      {statusBar}
    </Stack>
  );

  return (
    <DialogWrapper
      openModal={open}
      handleModal={handleModal}
      width="862px"
      buttons={isMobile ? buttons : undefined}
    >
      {isMobile ? (
        mobileList
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          wrap="nowrap"
          gap={6}
        >
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            laptop={8}
          >
            <Grid item gap={2}>
              {content}
            </Grid>
            <Grid
              item
              sx={{ borderTop: "1px solid rgba(241, 247, 255, 0.10)", pt: 2 }}
            >
              {buttons}
            </Grid>
          </Grid>
          <Grid item laptop={4}>
            {statusBar}
          </Grid>
        </Grid>
      )}
    </DialogWrapper>
  );
};

export default EditableModal;
