import React, {FC, memo, useEffect} from 'react';
import DialogWrapper from '../../../common/Modals/DialogWrapper/DialogWrapper';
import {useAppDispatch} from '../../../../hooks/useAppDispatch';
import {useAppSelector} from '../../../../hooks/useAppSelector';
import {toggleModal} from '../../../../reducer/bonusesModalSlice';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {getPointsText} from '../../helpers/getPointsText';
import {useLazyGetActivityByIDQuery} from '../../../../services/activity.controller';
import Divider from '@mui/material/Divider';
import {LoadProgress} from '../../../common/LoadProgress/LoadProgress';

export const ViewActivity: FC = () => {
    const dispatch = useAppDispatch();

    const {isOpenView, modalType, selectedEntityId} = useAppSelector((state) => state.bonusesModal);

    const [fetchActivityByID, {data, isFetching}] = useLazyGetActivityByIDQuery();

    const handleClose = () => {
        dispatch(toggleModal({type: null, isOpenView: false}));
    };
    useEffect(() => {
        if (isOpenView && modalType === 'activity') {
            fetchActivityByID(selectedEntityId ?? '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenView]);

    return (
        <DialogWrapper
            openModal={isOpenView && modalType === 'activity'}
            handleModal={handleClose}
            width="532px"
            contentDividers
            header={isFetching ? '' : `${data?.title}`}
        >
            {!isFetching ? <Box>
                <Box>
                    <Typography fontSize={'16px'}>{data?.description}</Typography>
                </Box>
                <Box my={2}>
                    <Divider variant={'fullWidth'}/>
                </Box>
                <Box display={'flex'} justifyContent={'center'}>
                    <Typography fontSize={'16px'}>{`+ ${data?.cost} ${getPointsText(data?.cost!)}`}</Typography>
                </Box>
            </Box> : <LoadProgress/>}
        </DialogWrapper>
    );
};

export default memo(ViewActivity);
