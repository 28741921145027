import React, {FC} from 'react';
import Box from '@mui/material/Box';
import {Paper} from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {headerTable} from './styles/headerTable';

export type TColumn = {
    id: string,
    px: number,
    label: string,
    divider: boolean
}

interface IHeaderTable {
    columns: TColumn[]
}
export const HeaderTable: FC<IHeaderTable> = ({columns}) => {
    return (
        <Paper sx={headerTable.container}>
            {columns.map((column) => {
                return (
                    <Box key={column.id} display={'flex'} alignItems={'center'} justifyContent={'center'}
                         justifyItems={'center'} width={'30%'}>
                        <Typography sx={{px: column.px}}>{column.label}</Typography>
                        {column.divider && <Divider orientation="vertical" sx={headerTable.column}/>}
                    </Box>
                );
            })}
        </Paper>
    );
};

export default HeaderTable;