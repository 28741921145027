import React, { FC, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { AppContext } from "../../shared/AppContextProvider";
import { KbContext } from "../KbContextProvider/KbContextProvider";

import Snackbars from "../../common/Snackbars/Snackbars";
import SectionsPage from "../SectionsPage/SectionsPage";
import ArticlePage from "../ArticlePage/ArticlePage";

import { Box } from "@mui/material";

export const KbContent: FC = () => {
  const { isMobile } = useContext(AppContext);

  const { openSnackbar, snackbarType, setOpenSnackbar, snackbarMessage } = useContext(KbContext);
  const [page, setPage] = useState<"sections" | "article">("sections");
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/knowledge") {
      setPage("sections");
    } else {
      setPage("article");
    }
  }, [location.pathname]);

  return (
    <Box paddingX={isMobile ? 2 : undefined}>
      {page === "sections" && <SectionsPage />}
      {page === "article" && <ArticlePage />}
      <Snackbars
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        type={snackbarType}
        position="center"
        message={snackbarMessage}
      />
    </Box>
  );
};

export default KbContent;
