import {useEffect, useState} from 'react';
import {useAppSelector} from '../../../../../hooks/useAppSelector';
import {useLazyGetReviewActivityByIDQuery} from '../../../../../services/person_activity.controller';
import {useLazyGetReviewGiftByIDQuery} from '../../../../../services/person_gifts.controller';


export const useApplicationData = () => {
    const { isOpenView, modalType, selectedEntityId } = useAppSelector((state) => state.bonusesModal);
    const [fetchActivityByID, { data: activity, isFetching: isFetchingActivity }] = useLazyGetReviewActivityByIDQuery();
    const [fetchGiftByID, { data: gifts, isFetching: isFetchingGifts }] = useLazyGetReviewGiftByIDQuery();

    const [isShowButton, setIsShowButton] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");

    useEffect(() => {
        if (isOpenView) {
            if (modalType === "managementActivity") {
                fetchActivityByID(selectedEntityId);
            } else if (modalType === "managementGifts") {
                fetchGiftByID(selectedEntityId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenView, selectedEntityId, modalType]);

    useEffect(() => {

        if (modalType === "managementActivity") {
            const status = activity?.status;
            setIsShowButton(status?.code !== "REFUSED" && status?.code !== "DONE");
            setMessage(activity?.message || "");
        } else if (modalType === "managementGifts") {
            const status = gifts?.status;
            setIsShowButton(status?.code !== "REFUSED" && status?.code !== "DONE");
            setMessage(gifts?.message || "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity, gifts, modalType]);

    return {
        activity,
        gifts,
        isShowButton,
        message,
        isFetchingGifts,
        isFetchingActivity
    };
};

