import {SystemStyleObject, Theme} from '@mui/system';

export const rowTable: { [key: string]: SystemStyleObject<Theme> } = {
    row: {
        display: 'flex',
        height: 80,
        my: 2,
        borderRadius: 3,
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 2,
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: 'rgba(203, 225, 255, 0.17)'
        }
    },

}