import { apiUrlPortal } from "../constants/apiUrl";
import jwtInterceptor from "./jwtInterceptor";

export const notificationsController = {
  notifications: async (seen?: boolean, page?: number, pageSize?: number): Promise<any> => {
    return await jwtInterceptor.get(
      `${apiUrlPortal}/bell_notifications?page=${page ?? 0}&seen=${seen ?? false}&size=${pageSize ?? 10}&sort=bellNotification.date,desc`
    );
  },
  markNotification: async (id: string): Promise<any> => {
    return await jwtInterceptor.put(`${apiUrlPortal}/bell_notifications/${id}/mark`);
  },
  markNotifications: async (ids: string[]): Promise<any> => {
    return await jwtInterceptor.put(`${apiUrlPortal}/bell_notifications/mark`, ids);
  },
  markAllNotifications: async (): Promise<any> => {
    return await jwtInterceptor.put(`${apiUrlPortal}/bell_notifications/mark/all`);
  },
  deleteNotification: async (id: string) => {
    return await jwtInterceptor.delete(`${apiUrlPortal}/bell_notifications/${id}`);
  },
  deleteAllSeenNotifications: async () => {
    return await jwtInterceptor.delete(`${apiUrlPortal}/bell_notifications`);
  },
};