import React, {FC, useEffect, useState} from 'react';

import {useDispatch} from 'react-redux';
import Box from '@mui/material/Box';

import CustomButton from '../../../common/Buttons/CustomButton/CustomButton';
import ConfirmModal from '../../../common/Modals/ConfirmModal/ConfirmModal';
import {RowCardTable} from '../RowCardTable/RowCardTable';
import {useDeleteGiftMutation, useGetAllGiftsQuery} from '../../../../services/gift.controller';
import {useScrollShadow} from '../../helpers/useScrollShadow';
import {toggleModal} from '../../../../reducer/bonusesModalSlice';
import {useAppSelector} from '../../../../hooks/useAppSelector';

import {tableGifts} from './styles/tableGifts';


export const TableGifts: FC = () => {
    const {data} = useGetAllGiftsQuery('');
    const gifts = data?.items;
    const {scrollBoxRef, applyShadowEffect} = useScrollShadow<HTMLDivElement>();
    const dispatch = useDispatch();
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const [deleteGift] = useDeleteGiftMutation();
    const {selectedEntityId} = useAppSelector((state) => state.bonusesModal);
    const user = useAppSelector((state) => state.profileReducer.profile);
    const roles = ['EDITOR', 'ADMIN']
    const userRole = user.role.role;
    const hasRole = roles.some(role => userRole.includes(role));

    const handleClick = () => {
        dispatch(toggleModal({type: 'createGift', isOpenView: true}));
    };
    useEffect(() => {
        applyShadowEffect();
    }, [gifts, applyShadowEffect]);

    const handleConfirmModal = (id?: string) => {
        setOpenConfirmModal((prev) => !prev);
        dispatch(toggleModal({type: 'delete', isOpenView: true, id}));
    };

    const handleDelete = () => {
        deleteGift(selectedEntityId)
        setOpenConfirmModal(false);
        dispatch(toggleModal({type: null, isOpenView: false}));
    };
    return (
        <Box>
            {hasRole && <Box display={'flex'} justifyContent={'flex-end'} my={1}>
                <CustomButton text={'+Добавить'} styles={{width: '25%'}} onClick={handleClick} color={'secondary'}/>
            </Box>}
            <Box ref={scrollBoxRef}
                 height={300}
                 overflow="auto"
                 sx={{
                     ...tableGifts.scrollBox,
                     position: 'relative',
                     p: 2
                 }}>
                {gifts?.map(({title, cost, id}) =>
                    <RowCardTable
                        key={id}
                        title={title}
                        cost={cost}
                        id={id}
                        type={'gift'}
                        onConfirmModal={() => handleConfirmModal(id)}
                        typeEdite={'editeGift'}
                    />)}
                <ConfirmModal
                    openConfirmModal={openConfirmModal}
                    handleModal={handleConfirmModal}
                    nameConfirmButton="Удалить"
                    handleConfirm={handleDelete}
                    text="Подарок будет удален."
                />
            </Box>
        </Box>
    )
}
