import { SystemStyleObject, Theme } from '@mui/system';

const keys = [
  'containerStyle',
  'weekend',
  'boxStyle',
  'mobileWidth',
  'dayItem',
  'dayEvent',
  'popover',
] as const;

type StylesType = Record<(typeof keys)[number], SystemStyleObject<Theme>>;

export const styles: StylesType = {
  containerStyle: {
    '.MuiPickersDay-dayWithMargin': {
      margin: '5px',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  weekend: {
    backgroundColor: 'rgba(185, 215, 255, 0.08)',
  },
  boxStyle: {
    display: 'inherit',
    padding: '0px 8px',
    alignItems: 'center',
    lineHeight: '20px',
    width: '5vw',
    height: '20px',
    borderRadius: '4px',
    margin: '2px 0px',
  },
  mobileWidth: {
    width: '11vw',
  },
  dayItem: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:hover': {
      backgroundColor: 'rgba(185, 215, 255, 0.08)',
      borderRadius: '8px',
      cursor: 'pointer',
    },
  },
  dayEvent: {
    '&:hover': { backgroundColor: 'rgba(185, 215, 255, 0.08)' },
  },
  popover: {
    pointerEvents: 'none',
  },
};
