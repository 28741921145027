import { SxProps, Theme } from "@mui/material";

export const getCalendarStyles = (isMobile: boolean): SxProps<Theme> => {
  return {
    px: { mobile: "0px", laptop: "16px" },
    ".MuiPickersCalendarHeader-labelContainer": {
      minHeight: "40px",
      maxHeight: "40px",
    },
    ".MuiPickersCalendarHeader-label": {
      fontSize: isMobile ? "24px" : "32px",
      lineHeight: "40px",
      minHeight: "40px",
      maxHeight: "40px",
      fontWeight: "700",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      "&:first-letter": {
        textTransform: "uppercase",
      },
    },
    ".MuiPickersFadeTransitionGroup-root": {
      minWidth: "100px",
      cursor: "default",
      "&.MuiCalendarPicker-viewTransitionContainer": {
        backgroundColor: 'rgba(24, 132, 255, 0.16)'
      }
    },
    ".MuiDayPicker-weekContainer": {
      margin: "0px",
    },
    ".MuiPickersCalendarHeader-root": {
      width: "100%",
      minHeight: "40px",
      maxHeight: "40px",
      gap: { mobile: "0px", laptop: "14px" },
      p: { mobile: "16px", laptop: "0px" },
    },
    ".MuiButtonBase-root": {
      width: "20px",
      borderRadius: 500,
      height: "20px",
      margin: "10px",
    },
    ".MuiTypography-root": {
      width: "100%",
    },
    ".MuiPickersArrowSwitcher-spacer": {
      width: { mobile: "0px", laptop: "8px" },
    },
    ".MuiPickersArrowSwitcher-button": {
      width: { mobile: "32px", laptop: "40px" },
      height: { mobile: "32px", laptop: "40px" },
      borderRadius: "20px",
      margin: "0px",
      padding: "0px",
    },
    ".MuiContainer-root": {
      height: "100px",
      width: "100%",
      padding: "0px",
      border: "solid 1px rgba(241, 247, 255, 0.12)",
    },
    ".MuiCalendarPicker-root": {
      margin: 0,
      width: "100%",
      maxHeight: "unset",
      mb: { mobile: "17px", laptop: "1rem" },
    },
    ".MuiDayPicker-monthContainer": {
    },
    ".MuiDayPicker-slideTransition": {
      overflow: "hidden",
      height: "600px"
    },
    ".MuiDayPicker-weekDayLabel:nth-of-type(1)": {
      "&::after": {
        content: "'н'",
        display: "inline-block",
      },
    },
    ".MuiDayPicker-weekDayLabel:nth-of-type(2)": {
      "&::after": {
        content: "'т'",
        display: "inline-block",
      },
    },
    ".MuiDayPicker-weekDayLabel:nth-of-type(3)": {
      "&::after": {
        content: "'р'",
        display: "inline-block",
      },
    },
    ".MuiDayPicker-weekDayLabel:nth-of-type(4)": {
      "&::after": {
        content: "'т'",
        display: "inline-block",
      },
    },
    ".MuiDayPicker-weekDayLabel:nth-of-type(5)": {
      "&::after": {
        content: "'т'",
        display: "inline-block",
      },
    },
    ".MuiDayPicker-weekDayLabel:nth-of-type(6)": {
      "&::after": {
        content: "'б'",
        display: "inline-block",
      },
    },
    ".MuiDayPicker-weekDayLabel:nth-of-type(7)": {
      "&::after": {
        content: "'с'",
        display: "inline-block",
      },
    },
  };
};
