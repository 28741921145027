import React, {FC} from 'react';
import {Paper, Tooltip} from '@mui/material';
import Typography from '@mui/material/Typography';
import {rowTable} from './styles/rowTable';
import {toggleModal} from '../../../../../reducer/bonusesModalSlice';
import {useDispatch} from 'react-redux';

interface IRowTableProps {
    date: string;
    name: string;
    typeName: string;
    status: string;
    type: 'managementGifts' | 'managementActivity';
    id: string;
}

type TypographyItem = {
    value: string;
    width: string;
    px: number;
}

export const RowTable: FC<IRowTableProps> = ({date, status, typeName, name, type, id}) => {
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(toggleModal({type: type, isOpenView: true, id}));
    };
    const items: TypographyItem[] = [
        {value: date, width: '30%', px: 2},
        {value: name, width: '30%', px: 5},
        {value: typeName, width: '35%', px: 5},
        {value: status, width: '30%', px: 5},
    ];
    return (
        <Paper sx={rowTable.row} onClick={handleClick}>
            {items.map((item, index) => (
                <Tooltip key={index} title={item.value}>
                    <Typography width={item.width} px={item.px} noWrap>
                        {item.value}
                    </Typography>
                </Tooltip>
            ))}
        </Paper>
    )
};

export default RowTable;