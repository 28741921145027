export const styleHeader = {
    "& .table-header": {
        backgroundColor: "background.paper",
        padding: "10px 12px !important",
    },
    "& .table-header--hide": {
        visibility: "hidden",
    },
    "& .table-header:nth-of-type(1)": {
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "8px",
        borderRight: "1px solid rgba(185, 215, 255, 0.08)",
    },
    "& .table-header:nth-of-type(3)": {
        borderLeft: "1px solid rgba(185, 215, 255, 0.08)",
    },
    "& .table-header:nth-of-type(4)": {
        borderRight: "1px solid rgba(185, 215, 255, 0.08)",
        borderLeft: "1px solid rgba(185, 215, 255, 0.08)",
    },
    "& .table-header:last-of-type": {
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "8px",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
        justifyContent: "space-between",
    },
};

export const styleTable = {
    '& .MuiDataGrid-root .row-row-action': {
        background: 'linear-gradient(90deg, rgba(68, 67, 67, 0.55) 25%, rgba(100, 149, 237, 0.6) 50%, rgba(68, 67, 67, 0.55) 75%)',
        backgroundSize: '200% 100%',
        animation: 'rowLoadingEffect 2s linear infinite',
        opacity: 0.8,
        pointerEvents: 'none',
        transition: 'opacity 0.8s ease, background 0.3s ease',
    },
    border: "none",
    "& .MuiDataGrid-columnSeparator": {
        display: "none",
    },
    "& .MuiDataGrid-columnHeaders": {
        borderBottom: "none",
        minHeight: "auto !important",
        maxHeight: "none !important",
        lineHeight: "normal !important",
        fontSize: "14px",
    },
    "& .MuiDataGrid-row": {
        minHeight: "auto !important",
        maxHeight: "none !important",
    },
    "& .MuiDataGrid-row.Mui-selected": {
        backgroundColor: "background.paper",
    },
    "& .MuiDataGrid-row--lastVisible .MuiDataGrid-cell": {
        borderBottomColor: "rgba(59, 68, 81, 1) !important",
    },
    "& .MuiDataGrid-cell": {
        padding: "12px",
    },
    "& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus-within":
        {
            outline: "none",
        }
};

export const customToggleButtonsStyle = {
    marginBottom: "10px",
}