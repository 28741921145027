import {
    useApproveReviewActivityMutation,
    useClearingReviewActivityMutation,
    useRefuseReviewActivityMutation
} from '../../../../../services/person_activity.controller';
import {
    useApproveReviewGiftMutation,
    useClearingReviewGiftMutation, useRefuseReviewGiftMutation
} from '../../../../../services/person_gifts.controller';


export const useApplicationActions = () => {
    const [approveActivity] = useApproveReviewActivityMutation();
    const [clearingActivity] = useClearingReviewActivityMutation();
    const [refuseActivity] = useRefuseReviewActivityMutation();

    const [approveGift] = useApproveReviewGiftMutation();
    const [clearingGift] = useClearingReviewGiftMutation();
    const [refuseGift] = useRefuseReviewGiftMutation();

    const getAction = (type: 'approve' | 'cancel' | 'clearing', action: 'managementActivity' | 'managementGifts') => {
        const actionMap = {
            managementActivity: {
                approve: approveActivity,
                cancel: refuseActivity,
                clearing: clearingActivity,
            },
            managementGifts: {
                approve: approveGift,
                cancel: refuseGift,
                clearing: clearingGift,
            },
        };

        return actionMap[action][type];
    };

    return {getAction};
};
